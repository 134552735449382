import {useTheme} from '@mui/material';

function useChipStyles(chipStyle) {

  const theme = useTheme();

  const colors = {
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    info: theme.palette.primary.main,
    neutral: '#F5F5F5',
  };

  const neutralStyles = chipStyle === 'filled' ?
      {
        backgroundColor: colors.neutral,
        color: 'text.primary',
      } :
      {
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: '#fff',
        color: 'text.primary',
      };

  const successStyles = chipStyle === 'filled' ?
      {
        backgroundColor: colors.success,
        color: '#fff',
      } :
      {
        border: `1px solid ${colors.success}`,
        backgroundColor: '#fff',
        color: colors.success,
      };

  const errorStyles = chipStyle === 'filled' ?
      {
        backgroundColor: colors.error,
        color: '#fff',
      } :
      {
        border: `1px solid ${colors.error}`,
        backgroundColor: '#fff',
        color: colors.error,
      };

  const infoStyles = chipStyle === 'filled' ?
      {
        backgroundColor: colors.info,
        color: '#fff',
      } :
      {
        border: `1px solid ${colors.info}`,
        backgroundColor: '#fff',
        color: colors.info,
      };

  return [neutralStyles, successStyles, errorStyles, infoStyles];
}

export default useChipStyles;
