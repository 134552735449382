import {Box, Divider, Grid, Link, Stack, Typography} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {externalUrl} from '../../utils/url';

function ClubInfoNewClubLinks({links}) {

  const {t} = useTranslate();

  if (links?.length > 0) {
    return (
        <Grid item xs={12}>
          <Box>
            <Typography variant={'h6-bold'} mb={2}>
              {t('clubInfo.clubLinks.title')}
            </Typography>
            <Stack
                direction={'row'}
                columnGap={1}
                rowGap={2}
                alignItems={'center'}
                flexWrap={'wrap'}
                divider={<Divider orientation={'vertical'} flexItem/>}
            >
              {
                links?.map((link, i) => (
                    <LinkItem link={link}/>
                ))
              }
            </Stack>
          </Box>
        </Grid>
    );
  }

}

function LinkItem({link}) {
  return (
      <Link
          href={externalUrl(link.link)}
          underline="always"
          target={'_blank'}
          textTransform={'capitalize'}
      >
        {link.title}
      </Link>
  );
}

export default ClubInfoNewClubLinks;
