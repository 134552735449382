import {statusError, statusLoading, statusSuccess} from './status';
import axios from 'axios';
import {
  COURT_TYPE_FOR_PLAYER_FAILURE,
  COURT_TYPE_FOR_PLAYER_LOADING,
  COURT_TYPE_FOR_PLAYER_SUCCESS,
  SEARCH_DATA_FOR_PLAYER_FAILURE,
  SEARCH_DATA_FOR_PLAYER_LOADING,
  SEARCH_DATA_FOR_PLAYER_SUCCESS,
  SHOW_VERSION_MISMATCH_POPUP,
} from '../constants';

export const customersSearch_POST = (body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(SEARCH_DATA_FOR_PLAYER_LOADING));
      const response = await axios.post(`customers/search`, body);
      dispatch(statusSuccess(SEARCH_DATA_FOR_PLAYER_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(SEARCH_DATA_FOR_PLAYER_FAILURE, e));
    }
  };
};

export const customersSearchByDate_POST = (body,clubId, cbSuccess, cbFail) => {
  return async () => {
    try {
      const response = await axios.post(`customers/searchByDate`, body);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const sportsAndCourtTypeForPlayer_GET = (name, cbCallback) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(COURT_TYPE_FOR_PLAYER_LOADING));
      const response = await axios.get(`customers/sports/list?name=${name}`);
      dispatch(statusSuccess(COURT_TYPE_FOR_PLAYER_SUCCESS, response.data));
      cbCallback(response);
    } catch (e) {
      console.log(e);
      cbCallback(e);
      dispatch(statusError(COURT_TYPE_FOR_PLAYER_FAILURE, e));
    }
  };
};

export const showVersionMismatchPopup = (value) => {
  return async (dispatch) => {
    dispatch(statusSuccess(SHOW_VERSION_MISMATCH_POPUP, value));
  }

};
