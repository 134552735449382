import {Box, Divider, FormControl, Switch, Typography} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {useSlotSearchV2Context} from '../SlotSearchV2/ProviderSlotSearchV2';
import useIsMobile from '../../hooks/ui/useIsMobile';

const EnableAlternateCourtInput = (props) => {
  const isMobile = useIsMobile()
  const {t} = useTranslate();

  const {
    allowCourtUpdate,
    setAllowCourtUpdate,
  } = useSlotSearchV2Context();

  const onChange = (e) => {
    const checked = e.target.checked;
    setAllowCourtUpdate(checked);
  };

  return (
      <Box
          position={'sticky'}
          top={'100%'}
          px={isMobile? '0px' :'26px'  }
      >
        <Divider/>
        <FormControl
            fullWidth
            size={'medium'}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: '16px',
            }}
        >
          <Typography>
            {t('openMatch.create.allowCourtUpdate.text')}
          </Typography>
          <Switch
              checked={allowCourtUpdate}
              onChange={onChange}
          />
        </FormControl>
      </Box>
  );
};
export default EnableAlternateCourtInput;
