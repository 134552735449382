import {
  alpha,
  ButtonBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  popoverClasses,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {NavLink, useLocation, useParams} from 'react-router-dom';
import {useMemo, useRef, useState} from 'react';
import {ChevronRight, MoreVertTwoTone} from '@mui/icons-material';
import {useTranslate} from '@tolgee/react';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';

export function LayoutClubNavHorizontal({navItems,experience}) {

  const location = useLocation();
  const {t} = useTranslate();
  const isAuthenticated = useIsAuthenticated();


  const collapseMdAuth = useMediaQuery('(max-width: 958px)');
  const collapseLgAuth = useMediaQuery('(max-width: 1201px)');

  const collapseMd = useMediaQuery('(max-width: 974px)');
  const collapseLg = useMediaQuery('(max-width: 1236px)');

  let [items, moreItems] = useMemo(() => {


    if (isAuthenticated){
      if (collapseMdAuth) {
        return [
          navItems.slice(0, 2),
          navItems.slice(2),
        ];
      }

      if (collapseLgAuth) {
        return [
          navItems.slice(0, 4),
          navItems.slice(4),
        ];
      }
    }

    if (!isAuthenticated){
      if (collapseMd) {
        return [
          navItems.slice(0, 1),
          navItems.slice(1),
        ];
      }

      if (collapseLg) {
        return [
          navItems.slice(0, 3),
          navItems.slice(3),
        ];
      }
    }

    return [
        navItems,
        null,
    ]

  }, [navItems,  collapseMd, collapseLg, collapseMdAuth, collapseLgAuth, isAuthenticated]);

  return (
      <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
      >
        {
          items?.map((navItem, i) => {
            if (!navItem.canAccess) return;
            return <NavItem item={navItem}/>;
          })
        }
        {
            moreItems?.length > 0 &&
            <NavItem
            experience={experience}
                item={{
                  name: t('nav.more'),
                  route: null,
                  activeOnRoot: false,
                  // icon: MoreVertTwoTone,
                  disabled: false,
                  canAccess: true,
                  onClick: () => {
                  },
                  subMenuItems: moreItems,
                }}
            />
        }
      </Stack>
  );

}

function NavItem({item,experience}) {

  const theme = useTheme();
  const itemRef = useRef(null);
  const {clubId,companyId} = useParams();

  const homePath = experience=='COMPANY'?`/company/${companyId}`:`/club/${clubId}`

  const [open, setOpen] = useState(false);

  const children = item?.subMenuItems;
  const hasChildren = children?.length > 0;
  const hasRoute = item?.route;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <>
        <ButtonBase
            ref={itemRef}
            component={hasRoute && NavLink}
            to={item?.route}
            end={item?.route === homePath}
            disabled={item.disabled}
            onClick={() => {
              if (item?.onClick) {
                item?.onClick();
              }
              handleClose();
              if (!hasRoute) {
                setOpen((prevState) => !prevState);
              }
            }}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '6px 12px',
              borderRadius: '24px',
              columnGap: '6px',
              color: theme.palette.text.secondary,
              height: '34px',
              p:"16px 16px",
              '&:hover': {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.text.primary,
              },
              '&.active': {
                backgroundColor: alpha(theme.palette.primary.main, 1),
                color: '#fff',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.dark,
                      1),
                },
              },
              '&.Mui-disabled': {
                color: theme.palette.text.disabled,
              },
            }}
        >
          {/*{*/}
          {/*    item.icon && <item.icon color={'inherit'} width={'20px'} fontSize={'small'} />*/}
          {/*}*/}
          <Typography variant={'body2'} color={'inherit'}
                      fontWeight={500} lineHeight={.1} whiteSpace={'nowrap'}>
            {item.name}
          </Typography>
          {
              hasChildren &&
              <ChevronRight fontSize="small"/>
          }
        </ButtonBase>
        {
            hasChildren &&
            <SubMenu
                items={children}
                itemRef={itemRef}
                open={open}
                handleClose={handleClose}
                handleOpen={handleOpen}
            />
        }
      </>
  );
}

function SubMenu({items, itemRef, open, handleOpen, handleClose}) {

  const theme = useTheme();

  return (
      <Popover
          open={open}
          anchorEl={itemRef.current}
          anchorOrigin={
            {vertical: 'bottom', horizontal: 'left'}
          }
          transformOrigin={
            {vertical: 'top', horizontal: 'left'}
          }
          slotProps={{
            paper: {
              onMouseEnter: handleOpen,
              onMouseLeave: handleClose,
            },
          }}
          sx={{
            pointerEvents: 'none',
            [`& .${popoverClasses.paper}`]: {
              minWidth: 160,
              ...(open && {
                pointerEvents: 'auto',
              }),
            },
          }}
      >
        <List>
          {
            items?.map((item) => {
              return (
                  <ListItem
                      sx={{p: 0}}

                  >
                    <ListItemButton
                        component={NavLink}
                        to={item.route}
                        disabled={item.disabled}
                        onClick={() => {
                          if (item?.onClick) {
                            item?.onClick();
                          }
                          handleClose();
                        }}
                        sx={{
                          px: 1,
                          py: .25,
                          borderRadius: '8px',
                          '&.active': {
                            backgroundColor: theme.palette.grey[200],
                          },
                        }}
                    >
                      <ListItemText
                          sx={{
                            fontSize: '14px',
                            fontWeight: '600 !important',
                          }}
                      >
                        {item.name}
                      </ListItemText>
                    </ListItemButton>

                  </ListItem>
              );
            })
          }

        </List>
      </Popover>
  );
}


