import { Box, Skeleton, Stack, Typography } from "@mui/material";
import IconCalendarDot from "../../assets/icons/IconCalendarDot.js";
import IconClockAfternoon from "../../assets/icons/IconClockAfternoon";
import moment from "moment-timezone";
import useIsMobile from "../../hooks/ui/useIsMobile.js";
import { formatTime } from "../../utils/ui.js";

const TrainingTimeSection = ({
  isLoading,
  rowGap=2,
  fw=600,
  startDate,
  startTime,
  endDate,
  endTime,
  recurrence = 'Every Thu',
}) => {
  
  const isMobile = useIsMobile();
  
  const SingleSection = ({ Icon, value }) => {
    return (
      <Stack direction={"row"} alignItems={'center'} columnGap={1} width={"50%"}>
        {Icon && <Icon />}
        {
          isLoading?
          <Skeleton height={'30px'} width={'200px'} />:
          <Typography textTransform={'capitalize'} variant="body1" fontSize={isMobile?'14px':'16px'} fontWeight={fw}>{value}</Typography>
        }
      </Stack>
    );
  };

  const Dot=()=><Box
  sx={{
    width: "8px",
    height: "8px",
    backgroundColor: "grey.900",
    borderRadius: "50%",
  }}
/>
  
  const Line=()=><Box
  sx={{
    width: "1px",
    height: "90%",
    borderStyle: "dashed",
    borderWidth: "1px",
    borderColor: "grey.900"
  }}
/>

  const LineAndDot=()=>{

    return(
        <Stack
        alignItems={'center'}
        margin={'0.5rem .5rem'}
        >
            
            <Dot/>
            <Line/>
            <Dot/>
        </Stack>
    )
  }


  return (
    <Stack spacing={1}>
      
    <Stack
    direction={'row'}
    >
        <LineAndDot/>
        <Stack
            direction={"row"}
            alignItems={"center"}
            rowGap={rowGap}
            flexWrap={"wrap"}
        >
        
            <SingleSection
            value={moment(startDate, "YYYY-MM-DD").format("ddd, DD MMM YY")}
            />
            <SingleSection Icon={IconCalendarDot} value={recurrence} />
            <SingleSection
            value={moment(endDate, "YYYY-MM-DD").format("ddd, DD MMM YY")}
            />
            <SingleSection
            Icon={IconClockAfternoon}
            value={`${formatTime(startTime)}-${formatTime(endTime)}`}
            />
        </Stack>
    </Stack>
    </Stack>
  );
};

export default TrainingTimeSection;
