import {useEffect, useRef, useState} from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import ProviderExperience from '../providers/ProviderExperience';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {customerDetailsByToken_GET, customersClubsDetail_GET} from '../vendor/redux/actions/customers';
import {useDispatch} from 'react-redux';
import axios from 'axios';
import ReactGA from 'react-ga4';
import {getIsProduction} from '../utils/environments';
import {clubsPolicyList_GET} from '../vendor/redux/actions/policies';
import {Box, LinearProgress} from '@mui/material';
import {
  customersClubsTimingsList_GET,
  customersSportsClubList_GET,
} from '../vendor/redux/actions/scheduleApis';
import {useSnackbar} from 'notistack';
import {useTranslate} from '@tolgee/react';
import ProviderNotistack from '../providers/ProviderNotistack';
import {ERR_NETWORK_ERROR} from '../constants/errors';
import {locationsCountriesList_GET} from '../vendor/redux/actions/locations';
import ClubNotExists from '../components/ClubNotExists/ClubNotExists';
import TagManager from "react-gtm-module"
import { getCompanyDetails } from '../vendor/redux/actions/company';
import ReactPixel from 'react-facebook-pixel';
import VersionUpdate from '../components/_Global/VersionUdate/VersionUpdate';
import useLogin from '../hooks/onboarding/useLogin';
import useUser from '../hooks/access/useUser';
import useDemo from '../hooks/access/useDemo';
import IntercomComponent from './IntercomComponent';
import useOnboardingContext from '../hooks/onboarding/useOnboardingContext';
import DialogAddUserRating from '../dialogs/OnBoarding/DialogAddUserRating';
import { USER_LOGIN } from '../vendor/redux/constants';
import useSetLanguage from '../hooks/localization/useSetLanguage';

function InitClubExperience() {

  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslate();
  const setLanguage = useSetLanguage();

  const {clubId} = useParams();
  const [searchParams] = useSearchParams();
  const isDemo = searchParams?.get('demo');
  const tokenForDemoLogin = '6c1623c0-bc5f-11ef-85f4-418fb0291eb8';
  const {pathname} = useLocation();
  const prevPathname = useRef();
  const isProduction = getIsProduction();
  const appToken = searchParams.get('app-token');
  const hasAppToken = searchParams.has('app-token');

  const [clubDetails, setClubDetails] = useState();
  const [clubTheme, setClubTheme] = useState('pending');
  const [gTag, setGTag] = useState('pending');
  const [gTagSet, setGTagSet] = useState(false);
  const [fbPixel, setFbPixel] = useState(false);
  const [pixelId, setPixelId] = useState('pending');
  const [clubPolicies, setClubPolicies] = useState();
  const [clubSports, setClubSports] = useState();
  const [clubTimings, setClubTimings] = useState();
  const [clubNotExists, setClubNotExists] = useState(false);

  const [headersSet, setHeadersSet] = useState();

  const [companyDetails, setCompanyDetails] = useState();
  const {handleLogin} = useLogin();
  const USER = useUser();
  const isDemoClub = useDemo(clubPolicies);

  const {
    isOpenUserRatingDialog,
    setIsOpenUserRatingDialog,
  } = useOnboardingContext();

  const isLoadingClubExperience =
      !clubDetails ||
      !clubPolicies ||
      !clubTimings ||
      !clubSports ||
      !headersSet ||
      !gTagSet ||
      clubTheme === 'pending';

  function fetchClubDetails() {

    dispatch(customersClubsDetail_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersClubsDetail_GET Success', res);
      setClubDetails(res?.data?.data);
    }

    function cbFail(e) {
      console.log('customersClubsDetail_GET Fail', e);
      const err = e?.response?.data?.data;
      if (err === 'No such club.') {
        setClubNotExists(true);
      } else {
        enqueueSnackbar(t(ERR_NETWORK_ERROR), {variant: 'error'});
      }
    }

  }

  const fetchCompanyDetails = () => {

    function cbSuccess(res) {

      const response = res?.data?.data;
      const toSet = {...response, ...response.company};

      setCompanyDetails(toSet);

    }

    function cbFailure(res) {
      console.log(' !! ERR : ', res);
    }

    dispatch(getCompanyDetails(clubDetails?.clubId, cbSuccess, cbFailure));

  };

  function fetchClubPolicies() {
    dispatch(clubsPolicyList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('clubsPolicyList_GET Success', res);
      let policies = res?.data?.data;
      setClubPolicies(policies);
    }

    function cbFail(e) {
      console.log('clubsPolicyList_GET Fail', e);
      enqueueSnackbar(t(ERR_NETWORK_ERROR), {variant: 'error'});
    }

  }

  function fetchClubSports(company = null) {
    dispatch(customersSportsClubList_GET(company ? null : clubId, cbSuccess,
        cbFail));

    function cbSuccess(res) {
      console.log('customersSportsClubList_GET Success', res);
      setClubSports(res?.data?.data);
    }

    function cbFail(e) {
      console.log('customersSportsClubList_GET Fail', e);
      enqueueSnackbar(t(ERR_NETWORK_ERROR), {variant: 'error'});

    }

  }

  function fetchClubTimings() {

    dispatch(customersClubsTimingsList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersClubTimingList_GET Success', res);
      setClubTimings(res?.data?.data);
    }

    function cbFail(e) {
      console.log('customersClubTimingList_GET Fail', e);
      enqueueSnackbar(t(ERR_NETWORK_ERROR), {variant: 'error'});

    }
  }

  function fetchCountries() {

    dispatch(locationsCountriesList_GET(cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('countries list Success', res);
    }

    function cbFail(e) {
      console.log('countries list Fail', e);
      enqueueSnackbar(t(ERR_NETWORK_ERROR), {variant: 'error'});

    }
  }

  const fetchCustomerDetails=()=>{

    customerDetailsByToken_GET(appToken,cbSuccess,cbFail);

    function cbSuccess(res){
      const user = res.data.data;
      const obj={
        refreshToken:appToken,
        accessToken:appToken,
        userObj:{...user}
      }
      localStorage.setItem('user', JSON.stringify(obj));
      dispatch({type: USER_LOGIN, payload: obj});
      //set app language to user preference
      const lang = user?.language || 'nl'
      setLanguage(lang);
    }

    function cbFail(e){
      enqueueSnackbar(t(ERR_NETWORK_ERROR), {variant: 'error'});
    }
  }

  const setupPixels = (pixelsId) => {

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(pixelsId, {}, options);
    ReactPixel.pageView(); // For tracking page view

  };

  useEffect(()=>{
    const localData = localStorage.getItem('mobileView'); 
    if(localData==null){
      localStorage.setItem('mobileView',Boolean(hasAppToken));
    }
  },[])

  useEffect(()=>{

    if(appToken && !USER){
      fetchCustomerDetails();
    }
  },[appToken])

  useEffect(() => {

    if (isDemo && !USER && !!isDemoClub) {
      handleLogin('', '', tokenForDemoLogin);
    }
  }, [isDemo, clubPolicies]);

  /*Fetch Club Details*/
  useEffect(() => {
    fetchClubDetails();
    fetchClubPolicies();
    fetchClubSports();
    fetchClubTimings();
    fetchCountries();
  }, []);

  useEffect(() => {
    if (pathname.includes('company')) {
      axios.defaults.headers.common['x-clubos-club'] = '';
      axios.defaults.headers.common['x-clubos-club-info'] = '';
      fetchClubSports('company');

    } else if (prevPathname.current &&
        prevPathname.current.includes('company')) {
      axios.defaults.headers.common['x-clubos-club'] = clubId;
      axios.defaults.headers.common['x-clubos-club-info'] = clubId;
      fetchClubSports();
    }
    prevPathname.current = pathname;
  }, [
    pathname,
  ]);

  useEffect(() => {
    if (clubDetails) {
      fetchClubPolicies();
      fetchClubSports();
      fetchClubTimings();
      fetchCountries();
      fetchCompanyDetails();
    }
  }, [clubDetails]);

  /*Init*/
  useEffect(() => {
    if (clubDetails) {
      //Set Headers
      const clubId = clubDetails?.clubId;
      const companyId = clubDetails?.companyId;
      axios.defaults.headers.common['x-clubos-company'] = companyId;
      axios.defaults.headers.common['x-clubos-club'] = clubId;
      axios.defaults.headers.common['x-clubos-club-info'] = clubId;
      setHeadersSet(true);
      //Set Club Theme
      setClubTheme(clubDetails?.theme || {});
      //Set Google Analytics
      setGTag(clubDetails?.googleAnalyticsId || null);
      setPixelId(clubDetails?.metaPixel ?? null);
    }
  }, [clubDetails]);

  useEffect(() => {
    if (clubId === '216742'){
      const args = {
        gtmId: 'GTM-TVH82JR4'
      }
        TagManager.initialize(args)
    }
    return ()=>{
    }

  }, []);

  /*Google Analytics Initialize if Google Analytics ID Exists */
  useEffect(() => {
    if (gTag !== 'pending') {
      if (isProduction && gTag) {
        ReactGA.initialize(gTag);
      }
      setGTagSet(true);
    }
  }, [gTag]);

  useEffect(() => {
    if (pixelId !== 'pending') {
      if (pixelId) {
        setupPixels(pixelId);
      }
      setFbPixel(true);
    }
  }, [pixelId]);

  /*Club favicons*/
  useEffect(() => {
    //todo: following solution does not work on safari
    // const link16 = document.querySelector(
    //     'link[rel="icon"][type="image/png"][sizes="16x16"]') || {};
    // const link32 = document.querySelector(
    //     'link[rel="icon"][type="image/png"][sizes="32x32"]') || {};
    // const linkAppleTouch = document.querySelector(
    //     'link[rel="apple-touch-icon"][type="image/png"]') || {};


    // if (clubId === '216790' || clubId === '216742') {
    //   link16.href = './favicons/plaza-padel/favicon_pp_16x16.png';
    //   link32.href = '/favicons/plaza-padel/favicon_pp_32x32.png';
    //   linkAppleTouch.href = '/favicons/plaza-padel/favicon_pp_512x512.png';
    // }
    // if (clubId === '216807') {
    //   link16.href = './favicons/padel-alpha/favicon-pa-16x16.png';
    //   link32.href = '/favicons/padel-alpha/favicon-pa-32x32.png';
    //   linkAppleTouch.href = '/favicons/padel-alpha/favicon-pa-512x512.png';
    // }

    // if (clubDetails) {
    //   const clubImage = clubDetails?.imageUrl;
    //   if (clubImage) {
    //     link16.href = clubImage;
    //     link32.href = clubImage;
    //     linkAppleTouch.href = clubImage;
    //   }

    // }

  }, [clubDetails]);

  /*Clean up*/
  useEffect(() => {
    return () => {
      // Club + Company Request Headers Cleanup
      delete axios.defaults.headers.common['x-clubos-company'];
      delete axios.defaults.headers.common['x-clubos-club'];
      delete axios.defaults.headers.common['x-clubos-club-info'];
      // GA Cleanup
      ReactGA.reset();
      // Favicon Cleanup
      // todo reset favicons
    };
  }, []);

  if (!isLoadingClubExperience && !clubNotExists) {
    return (
        <ProviderExperience
            clubDetails={clubDetails}
            clubPolicies={clubPolicies}
            fetchClubPolicies={fetchClubPolicies}
            clubSports={clubSports}
            clubTimings={clubTimings}
            companyDetails={companyDetails}
        >
          <ThemeProvider theme={(outerTheme) => {
            return createTheme(outerTheme, clubTheme);
          }}>
            <ProviderNotistack>
              <IntercomComponent
                  clubDetails={clubDetails}
              />
               <DialogAddUserRating
                isOpen={isOpenUserRatingDialog}
                setIsOpen={setIsOpenUserRatingDialog}
                />
              {!!clubPolicies?.club?.status ? <Outlet/> : <ClubNotExists/>}
              <VersionUpdate/>
            </ProviderNotistack>
          </ThemeProvider>
        </ProviderExperience>
    );
  }

  if (isLoadingClubExperience && !clubNotExists) {
    return (
        <Box
            sx={{
              height: '100vh',
              width: '100vw',
              position: 'relative',
            }}
        >
          <LinearProgress sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
          }}/>
        </Box>);
  }

  if (clubNotExists) {
    return <ClubNotExists/>;
  }

}

export default InitClubExperience;
