import DialogBase from '../../components/_Global/Dialogs/DialogBase';
import {useTranslate} from '@tolgee/react';
import {
  PackageBenefitDetails,
} from '../../components/Packages/BenefitDetails/PackageBenefitDetails';
import PackageDurationSelect
  from '../../components/Packages/PackageDurationSelect';
import PackageDurationDisplay
  from '../../components/Packages/PackageDurationDisplay';
import DialogSection from '../../components/_Global/Dialogs/DialogSection';
import {
  CheckBoxOutlined,
  Inventory,
  Inventory2Outlined,
  Lock,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import {FormProvider, useForm} from 'react-hook-form';
import {Alert, AlertTitle, Box, Button, Skeleton, Tooltip} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import BillingDetails from '../../components/_Global/Billing/BillingDetails';
import {useEffect, useMemo, useState} from 'react';
import useSocket from '../../hooks/sockets/useSocket';
import genUid from 'light-uid';
import {useDispatch} from 'react-redux';
import {
  customersPackagesDetail_GET,
  customersPackagesPurchase_POST,
} from '../../vendor/redux/actions/packages';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import useUser from '../../hooks/access/useUser';
import {getIsAgeEligible} from '../../utils/packages';
import { purchasePackagesEvents } from '../../utils/analyticsEvents';

const socketUUID = genUid();

function DialogPackagePurchase({
                                 isOpen,
                                 onAfterExitAnimation
                               }) {

  const {t} = useTranslate();
  const {socket} = useSocket();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const {pkgId} = useParams();
  
  const selectedPackageId  = pkgId;

  const {enqueueSnackbar} = useSnackbar();
  const {clubId} = useParams();
  const formMethods = useForm({
    defaultValues: {
      duration: '',
    },
  });

  const {
    handleSubmit: handleFormSubmit,
    formState: {isSubmitting: isSubmittingForm},
    reset: resetForm,
    watch,
  } = formMethods;

  const watched = watch();
  const {duration} = watched;

  /*State*/
  const [selectedPackage, setSelectedPackage] = useState();
  const [isLoadingSelectedPackage, setIsLoadingSelectedPackage] = useState(
      true);
  const [socketPrice, setSocketPrice] = useState();
  const [isSubmittingPaymentCheckout, setIsSubmittingPaymentCheckout] = useState(
      false);
  
  const packageMinAge = selectedPackage?.minAge;
  const packageMaxAge = selectedPackage?.maxAge;
  const user = useUser()?.userObj;
  const userDob = user?.dateOfBirth;
  const isPurchased = selectedPackage?.isPurchased;
  const isNeedVerification = !!selectedPackage?.verificationRequired && selectedPackage?.userPackages?.length > 0 && !selectedPackage?.userPackages[0]?.isVerified;
  let isAgeEligible = useMemo(() => {
    return getIsAgeEligible(userDob, packageMinAge, packageMaxAge);
  }, [packageMinAge, packageMaxAge, userDob]);

  const packageId = selectedPackage?.id;
  const packageName = selectedPackage?.name;
  const packageDescription = selectedPackage?.description;
  const packageBenefitsList = selectedPackage?.packageBenefitsList;
  const discounts = selectedPackage?.packageBenefits?.discounts?.map(
      (discount) => {
        return {
          ...discount,
          discount: parseFloat((+discount.discount * 100).toFixed(0)),
        };
      });

  const packageType = selectedPackage?.type;
  const startDate = selectedPackage?.startDate;
  const endDate = selectedPackage?.endDate;
  const durationPrices = selectedPackage?.durationPrices || [];

  const packagePrice = socketPrice?.packagePrice || 0;
  const totalPrice = socketPrice?.totalPrice || 0;
  const platformFee = socketPrice?.platformFee || 0;
  const platformPercentage = socketPrice?.platformPercentage || 0;

  const socketBody = {
    type: 'package',
    packageId,
    ...(packageType === 'dynamic') && {
      duration: duration,
    },
  };

  const billingEntries = [
    {
      label: t('packages.purchase.billing.packagePrice'),
      amount: packagePrice,
    },
  ];

  function fetchPackage() {

    setIsLoadingSelectedPackage(true);
    dispatch(customersPackagesDetail_GET(selectedPackageId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersPackagesDetail_GET Success', res);
      setSelectedPackage(res.data.data);
      setIsLoadingSelectedPackage(false);
    }

    function cbFail(e) {
      console.log('customersPackagesDetail_GET Fail', e);
    }
  }

  function handleSubmit() {
    return handleFormSubmit(handleSubmitOnValidationPass,
        handleSubmitOnValidationFail)();
  }

  function handleSubmitOnValidationPass(data) {
    console.log('form validation pass', data);
    purchasePackagesEvents['proceedToPayment']()
    handlePaymentCheckout(data);
  }

  function handleSubmitOnValidationFail(e) {
    console.log('form validation fail', e);
  }

  function handlePaymentCheckout(data) {

    let redirectUrl;
    let domain = process.env.REACT_APP_URL;
    redirectUrl = `${domain}/club/${selectedPackage?.clubId}/packages/${selectedPackage?.id}/payment-status`;

    const body = {
      type: 'bank',
      packageId,
      redirectUrl,
      ...(packageType === 'dynamic') && {
        duration: data.duration,
      },
    };

    setIsSubmittingPaymentCheckout(true);

    dispatch(customersPackagesPurchase_POST(body, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersPackagesPurchase_POST Success', res?.data);
      const checkout_url = res?.data?.data?.checkoutUrl?.checkout_url;
      const isFullyPaid = res?.data?.data?.isFullyPaid;
      const orderId = res?.data?.data?.orderId;
      console.log('res.data.data',res.data.data)
      if(isFullyPaid) {
        navigateTo(`/club/${clubId}/packages/${selectedPackage?.id}/payment-status?orderId=${orderId}`);
        purchasePackagesEvents['purchaseSuccess']()
      }
      else { 
        window.location.href = checkout_url;
      }

      setIsSubmittingPaymentCheckout(false);
    }

    function cbFail(e) {
      console.log('customersPackagesPurchase_POST Fail', e);
      const errorResponse = e?.response?.data?.data ||
          'packages.purchase.alert.fail';
      enqueueSnackbar(t(`${errorResponse}`), {variant: 'error'});
      setIsSubmittingPaymentCheckout(false);
      purchasePackagesEvents['purchaseFailed']()
    }
  }

  function handleSocketEmitCalcPrice() {
    socket.emit('calculate_price', socketUUID, socketBody);
    console.log('socket calculate_price emit body', socketBody);
  }

  function handleSocketOnNewPrice(id, data) {
    if (id === socketUUID) {
      if (data?.status === 'error') {
        console.log('Socket new_price error', data);
        setSocketPrice(null);
      } else {
        console.log('Socket new_price data received', data);
        setSocketPrice(data);
      }
    }
  }

  function handleDialogEnter() {
    socket.on('new_price', handleSocketOnNewPrice);
    console.log('socket listen on new_price');
  }

  function handleDialogExited() {
    
    setSelectedPackage(null);
    setIsLoadingSelectedPackage(true);
    setSocketPrice(null);
    resetForm({
      duration: '',
    });
    socket.off('new_price');
    console.log('socket off new_price');
    onAfterExitAnimation();
  }

  /*Fetch Package*/
  useEffect(() => {
    if (selectedPackageId) {
      fetchPackage();
    }
  }, [selectedPackageId]);

  /*Socket Emit*/
  useEffect(() => {
    if (selectedPackageId && selectedPackage) {
      if (packageType === 'fixed') {
        handleSocketEmitCalcPrice();
      }
      if (packageType === 'dynamic' && duration) {
        handleSocketEmitCalcPrice();
      }
    }
  }, [duration, selectedPackageId, selectedPackage]);


  useEffect(()=>{

    if(duration){
      purchasePackagesEvents['selectPackage']()
    }
  },[duration])

  return (
      <FormProvider {...formMethods}>
        <DialogBase
            isLoading={isLoadingSelectedPackage}
            dividers={true}
            isOpen={isOpen}
            title={isPurchased ?
                t('packages.purchase.dialog.purchased.title') :
                t('packages.purchase.dialog.title')}
            onClose={handleDialogExited}
            onEnter={handleDialogEnter}
            onExited={handleDialogExited}
            contentSlot={
              <>
                <Box mb={3}></Box>
                {
                    isPurchased && !isNeedVerification &&
                    <Alert
                        severity={'success'}
                        sx={{mb: 2}}
                    >
                      <AlertTitle>
                        {t('package.purchase.alert.isPurchased.title')}
                      </AlertTitle>
                      {t('packages.purchase.alert.isPurchased.description')}
                    </Alert>
                }
                {isPurchased && isNeedVerification &&
                   <Alert
                   severity={'warning'}
                   sx={{mb: 2}}
               >{t('package.verify.message')} </Alert>
                }
                {
                    (!isAgeEligible && !isPurchased) &&
                    <Alert
                        severity={'warning'}
                        sx={{mb: 2}}
                        iconMapping={{
                          warning: <Lock/>,
                        }}
                    >
                      <AlertTitle>
                        {t('package.purchase.alert.unavailable')}
                      </AlertTitle>
                      {`${t(
                          'packages.purchase.tooltip.ageNotEligible')} ${packageMinAge} to ${packageMaxAge} ${t(
                          'global.types.time.years')}`}
                    </Alert>
                }
                <PackageBenefitDetails
                    isLoading={isLoadingSelectedPackage}
                    packageName={packageName}
                    packageDescription={packageDescription}
                    packageBenefitsList={packageBenefitsList}
                    discounts={discounts}
                />
                {
                    !isPurchased &&
                    <DialogSection
                        isLoading={isLoadingSelectedPackage}
                        label={t('packages.purchase.packageDuration')}
                        icon={Inventory}
                    >
                      {
                          isLoadingSelectedPackage &&
                          <Skeleton width={'200px'}/>
                      }
                      {
                          !isLoadingSelectedPackage && packageType ===
                          'dynamic' &&
                          <PackageDurationSelect
                              durations={durationPrices}
                          />
                      }
                      {
                          !isLoadingSelectedPackage && packageType ===
                          'fixed' &&
                          <PackageDurationDisplay
                              startDate={startDate}
                              endDate={endDate}
                              price={packagePrice}
                          />
                      }
                    </DialogSection>
                }
                {
                    !isPurchased &&
                    <DialogSection
                        isLoading={isLoadingSelectedPackage}
                        icon={ReceiptLongOutlined}
                        label={t('bookingAdd.billings')}
                        isLastChild={true}
                    >
                      <BillingDetails
                          isLoading={isLoadingSelectedPackage}
                          entries={billingEntries}
                          platformFee={platformFee}
                          platformPercentage={platformPercentage}
                          totalBillingAmount={totalPrice}
                      />
                    </DialogSection>
                }
              </>
            }
            actionsSlot={<>
              <Button
                  variant="outlined"
                  size={'large'}
                  color={'inherit'}
                  onClick={() => {
                    purchasePackagesEvents['close']();
                    onAfterExitAnimation();
                  }}
              >
                {t('global.buttons.actions.close')}
              </Button>

              {
                  !isPurchased &&
                  <Tooltip
                      placement={'top'}
                      arrow
                      title={(!isAgeEligible &&
                          `${t('package.purchase.alert.unavailable')}`)

                      }
                  >
                    <Box ml={1}>
                      <LoadingButton
                          variant={'contained'}
                          color={'primary'}
                          size={'large'}
                          loading={isSubmittingForm ||
                              isSubmittingPaymentCheckout}
                          onClick={handleSubmit}
                          disabled={!isAgeEligible}
                      >
                        <span>{t(
                            'global.buttons.actions.proceedToPayment')}</span>
                      </LoadingButton>
                    </Box>
                  </Tooltip>}
            </>}

        />
      </FormProvider>
  );
}

export default DialogPackagePurchase;