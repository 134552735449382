import {Box, Stack, useTheme} from '@mui/material';
import {LAYOUT_MAIN__MOBILE_HEADER__HEIGHT} from '../../constants/ui';
import ContainerFullWidth
  from '../../components/_Global/Containers/ContainerFullWidth';
import SiteMobileNav from '../../components/_Global/Site/SiteMobileNav';

function LayoutMainMobileHeader({navItems}) {

  const theme = useTheme();

  return (
      <>
        <SiteMobileNav navItems={navItems}/>
      </>
  );
}

export default LayoutMainMobileHeader;