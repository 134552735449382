import {Box, Stack, useTheme} from '@mui/material';
import {FacebookOutlined, Instagram, Language, X} from '@mui/icons-material';
import {NavLink} from 'react-router-dom';
import {externalUrl} from '../../utils/url';

function ClubInfoNewSocialLinks({clubDetails}) {

  const {facebook, instagram, twitter, website} = clubDetails || {};

  if (!facebook && !instagram && !twitter && !website) {
    return;
  }

  return (
      <Stack direction={'row'} alignItems={'center'} spacing={1} mb={2}>
        {
            website && <SocialItem url={website} Icon={Language}/>
        }
        {
            facebook && <SocialItem url={facebook} Icon={FacebookOutlined}/>
        }
        {
            instagram && <SocialItem url={instagram} Icon={Instagram}/>
        }
        {
            twitter && <SocialItem url={twitter} Icon={X}/>
        }
      </Stack>
  );

}

function SocialItem({url, Icon}) {

  const theme = useTheme();
  return (
      <Box
          component={NavLink}
          to={externalUrl(url)}
          target="_blank"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '32px',
            height: '32px',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
            cursor: 'pointer',
          }}
      >
        {Icon && <Icon fontSize={'small'} color={'action'}/>}
      </Box>
  );
}

export default ClubInfoNewSocialLinks;
