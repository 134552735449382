import {createContext} from 'react';

export const ContextExperience = createContext();

function ProviderExperience({
                                  clubDetails,
                                  clubPolicies,
                                  fetchClubPolicies,
                                  clubSports,
                                  clubTimings,
                                  companyDetails,
                                  children,
                                }) {

  return (
      <ContextExperience.Provider
          value={{
            clubDetails,
            clubPolicies,
            fetchClubPolicies,
            clubSports,
            clubTimings,
            companyDetails,
          }}>
        {children}
      </ContextExperience.Provider>
  );
}

export default ProviderExperience;
