import {Box} from '@mui/material';
import ContainerFixed from '../../components/_Global/Containers/ContainerFixed';

function LayoutClubPage({children}) {
  return (
      <Box sx={{pb:3}}>
        <ContainerFixed>
          {children}
        </ContainerFixed>
      </Box>
  );
}

export default LayoutClubPage;
