import { useEffect, useState } from "react";
import { rankingDetailsPerSport, sportsList_GET } from "../../vendor/redux/actions/player";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import SportsRating from "../_Global/Inputs/SportsRating";
import { useTranslate } from "@tolgee/react";
import { useFormContext } from "react-hook-form";

const SelectUserRatings = ({loading=false,isRequired}) => {

  const {t} = useTranslate();
  const dispatch = useDispatch();
  const { clubId } = useParams();

  const [sportsList, setSportsList] = useState([]);
  const [sportsLoading, setSportsLoading] = useState(loading);
  const [ratingSystem, setRatingSystem] = useState();

  const {control,formState,watch,setValue} = useFormContext();
  const {errors} = formState;
  const watched = watch();

  const fetchAndSetSports = () => {
    setSportsLoading(true);
    dispatch(sportsList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log("sportsList_GET Success", res);

      setSportsList(
        res?.data?.data?.sort((a, b) => a.name.localeCompare(b.name))
      );
      setSportsLoading(false)
    }

    function cbFail(e) {
      setSportsLoading(false);
      console.log("sportsList_GET Fail", e);
    }
  };

  useEffect(() => {
    fetchAndSetSports();
  }, []);

  
  useEffect(()=>{

    if(sportsList?.length){
      dispatch(rankingDetailsPerSport(clubId,cbSuccess,cbFail));

      function cbSuccess(res){
        const response = res?.data?.data;
        setRatingSystem(response);
      }
     
      function cbFail(res){
        console.log('Err on rating system get : ',res)
      }
    }

  },[sportsList])

  if (sportsLoading)
    return <Skeleton width={"100%"} height={"100px"} sx={{ mr: "auto" }} />;

  return (
    <>
      {!sportsLoading &&
        sportsList?.length > 0 &&
        sportsList?.map((sport) => {
          const fieldName = `${sport?.name?.toLowerCase()}Rating`;
          const sportRatingSystem = ratingSystem?.find(
            (x) => x?.sport?.toLowerCase() == sport?.name?.toLowerCase()
          )?.ratingSystem?.detail;

          return (
            <SportsRating
              name={fieldName}
              errorName={t("profile.error.rating")}
              label={t("profile.rating")}
              options={sportRatingSystem}
              errors={errors}
              control={control}
              sport={sport?.name}
              watched={watched}
              isRequired={isRequired}
              handleChange={(e) => setValue(fieldName, e.target.value)}
            />
          );
        })}
    </>
  );
};
export default SelectUserRatings;
