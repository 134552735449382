import {Stack, Typography} from '@mui/material';
import {
  EmailOutlined,
  LocalPhoneOutlined,
  RoomOutlined,
} from '@mui/icons-material';
import {useMemo} from 'react';

function ClubInfoNewContact({clubDetails}) {

  const {email, contact: phone, addresses} = clubDetails || {};

  const address = useMemo(() => {
    const address = addresses?.[0];
    if (address) {
      const output = [
          address?.streetAddress,
          address?.city,
          address?.country,
      ].filter(Boolean);

      if (output.length > 0) {
        return output.join(', ');
      }
    }
  },[addresses]);

  if (!email && !phone && !address) {
    return;
  }

  return (
      <Stack
          direction={'row'}
          flexWrap={'wrap'}
          columnGap={2}
          rowGap={2} mb={2}
          maxWidth={'422px'}
      >
        {
            email &&
            <ContactItem label={email} Icon={EmailOutlined}/>
        }
        {
            phone &&
            <ContactItem label={phone} Icon={LocalPhoneOutlined}/>
        }
        {
            address &&
            <ContactItem label={address} Icon={RoomOutlined}/>
        }
      </Stack>
  );

}

function ContactItem({Icon, label}) {
  return (
      <Stack direction={'row'} alignItems={'flex-start'} columnGap={1}>
        {Icon && <Icon fontSize={'small'} color={'action'} sx={{
          position: 'relative',
          top:2,
        }}/>}
        <Typography variant={'body1'} >
          {label}
        </Typography>
      </Stack>
  );

}

export default ClubInfoNewContact;
