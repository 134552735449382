function IconClock({className, fill}) {
    return (
        <svg width="17" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.9375 0.585938C8.04622 0.585937 6.19741 1.14677 4.62486 2.19751C3.05232 3.24825 1.82667 4.74171 1.10291 6.48903C0.379143 8.23635 0.189774 10.159 0.558745 12.014C0.927716 13.8689 1.83846 15.5728 3.1758 16.9101C4.51314 18.2475 6.21701 19.1582 8.07195 19.5272C9.9269 19.8962 11.8496 19.7068 13.5969 18.983C15.3442 18.2593 16.8377 17.0336 17.8884 15.4611C18.9392 13.8885 19.5 12.0397 19.5 10.1484C19.497 7.61322 18.4886 5.18269 16.6959 3.39002C14.9033 1.59735 12.4727 0.588915 9.9375 0.585938ZM9.9375 18.5859C8.26872 18.5859 6.63742 18.0911 5.24988 17.164C3.86234 16.2368 2.78088 14.9191 2.14227 13.3773C1.50366 11.8356 1.33657 10.1391 1.66213 8.50236C1.98769 6.86565 2.79129 5.36223 3.97129 4.18222C5.1513 3.00222 6.65472 2.19862 8.29143 1.87306C9.92814 1.5475 11.6246 1.71459 13.1664 2.3532C14.7081 2.99182 16.0259 4.07327 16.953 5.46081C17.8802 6.84835 18.375 8.47966 18.375 10.1484C18.3725 12.3854 17.4828 14.5301 15.901 16.1119C14.3192 17.6937 12.1745 18.5835 9.9375 18.5859ZM15.75 10.1484C15.75 10.2976 15.6907 10.4407 15.5853 10.5462C15.4798 10.6517 15.3367 10.7109 15.1875 10.7109H11.2959L14.085 13.5009C14.1403 13.5524 14.1846 13.6145 14.2153 13.6835C14.2461 13.7525 14.2626 13.827 14.2639 13.9025C14.2653 13.9781 14.2514 14.0531 14.2231 14.1231C14.1948 14.1932 14.1527 14.2568 14.0993 14.3102C14.0459 14.3636 13.9822 14.4057 13.9122 14.434C13.8422 14.4623 13.7671 14.4762 13.6916 14.4749C13.6161 14.4735 13.5416 14.457 13.4726 14.4263C13.4036 14.3955 13.3415 14.3512 13.29 14.2959L9.54 10.5459C9.46144 10.4673 9.40794 10.3671 9.38628 10.258C9.36462 10.149 9.37576 10.036 9.41829 9.93324C9.46082 9.83052 9.53284 9.74271 9.62526 9.68089C9.71767 9.61908 9.82632 9.58604 9.9375 9.58594H15.1875C15.3367 9.58594 15.4798 9.6452 15.5853 9.75069C15.6907 9.85618 15.75 9.99925 15.75 10.1484Z" fill="black" fill-opacity="0.87"/>
</svg>

    );
  }
  
  export default IconClock;
  