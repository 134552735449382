import {useMemo} from 'react';
import {Stack, Typography} from '@mui/material';
import useLocale from '../../../hooks/localization/useLocale';
import {getLocalizedNum} from '../../../utils/localization';
import {useTranslate} from '@tolgee/react';
import dayjs from 'dayjs';

function PackageBenefitDetailsDiscountsRow({discount}) {

  const courts = discount?.courts;
  const days = discount?.days;
  const startTime = discount?.startTime;
  const endTime = discount?.endTime;
  const discountAmount = +discount?.discount;

  return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack spacing={.25}>
          <CourtsList courts={courts}/>
          <DaysList days={days}/>
          <Timings startTime={startTime} endTime={endTime}/>
        </Stack>
        <DiscountAmount discount={discountAmount}/>
      </Stack>
  );

}

function CourtsList({courts}) {
  const courtNames = courts.map((court) => {
    return court.name;
  });
  return (
      <Typography maxWidth={'403px'} paddingRight={'16px'}>
        {courtNames.join(', ')}
      </Typography>
  );
}

function DaysList({days}) {

  const {t} = useTranslate();
  const {locale} = useLocale();

  const isAllWeek = useMemo(() => {
    return days?.length === 7;
  }, [days]);

  const localizedDays = useMemo(() => {
    return days.map((day) => {
      return t(`global.types.days.${day}`);
    });
  }, [days, locale]);

  let output;

  if (isAllWeek) {
    output = t('packages.discounts.days.allWeek');
  }

  if (!isAllWeek) {
    output = localizedDays.join(', ');
  }

  return (
      <Typography variant={'body2'}>
        {output}
      </Typography>
  );

}

function Timings({startTime, endTime}) {

  const {locale} = useLocale();
  const {t} = useTranslate();

  const startT = dayjs(startTime,'HH:mm').locale(locale).format('LT');
  const endT = dayjs(endTime,'HH:mm').locale(locale).format('LT');

  return (
      <Typography variant={'body2'}>
        {`${startT} ${t('global.keywords.to')} ${endT}`}
      </Typography>
  )
}

function DiscountAmount({discount}) {
  return (
      <Typography fontWeight={600}>
        {`${discount}%`}
      </Typography>
  );
}

export default PackageBenefitDetailsDiscountsRow;