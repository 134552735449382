import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {useTranslate} from '@tolgee/react';
import {
  customersBookingsSingle_GET,
} from '../../vendor/redux/actions/bookings';
import {useSnackbar} from 'notistack';
import {ERR_NETWORK_ERROR} from '../../constants/errors';

function useBooking(bookingId) {

  const dispatch = useDispatch();
  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();

  const [booking, setBooking] = useState(null);

  function fetchAndSetBooking() {

    dispatch(customersBookingsSingle_GET(bookingId, cbSuccess, cbFailure));

    function cbSuccess(res) {
      console.log('customersBookingsSingle_GET success', res);
      setBooking(res?.data?.data);
    }

    function cbFailure(e) {
      console.log('bookingsByID_GET fail', e);
      const err = e?.response?.data?.data || ERR_NETWORK_ERROR;
      enqueueSnackbar(t(err), {variant: 'error'});
    }
  }

  useEffect(() => {
    fetchAndSetBooking();
  }, []);

  return [booking, fetchAndSetBooking];
}

export default useBooking;
