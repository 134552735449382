import { useEffect, useState } from "react";

const useDebounce=(initValue,delay)=>{

    const [value,setValue] = useState(initValue);

    useEffect(()=>{

        let timer;
        if(timer) clearTimeout(timer);

        timer = setTimeout(()=>setValue(initValue),delay);
        return()=>clearTimeout(timer)

    },[initValue])

    return value
}

export default useDebounce