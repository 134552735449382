import {
  Box,
  Grid,
  Stack,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslate} from '@tolgee/react';
import {SwiperButton} from '../../_Global/Swiper/SwiperButton';
import {Swiper, SwiperSlide} from 'swiper/react';
import {PackageSliderSlide} from './PackageSliderSlide';
import {
  customersPackagesList_GET,
} from '../../../vendor/redux/actions/packages';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import PageCardTitle from '../../_Global/Page/PageCardTitle';
import theme from '../../../vendor/mui/theme';
import { RedeemTwoTone } from '@mui/icons-material';


function PackageSlider() {

  const {t} = useTranslate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width:825px)`);
  const swiperRef = useRef();
  const navigateTo = useNavigate();
  const {clubId} = useParams();
  const theme = useTheme()
  const isAuthenticated = useIsAuthenticated();
  
  const [clubPackages, setClubPackages] = useState();
  const [isLoadingClubPackages, setIsLoadingClubPackages] = useState(true);
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [isOpenPurchaseDialog, setIsOpenPurchaseDialog] = useState(false);

  const activePackages = clubPackages?.filter((clubPackage) => {
    return clubPackage?.status === 'active';
  });

  let slidesPerView = 1;

  function handleClickPurchase(clubPackage) {

    const {id: packageId, isPurchased} = clubPackage;
    setSelectedPackageId(packageId);
    setIsOpenPurchaseDialog(true);

    if (!isPurchased) {
      navigateTo(`packages/${packageId}/add`);
    } else {
      navigateTo(`packages/${packageId}/detail`);
    }

  }

  function fetchClubPackages() {

    setIsLoadingClubPackages(true);
    dispatch(customersPackagesList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersPackagesList_GET Success', res);
      setClubPackages(res?.data?.data?.rows || []);
      setIsLoadingClubPackages(false);
    }

    function cbFail(e) {
      console.log('customersPackagesList_GET Fail', e);
      setClubPackages([]);
      setIsLoadingClubPackages(false);
    }
  }

  useEffect(() => {
    fetchClubPackages();
  }, [isAuthenticated]);

  return (
      <Box
      sx={{
        minHeight: '400px',
      }}
      >
        <PageCardTitle
            title={t('clubInfo.packages.title')}
        />
        {
            !isLoadingClubPackages && activePackages.length === 0 &&
            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{mt:isMobile?'45%':'25%'}} rowGap={1}>
            <RedeemTwoTone color={'action'} fontSize='large'/>  
            <Typography variant="subtitle2" color={theme.palette.grey[600]} >
              {t('club.noPackages')}
            </Typography>
            </Stack>
        }
        {
            !isLoadingClubPackages && activePackages.length > 0 &&
            <Box sx={{position: 'relative'}}
            >
              <Box
                  sx={{
                    position: 'absolute',
                    left: '-18px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 3,
                  }}
              >
                {
                    slideActiveIndex > 0 &&
                    <SwiperButton
                        direction={'prev'}
                        onClick={() => {
                          swiperRef.current.slidePrev();
                          setSlideActiveIndex(
                              swiperRef.current.activeIndex);
                        }}
                    />
                }
              </Box>
              <Box
                  sx={{
                    position: 'absolute',
                    right: '-18px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 3,
                  }}
              >
                {
                    (slideActiveIndex !== activePackages?.length -
                        slidesPerView) &&
                    <SwiperButton
                        direction={'next'}
                        onClick={() => {
                          swiperRef.current.slideNext();
                          setSlideActiveIndex(
                              swiperRef.current.activeIndex);
                        }}
                    />
                }
              </Box>
              <Swiper
                  slidesPerView={slidesPerView}
                  spaceBetween={20}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  onSlideChange={(swiper) => {
                    setSlideActiveIndex(swiper.activeIndex);
                  }}
              >
                {
                  activePackages?.map((clubPackage, index) => {
                    return <SwiperSlide key={index}>
                      <PackageSliderSlide
                          clubPackage={clubPackage}
                          index={index}
                          handleClickPurchase={handleClickPurchase}
                      />
                    </SwiperSlide>;
                  })
                }
              </Swiper>

            </Box>
        }
      </Box>

  );


}

export default PackageSlider;
