import { Accordion, alpha, backdropClasses, Box, Grid, IconButton, List, Stack, styled, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Fragment } from "react";
import useIsMobile from "../../../hooks/ui/useIsMobile";

export const DateTimeCardContainer=styled(Stack)(({theme})=>{

    return{
        flexDirection:'row',
        flexWrap:'wrap',
        rowGap:'20px',
        minHeight:'84px',
        background:theme.palette.grey[50],
        padding:'1rem',
        borderRadius:'12px',
        border:`1px solid ${theme.palette.divider}`,
    }

})

export const DetailPageHeaderContainer=styled(Grid)(({theme,border,padding,margin})=>{

    return{
        rowGap:'20px',
        minHeight:'84px',
        padding:padding ?? '1rem',
        borderRadius:'12px',
        border:border ?? `1px solid ${theme.palette.divider}`,
        margin:margin,

        '.headerTile':{
            background:theme.palette.grey[100],
            borderRadius:'8px',
            padding:'8px 24px',

            svg:{
                fill: theme.palette.icon.primary
            }
        }
    }

})

export const CreateMatchBodyContainer=styled(Stack)(({theme})=>{

    return{
        marginTop:'1rem',
        borderRadius:'12px',
        border:`1px solid ${theme.palette.divider}`,
        padding:'1.5rem',

        '.sectionTitle':{
            textTransform: 'capitalize'
        }
    }
})

export const MatchTypeContainer=styled(Box)(({theme})=>{

    return{

        '.flexContainer':{
            flexDirection:'row',
            alignItems:'center',
            columnGap:8,
        },
        
        '.inputContainer':{
            padding:'1rem .5rem',
            paddingBottom:0,
            rowGap:'1.25rem'
        },
        '.MuiSlider-root':{
            position:'relative',
            '.MuiSlider-thumb':{
                position:'absolute',
            },
            
        }
    }

})

export const GenderCardContainer=styled(Stack)(({theme,isSelected,disabled})=>{

    return{

        flexDirection:'row',
        justifyContent:'space-between',
        position:'relative',
        flex:'1 0 230px',
        minHeight:'100px',
        cursor:!disabled && 'pointer',
        borderRadius:'12px',
        border:isSelected? `2px solid ${theme.palette.primary.main}` :`1px solid ${theme.palette.divider}`,
        backgroundColor:isSelected && `${alpha(theme.palette.primary.main,0.04)}`,
        opacity:disabled && 0.5,
        
        padding:'1rem',

        '.MuiFormControlLabel-root':{
            margin:0,
            display:'block',

            '.MuiButtonBase-root':{
                paddingLeft:0
            }
        },
        '.description':{
            paddingTop:'4px'
        },
        '.MuiButtonBase-root':{
            padding:'4px 8px'
        },
        '.subtitle':{
            fontSize:'12px',
            color:theme.palette.grey[500],
        },
        '.MuiAvatar-root':{
            postition:'absolute',
            top:'-10%',
            right:'-11%',
            height:'42px',
            width:'42px',
            background:'transparent',
            color:theme.palette.grey[500],
            border:`1px solid ${theme.palette.divider}`,
            marginLeft:'8'
        },
    }
})

export const PlayerListContainer=styled(Stack)(({theme,updatedDesign,isMobile,alignItems,team1Padding,team2Padding,teamSize})=>{

    return{
        
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'center',
        alignItems:alignItems,
        columnGap:isMobile && 1,
        rowGap:8,

        '.teamContainer':{
            flexDirection:'row',
            flexWrap:'wrap',
            columnGap:12,
            rowGap:15,
            flex:`1 0 ${isMobile?'100%':'48%'}`,
        },

        '.team1':{
            borderRight:`${!updatedDesign && (!isMobile? `1px solid ${theme.palette.divider}` : 'none')}`,
            borderBottom:`${!updatedDesign && (isMobile? `1px solid ${theme.palette.divider}` : 'none')}`,
            paddingBottom: `${isMobile? '.5rem':0}`,
            paddingLeft:!isMobile && team1Padding,
            justifyContent:updatedDesign && teamSize==2?'start':'center',
        },
        
        '.team2':{
            paddingLeft:!isMobile && team2Padding,
            justifyContent:updatedDesign && teamSize==2?'end':'center'
        }
    }

})

export const PlayerCardContainer=styled(Stack)(({theme,isMobile,isDisabled})=>{

    return{

        alignItems:'center',
        flex:isMobile? '1 0 125px' : `0 0 160.25px`,
        minHeight:'154px',
        padding:'1rem',
        border:`1px solid ${theme.palette.divider}`,
        cursor:!isDisabled && 'pointer',
        borderRadius:'12px',
        position:'relative',

        '&:hover':{

            '.closeBtn':{
                display:'flex'
            }
        },
           
        '.paymentStatus':{

            position:'absolute',
            top:-15

        },
        
        '.closeBtn':{

            display:'none',
            cursor:'pointer',
            transition: '.2s ease-in-out all',
            position:'absolute',
            top:'-18px',
            right:'-15px',
            zIndex:'1',
            background: 'rgba(254, 235, 238, 1)',
            border:'4px solid #FFFFFF',
        },
    }
})

export const AvatarIconContainer=styled(Stack)(({theme,teamSize,isMobile,flex = '1 0 48%',gender })=>{

    return{

        // flex,
        justifyContent:'center',
        alignItems:(teamSize==1 || isMobile)?'center':'flex-start',
        
        '.profileImage':{
            
                border:'none',
                padding:0,

            '.initials':{
                color:'black',
                background: '#dce4ff',
                background: gender!='male'?'-webkit-linear-gradient(197.38deg, #FFD4EE 18.67%, #D0DBFF 88.08%)':'-webkit-linear-gradient(197.38deg, #C7EBFF 18.67%, #D0DBFF 88.08%)',
                background: gender!='male'?'linear-gradient(197.38deg, #FFD4EE 18.67%, #D0DBFF 88.08%)':'linear-gradient(197.38deg, #C7EBFF 18.67%, #D0DBFF 88.08%)',
                height:'100%',
                width:'100%'
            }
        },
    }
})

export const LevelChipContainer=styled(Stack)(({theme})=>{

    return{

        background: '-webkit-linear-gradient(101.83deg, #4994EC 12.31%, #A895F2 84.41%)',
        background: 'linear-gradient(101.83deg, #4994EC 12.31%, #A895F2 84.41%)',
        width: '34px',
        height: '17px',
        borderRadius: '4px',
        opacity: '0px',
      
        'p':{
            fontSize:'10.69px',
            fontWeight:700,
            textAlign:'center',
            color:'white'
        },
    }
})

export const AddPlayerIconBtn= styled(IconButton)(({theme,width,height,canAddPartner})=>{

    return{
        width: width ?? '60px',
        height: height ?? '60px',
        border:`3px dashed ${canAddPartner?theme.palette.primary.main:theme.palette.action.disabled}`
    }
})

export const AddPlayerContentContainer=styled(Stack)(({theme,isMobile})=>{

    return{
        overflowY:'auto',
        padding:'1rem 0',
        rowGap:'1rem',
        minHeight:'70vh',
        width:isMobile?'auto':'448px'
    }

})

export const SuggestedPlayersList=styled(List)(({theme})=>{

    return{

        height:'50vh',
        overflowY:'auto',
        paddingTop:0,
        '.MuiListItem-root':{
            
            display:'flex',
            justifyContent:'space-between', 
            columnGap:'1rem',
            padding:'16px',
            border:`1px solid ${theme.palette.divider}`,
            cursor:'pointer',
            borderRadius:'12px',
            margin:'1rem 0',

            '.profileImage':{

                border:'none',
                padding:0,

                '.initials':{
                    color:'black',
                    background: '#dce4ff',
                    background: '-webkit-linear-gradient(0deg, #dce4ff 0%, #ffeae9 100%)',
                    background: 'linear-gradient(0deg, #dce4ff 0%, #ffeae9 100%)',
                    height:'100%',
                    width:'100%',
                    fontSize:'11.43px'
                }
            }
        },
        '.disabledPlayer':{
            background:`${theme.palette.grey[300]} !important`,
            opacity:0.5
        }
        
    }
})

export const ExtrasListContainer = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    
    
    background:'transparent',
    marginTop:'1rem',
    padding:'1.5rem',
    border:`1px solid ${theme.palette.divider}`,
    borderRadius:'12px',
    
    '::before':{
        display:'none'
    },

    '.MuiAccordionSummary-root':{
        padding:0,
        minHeight:'auto'
    },
    '.MuiAccordionSummary-content':{
        margin:0,

        '.MuiSvgIcon-root[data-testid="SportsBaseballOutlinedIcon"]':{
            color:theme.palette.grey[500],
            marginRight:'10px'
        }
    },

    '.MuiAccordionDetails-root':{
        paddingRight:0,
        // paddingLeft:0
    }

  }));

  export const BillingSectionContainer=styled(Stack)(({theme})=>{
    const isMobile = useIsMobile();
    return{
        
        position:'sticky',
        top:10,
        border:`1px solid ${theme.palette.divider}`,
        borderRadius:'12px',
        gap:0,
        spacing:'0',
        rowGap:0,

        '.head':{

            alignItems:'center',
            borderRadius:'12px 12px 0 0',
            padding:'16px 24px 0 24px',
        },

        '.section':{
            padding:'12px 24px 0 24px',

            'hr':{
                margin:'6px 0'
            }
        },
        '.isLast':{
            borderBottom:'none !important',
            padding:isMobile ? '12px 24px 9px 24px' :'12px 24px 24px 24px'
        }


    }

  })

  export const BillingContainer = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    
    '::before':{
        display:'none'
    },
    
    '.MuiAccordionSummary-root':{
        padding:0,
        minHeight:'auto',
        
    },
    '.MuiAccordionSummary-content':{
        margin:0,
        position:'relative !important',
        '.MuiSvgIcon-root':{
            color:theme.palette.grey[500],
        }
    },
    '.priceArrow':{
        position:'absolute !important',
        right:'-7px'
    },

    '.MuiAccordionDetails-root':{
        paddingRight:0,
        paddingLeft:0,
    }

}));

export const SingleSectionContainer=styled(Stack)(({theme,isMobile,isLast,isFirst,pl,pr,w,mobileWidth,jc,alignItems,fg,fs})=>{

    return{

        flexDirection:'row',
        justifyContent:!isMobile && (jc ?? 'center'),
        columnGap:8,
        rowGap:8,
        flexGrow: isMobile? 1 : (fg ?? 1),
        flexShrink: fs ?? 0,
        flexBasis: `${isMobile? (mobileWidth ?? '100%') : (w ?? '48%')}`,
        
        // width:isMobile?(mobileWidth ?? '100%'):(w ?? '48%'),
        alignItems: 'end',

        borderRight:`${!isMobile?(isLast?'none':`1px solid ${theme.palette.divider}`):'none'}`,
        paddingLeft: `${!isMobile && !isFirst && (pl ?? '20px')}`,
        paddingRight: `${!isMobile && (pr ?? isFirst) && (pr ?? '1rem')}`,
        'img': {
            width: isMobile ? '16px' : '22px',
            height: isMobile ? '16px' : '22px'
        },
        'svg':{
            color:theme.palette.grey[600]
        },
        '.MuiAvatar-root':{
            background:'transparent',
            color:theme.palette.grey[500],
            border:`1px solid ${theme.palette.divider}`
        },

        '.title':{
            fontSize:'12px'
        },

        '.subtitle':{

            textTransform:'capitalize'

        }
        
    }

})


export const CopyLinkFieldContainer=styled(Box)(({theme})=>{

    return{

        background:'#F6FAFD',
        minHeight:'82px',
        padding: '22px 12px 10px 12px',
        gap: '16px',
        borderRadius: '12px',
        border: '1px',
        opacity: '0px',

        '.MuiInputBase-root':{
            paddingRight:'0',
            fontSize:'14px'
        },

        'input':{
            color:'black !important',
            '-webkit-text-fill-color':'black !important'
        },
        '.MuiButtonBase-root':{
            width:'34%'
        }
    }
})

export const ClippedText=styled(Typography)(({theme,clampLine, isMobile})=>{

    return{
        textAlign: isMobile ? 'center':'left',
        fontSize: isMobile ? '13px': '16px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        "-webkit-line-clamp": clampLine ?? '1',
        "-webkit-box-orient": 'vertical',
        width: '100%',
    }
})

export const StyledDatePicker=styled(DatePicker)(({theme,isMobile})=>{

    return{
        width:isMobile?'100%':'200px',
        '.MuiInputBase-root':{
            
            paddingRight:'12px',
            height:'46px',
            '.MuiInputBase-input':{
                padding:'12px 12px'
            },
        },
        
        '.css-d36u84-MuiFormLabel-root-MuiInputLabel-root':{
                transform: 'translate(14px, 12px) scale(1) !important'
        },
    
        '.Mui-focused':{
            'label':{
                transform: 'translate(14px, -19px) scale(0.75) !important'
            }
        }
    }

})