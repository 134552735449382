import {Alert, Box, Grid, Typography, useTheme} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  customersClubsLocation_GET,
} from '../../vendor/redux/actions/clubs-location';
import {LocationOff} from '@mui/icons-material';
import {useTranslate} from '@tolgee/react';
import {Map, Marker, Overlay, ZoomControl} from 'pigeon-maps';

function ClubInfoNewLocation({clubId, height = '500px'}) {

  const dispatch = useDispatch();
  const theme = useTheme();
  const {t} = useTranslate();

  const [clubLocation, setClubLocation] = useState();
  const [showPopover, setShowPopOver] = useState(false);

  const lat = clubLocation?.latitude;
  const long = clubLocation?.longitude;
  const streetAddress = clubLocation?.streetAddress;
  const postalCode = clubLocation?.postalCode;
  const city = clubLocation?.city;
  const country = clubLocation?.country;

  function fetchClubLocation() {

    dispatch(customersClubsLocation_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersClubsLocation_GET Success', res);
      const {
        data: {
          data: {
            latitude,
            longitude,
            streetAddress,
            postalCode,
            city,
            country,
          },
        },
      } = res;
      setClubLocation({
        latitude,
        longitude,
        streetAddress,
        postalCode,
        city,
        country,
      });
    }

    function cbFail(e) {
      console.log('customersClubsLocation_GET Fail', e);
    }
  }

  useEffect(() => {
    if (clubId) {
      fetchClubLocation();
    }
  }, [clubId]);

  if (!lat || !long) {
    return;
  }

  return (
      <Grid item xs={12}>
      <Box>
        <Typography variant={'h6-bold'} mb={2}>
          {t('clubInfo.location.title')}
        </Typography>
        <Box
            sx={{
              width: '100%',
              height,
              borderRadius: '12px',
              backgroundColor: theme.palette.grey[100],
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
        >
          {
              clubLocation && (!lat && !long) &&
              <Alert icon={<LocationOff/>} severity={''}>{t(
                  'global.components.map.noMap')}</Alert>
          }
          {
              clubLocation && (lat && long) &&
              <Map
                  height={height}
                  defaultCenter={[lat, long]}
                  defaultZoom={18}
                  attribution={false}
              >
                <ZoomControl/>
                {
                    showPopover &&
                    <Overlay
                        anchor={[lat, long]}
                        offset={[-45, 80]}
                    >
                      <PopOver
                          address={streetAddress}
                          postalCode={postalCode}
                          city={city}
                          country={country}
                      />
                    </Overlay>
                }
                <Marker
                    width={50}
                    anchor={[lat, long]}
                    color={theme.palette.primary.main}
                    onClick={() => {
                      const url = `https://maps.google.com/?q=${lat},${long}`;
                      window.open(url, '_blank');
                    }}
                    onMouseOver={() => {
                      setShowPopOver(true);
                    }}
                    onMouseOut={() => {
                      setShowPopOver(false);
                    }}
                >
                </Marker>
              </Map>
          }
        </Box>
      </Box>
      </Grid>
  );

}

function PopOver({address, postalCode, city, country}) {
  return <Box
      sx={{
        position: 'relative',
        backgroundColor: '#fff',
        borderRadius: '8px',
        p: 3,
        boxShadow: 3,
      }}
  >
    <Box
        sx={{
          width: 0,
          height: 0,
          borderWidth: '9px 26px 9px 0',
          borderColor: 'transparent #fff transparent transparent',
          borderStyle: 'solid',
          position: 'absolute',
          left: '-15px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
    >

    </Box>
    <Typography>{`${address}, ${postalCode}`}</Typography>
    <Typography>{`${city}, ${country}`}</Typography>
  </Box>;
}

export default ClubInfoNewLocation;
