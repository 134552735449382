import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTranslate} from '@tolgee/react';

function DialogConfirmation({
  variant = 'error' || 'primary',
  dialogTitle,
  dialogContent,
  confirmButtonLabel,
  handleConfirm,
  isOpen,
  handleClose,
  isSubmitting,
  handleAfterExitAnimation = () => {
  },
}) {

  const {t} = useTranslate();

  const dialogTitleStyles = {
    ...(variant === 'error' &&
        {backgroundColor: '#EB495C0A'}),
    ...(variant === 'primary' &&
        {backgroundColor: 'rgba(2, 136, 209, 0.04)'}),
  };

  return (
      <Dialog
          open={isOpen}
          onClose={()=>{
            if (!isSubmitting){
              handleClose()
            }
          }}
          scroll={'body'}
          TransitionProps={{
            onExited: handleAfterExitAnimation,
          }}
          PaperProps={{
            sx: {
              maxWidth: '600px',
              overflow: 'hidden',
              position: 'relative',
            },
          }}
      >
        {
            isSubmitting &&
            <LinearProgress
                color={variant}
                sx={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  width: '100%',
                }}
            />
        }
        <DialogTitle variant={'h6'} sx={dialogTitleStyles}>
          {dialogTitle}
        </DialogTitle>
        {dialogContent && <DialogContent dividers={false}>
          <Box sx={{padding: '22px 0'}}>
            <Typography variant={'body1'} color={'text.secondary'} >
              {dialogContent}
            </Typography>
          </Box>
        </DialogContent>}
        <DialogActions>
          <Button
              size={'large'}
              variant={'outlined'}
              color={'inherit'}
              disabled={isSubmitting}
              onClick={handleClose}
          >
            {t('global.buttons.actions.close')}
          </Button>
          <LoadingButton
              size={'large'}
              variant={'contained'}
              color={variant}
              loading={isSubmitting}
              disabled={isSubmitting}
              onClick={handleConfirm}
          >
            <span>
            {confirmButtonLabel}
            </span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
  );
}

export default DialogConfirmation;
