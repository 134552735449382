import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {useEffect, useRef, useState} from 'react';
import {IconButton, Stack} from '@mui/material';

function ExtrasSelectNumberInput({item, onChange, defaultCount}) {

  const [count, setCount] = useState(defaultCount || 0);
  const mountedRef = useRef();

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      // do nothing on mount
    } else {
      //run only when count updates
      onChange({
        id: item.id,
        name: item.name,
        price: item.price,
        count: count,
      });
    }
  }, [count]);

  function handleIncrement(e) {
    e.target.blur();
    setCount((prevState) => ++prevState);
  }

  function handleDecrement(e) {
    e.target.blur();
    if (count >= 1) {
      setCount((prevState) => --prevState);
    }
  }

  return (
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <IconButton
            size={'small'}
            className="decrement"
            onClick={handleDecrement}
        >
          <RemoveIcon fontSize={'small'}/>
        </IconButton>
        <div className="count">{count}</div>
        <IconButton className="increment"
                    onClick={handleIncrement}
        >
          <AddIcon fontSize={'small'}/>
        </IconButton>
      </Stack>
  );
}

export default ExtrasSelectNumberInput;
