import {Controller, useFormContext} from 'react-hook-form';
import {
  Chip,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {getHumanizedDuration} from '../../utils/packages';
import useDefaults from '../../hooks/defaults/useDefaults';

function PackageDurationSelect({durations}) {

  const {t} = useTranslate();
  const {control} = useFormContext();
  const {currencySymbol} = useDefaults();
  return (
      <Controller
          control={control}
          name="duration"
          rules={{
            required: {
              value: true,
              message: t('package.purchase.durationSelect.validation.required'),
            },
          }}
          render={({field, fieldState: {error}}) => {
            return (
                <Stack>
                  <Select
                      variant={'standard'}
                      defaultValue={''}
                      displayEmpty
                      error={error}
                      renderValue={(value) => {
                        if (!value) {
                          return t(
                              'packages.purchase.durationSelect.placeholder');
                        } else {
                          return getHumanizedDuration(value, t);
                        }
                      }}
                      {...field}
                  >
                    {
                      durations.map((duration, index) => {
                        return (
                            <MenuItem
                                key={index}
                                value={duration.duration}
                                divider={index !== (durations?.length -1) }
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingY:2
                                }}
                            >
                              <Typography>
                                {getHumanizedDuration(duration.duration, t)}
                              </Typography>
                              <Chip
                                  color={'success'}
                                  size={'small'}
                                  label={`${currencySymbol}${duration.price}`}
                              />
                            </MenuItem>
                        );
                      })
                    }
                  </Select>
                  {
                      error &&
                      <FormHelperText error>
                        {error?.message}
                      </FormHelperText>
                  }
                </Stack>
            );
          }}
      />
  );

}

export default PackageDurationSelect;