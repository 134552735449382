import {Box, Stack, Typography} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {useEffect, useState} from 'react';
import {
  getDayTranslation,
  getSortedDaysTimings,
} from '../../utils/date-and-time';
import dayjs from 'dayjs';

function ClubInfoNewTiming({clubTimings}) {

  const {t} = useTranslate();

  const [sortedTimings, setSortedTimings] = useState();

  useEffect(() => {
    if (clubTimings) {
      setSortedTimings(getSortedDaysTimings(clubTimings));
    }
  }, [clubTimings]);

  return (
      <Box>
        <Typography variant="h6-bold" mb={3}>
          {t('clubsDetail.timeHeading')}
        </Typography>
        {
            sortedTimings &&
            <Stack spacing={3}>
              {sortedTimings.map((timing, index) => {
                return <TimingItem key={index} timing={timing}/>;
              })}
            </Stack>
        }
      </Box>
  );
}

function TimingItem({timing}) {
  const {t} = useTranslate();
  return (
      <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          spacing={2}
          width={'100'}
          maxWidth={'232px'}
      >
        <Typography
            fontWeight={400}
            variant={'body2'}
            color={'text.primary'}
        >
          {getDayTranslation(timing.day, t)}
        </Typography>
        <Typography variant={'body2'} ml={'auto'}>
          {`${dayjs(timing.startTime, 'HH:mm').format('LT')} - ${dayjs(
              timing.endTime, 'HH:mm').format('LT')}`}
        </Typography>
      </Stack>
  );
}

export default ClubInfoNewTiming;
