import {createTheme} from '@mui/material';
import ChipOverride from './overrides/components/chip';
import {shadows} from './overrides/shadows';
import {customShadows} from './overrides/custom-shadows';
import {PALETTE} from './palette';
import ToggleButtonOverride from './overrides/components/toggle-button';
import SwitchOverride from './overrides/components/switch';
import SelectOverride from './overrides/components/select';
import CheckboxOverride from './overrides/components/checkbox';
import PopoverOverride from './overrides/components/popover';
import {typography} from './typography';
import DialogOverride from './overrides/components/dialog';
import TableOverride from './overrides/components/table';
import DataGridOverride from './overrides/components/datagrid';
import TabOverride from './overrides/components/tab';
import TypographyOverride from './overrides/components/typography';
import ListOverride from './overrides/components/list';
import ModalOverride from './overrides/components/modal';
import ButtonOverride from './overrides/components/button';
import SkeletonOverride from './overrides/components/skeleton';

let theme = createTheme({
  shadows: shadows('light'),
  customShadows: customShadows('light'),
  palette: PALETTE,
});

/*Typography*/
theme = createTheme({
  ...theme,
  typography: typography(theme),
});

/*Component Overrides*/
theme = createTheme({
  ...theme,
  components: {
    ...TypographyOverride(theme),
    ...ChipOverride(theme),
    // ...ToggleButtonOverride(theme),
    ...SwitchOverride(theme),
    ...SelectOverride(theme),
    ...CheckboxOverride(theme),
    ...PopoverOverride(theme),
    ...DialogOverride(theme),
    ...DataGridOverride(theme),
    ...TableOverride(theme),
    ...TabOverride(theme),
    ...ListOverride(theme),
    ...ModalOverride(theme),
    ...ButtonOverride(theme),
    ...SkeletonOverride(theme),
    // ...MenuOverride(theme),
    // ...RadioOverride(theme),
    // ...AlertOverride(theme),

  },

});

export default theme;
