import {Chip, Tooltip} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import useChipStyles from '../../../hooks/bookings/useChipStyles';
import useLocale from '../../../hooks/localization/useLocale';
import {getLocalizedNum} from '../../../utils/localization';
import useDefaults from '../../../hooks/defaults/useDefaults';


function ChipPlayerPaymentStatus({
  paymentStatus,
  playerPrice,
  size = 'small' || 'medium',
  chipStyle = 'filled' || 'outlined',
  variant = 'player-status' || 'player-price',
}) {

  const {t} = useTranslate();
  const {locale} = useLocale();
  const {currencySymbol} = useDefaults()

  const [neutralStyles, successStyles, errorStyles] = useChipStyles(chipStyle);

  let styles;
  let labelOutput = '';
  let tooltipMsg = '';

  if (paymentStatus === 'paid') {
    styles = successStyles;
  }

  if (paymentStatus === 'unpaid') {
    styles = errorStyles;
  }

  if (paymentStatus === 'refunded' || paymentStatus === 'refund-initiated') {
    styles = neutralStyles;
  }

  if (variant === 'player-status') {
    if (paymentStatus === 'paid') {
      labelOutput = t('global.chips.label.paid');
    }
    if (paymentStatus === 'unpaid') {
      labelOutput = t('global.types.status.unpaid');
    }
    if (paymentStatus === 'refunded') {
      labelOutput = t('global.chips.label.refunded');
    }
    if (paymentStatus === 'refund-initiated') {
      labelOutput = t('global.chips.label.refundInitiated');
    }
  }

  if (variant === 'player-price') {
    if (paymentStatus === 'paid') {
      labelOutput = `${currencySymbol}${getLocalizedNum(locale,
          +playerPrice)}`;
      tooltipMsg = t('global.chips.label.paid');
    }
    if (paymentStatus === 'unpaid') {
      labelOutput = `${currencySymbol}${getLocalizedNum(locale,
          +playerPrice)}`;
      tooltipMsg = t('global.types.status.unpaid');
    }
    if (paymentStatus === 'refunded') {
      labelOutput = t('global.chips.label.refunded');
    }
    if (paymentStatus === 'refund-initiated') {
      labelOutput = t('global.chips.label.refundInitiated');
    }

  }

  return (
      <Tooltip title={!!tooltipMsg ? tooltipMsg : ''}
               placement={'top'}
               arrow>
        <Chip
            size={size}
            label={labelOutput}
            sx={{
              ...styles,
            }}
        />
      </Tooltip>
  );
}

export default ChipPlayerPaymentStatus;


