import {ProviderSlotSearchV2} from './ProviderSlotSearchV2';
import {Box, Stack} from '@mui/material';
import SlotSearchV2Header from './Header/SlotSearchV2Header';
import SlotSearchV2Slots from './Slots/SlotSearchV2Slots';
import PageCard from '../_Global/Page/PageCard';
import Scrollbar from '../_Global/ScrollBar/ScrollBar';
import useBreakpoint from '../../hooks/ui/useBreakpoint';

function SlotSearchV2({
                        defaultDate, // initialize date with a default value
                        defaultSport, // initialize sport with a default value
                        defaultSelectedDuration,
                        view, // "" || "openMatch",
                        experience, // "COMPANY" || "CLUB"
                      }) {

  const {isMdDown} = useBreakpoint();

  const isCompanyExperience = experience === 'COMPANY';

  return (
      <Box className={'item'}>
        <PageCard>
          <ProviderSlotSearchV2
              defaultDate={defaultDate}
              defaultSport={defaultSport}
              defaultSelectedDuration={defaultSelectedDuration}
              view={view}
              experience={experience}
          >
            <Stack spacing={2}>
              <SlotSearchV2Header/>
              <Box
                  sx={{
                    height: '449px',
                    ...((isMdDown || isCompanyExperience) && {
                      height: 'auto',
                      minHeight: '200px',
                    }),
                  }}
              >
                <Scrollbar autoHide={false}>
                  <SlotSearchV2Slots
                      experience={experience}
                  />
                </Scrollbar>
              </Box>

            </Stack>
          </ProviderSlotSearchV2>
        </PageCard>
      </Box>

  );
}

export default SlotSearchV2;
