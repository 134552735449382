import dayjs from 'dayjs';
import moment from 'moment-timezone';
import {toFixedNoRounding} from './math';
import { customersBookingsAvailableCourts_POST } from '../vendor/redux/actions/bookings';
import { customersClubsExtrasList_GET } from '../vendor/redux/actions/clubs-extras';

export function getExtrasBillingEntries(billing) {
  if (billing) {
    if (billing?.extras?.length > 0) {
      return billing.extras.map((extra) => {
        return {
          label: `${extra.name} x ${extra.quantity}`,
          amount: toFixedNoRounding(extra.totalPrice, 2),
        };
      });
    } else {
      return [];
    }
  } else {
    return [];
  }
}

export function getCourtBookingLength(startTime, endTime) {
  //todo  update to use dayjs
  if (startTime && endTime) {
    const startTimeHM = startTime.split(':');
    const endTimeHM = endTime.split(':');
    const start = new Date();
    start.setHours(startTimeHM[0]);
    start.setMinutes(startTimeHM[1]);
    const end = new Date();
    end.setHours(endTimeHM[0]);
    end.setMinutes(endTimeHM[1]);
    return Math.abs(dayjs(start).diff(dayjs(end), 'minute')) / 60;
  } else {
    return 0;
  }
}

export function getBookingTimeHourSuffix(bookingTimeHours) {
  if (typeof bookingTimeHours === 'number') {
    if (bookingTimeHours <= 1) {
      return '';
    } else {
      return 's';
    }
  }
}

export function getHasBookingPast(
    bookingDate, bookingStartTime, bookingTimeZone) {
  if (bookingDate && bookingStartTime && bookingTimeZone){
    // Combine the bookingDate and startTime into a single string
    const bookingDateTimeStr = `${bookingDate} ${bookingStartTime}`;
    // Create a Moment.js object for the booking date and time in the specified timezone
    const bookingTimeTZ = moment.tz(bookingDateTimeStr, `${bookingTimeZone}`).add(24, 'hours');
    // Get the current time in the specified timezone
    const currentTimeTZ = moment().tz(bookingTimeZone);
    // Compare the booking time with the current time to check if it has passed
    const hasPast = bookingTimeTZ.isBefore(currentTimeTZ);
    return hasPast;
  }

}

export function getIsBookingCancellable(cancellationWindowHours, bookingDate, startTime, tz) {
  // Parse bookingDate and startTime into Moment.js objects in the provided timezone (tz)
  const bookingTimeTZ = moment.tz(`${bookingDate} ${startTime}`, `${tz}`);

  // Calculate the booking time with the timezone offset
  const bookingTimeWithOffset = bookingTimeTZ.clone().tz(tz);

  // Calculate the current time in the provided timezone
  const currentTime = moment.tz(tz);

  // Calculate the time difference in hours between current time and booking time
  const timeDifferenceHours = bookingTimeWithOffset.diff(currentTime, 'hours');

  console.log("time diff hours", timeDifferenceHours);

  // Check if the current time is within the cancellation window
  if (timeDifferenceHours >= cancellationWindowHours) {
    return true; // Booking can be cancelled
  } else {
    return false; // Booking cannot be cancelled
  }
}

export function getBooker(participants) {
  return participants.find((el) => {
    return el.owner;
  });
}

export function getIsBookingComplete(
    bookingDate, bookingStartTime, bookingTimezone, bookingStatus) {
  const isPast = getHasBookingPast(bookingDate, bookingStartTime,
      bookingTimezone);
  if (isPast && bookingStatus === 'paid') {
    return true;
  }
}

export function getIsBookingClosed(
    bookingDate, bookingStartTime, bookingTimezone, bookingStatus) {
  const isPast = getHasBookingPast(bookingDate, bookingStartTime,
      bookingTimezone);
  if (isPast && bookingStatus === 'unpaid') {
    return true;
  }
}

export function getExtrasArrayInApiFormat(stateExtras) {
  const extras = [];
  stateExtras.forEach((stateExtra) => {
    const extra = {};
    extra.extrasId = stateExtra.id;
    extra.quantity = stateExtra.count;
    extras.push(extra);
  });
  return extras;
}

export function getUsersArrayInApiFormat(players, clubID) {
  const users = [];
  players.forEach((statePlayer) => {
    let player = {};
    player.clubId = clubID;
    player.userId = statePlayer?.id;
    player.firstName = statePlayer?.firstName;
    player.lastName = statePlayer?.lastName;
    player.email = statePlayer?.email;
    player.owner = statePlayer.isOrganizer;
    player.type = 'player';
    users.push(player);
  });

  return users;
}

export function getIsBooker(user, participants) {
  if (user && participants) {
    const currentParticipant = getCurrentParticipant(user?.userObj.id,
        participants);
    return !!currentParticipant?.owner;
  }
}

export function getHasParticipantPaid(user, participants) {
  if (user && participants) {
    const currentParticipant = getCurrentParticipant(user?.userObj.id,
        participants);
    if (currentParticipant) {
      return currentParticipant.paymentStatus === 'paid';
    }
    return false;
  }
}

export function getCurrentParticipant(userId, participants) {
  return participants.find((participant) => {
    return participant?.user?.userId === userId;
  });
}

export function getSplitPlayerCost(priceTotal, playerCount) {
  return priceTotal / playerCount;
}

export const setFormDefaultStates=(setValue,formData)=>{

  const keys = Object.keys(formData);

  keys.forEach(key => {
   setValue(key,formData[key]);
  });

}

export const courtAvailableCheck=({startDate,startTime,endTime,clubId,setIsCourtNotAvailable,selectedCourt})=>{
  
    const body = {
      date: startDate,
      startTime: startTime,
      endTime: endTime,
      clubId: parseInt(clubId),
    };
   
    return dispatch=>{
      dispatch(customersBookingsAvailableCourts_POST(body, cbSuccess, cbFail))
    }

    function cbSuccess({data: {data}}) {
      console.log('bookingsAvailableCourts_POST Success', data);
      if (data.length === 0) {
        console.log('No available courts, block add booking');
        setIsCourtNotAvailable(true);
      }
      if (data.length > 0) {
        
        const courtExists = data.find((court) => {
          return +court.id === +selectedCourt.courtId;
        });
        if (!courtExists) {
          console.log(
              'selected court does not exist in available courts, block add booking');
          setIsCourtNotAvailable(true);
        }
      }
    }

    function cbFail(e) {
      console.log('bookingsAvailableCourts_POST fail', e);
      // setIsDisabled(true);
    }

}

export const fetchExtras=({clubId,courtSport,setExtras,setIsLoadingExtras})=>{

  return dispatch=>{
    setIsLoadingExtras(true);
    dispatch(customersClubsExtrasList_GET(clubId, courtSport, cbSuccess, cbFail))
  }

  function cbSuccess({data: {data}}) {
    console.log('customersClubsExtrasList_GET Success', data);
    setExtras(data);
    setIsLoadingExtras(false);
  }

  function cbFail(e) {
    console.log('customersClubsExtrasList_GET fail', e);
  }

}