import {customersWalletSelf_GET} from '../../vendor/redux/actions/wallet';
import {useEffect, useState} from 'react';
import useIsAuthenticated from '../access/useIsAuthenticated';
import {useDispatch} from 'react-redux';

function useWalletSelf(clubId) {

  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  const [walletSelf, setWalletSelf] = useState('loading');

  function fetchWalletSelf(clubId) {

    dispatch(customersWalletSelf_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersWalletSelf_GET Success', res);
      setWalletSelf(res.data.data);
    }

    function cbFail(e) {
      console.log('customersWalletSelf_GET Fail', e);
    }
  }

  useEffect(() => {
    if (isAuthenticated && clubId) {
      fetchWalletSelf(clubId);
    } else {
      setWalletSelf(null);
    }
  }, [isAuthenticated,clubId]);

  return {
    walletSelf,
    fetchWalletSelf
  };
}

export default useWalletSelf;