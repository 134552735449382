import {styled, TextField} from '@mui/material';

const TextFieldCustom = styled(TextField)(({theme}) => {
  return {
    ".MuiInputBase-root.MuiAutocomplete-inputRoot":{
      borderRadius: "4px",
      paddingRight:"8px",
      paddingTop:"0",
        "&:before":{
          // display:"none"
        },
      ".MuiInputBase-input":{
          padding:"6px 0"
      },
      ".MuiAutocomplete-endAdornment":{
        display: "none"
      }
    }
  };
});

export default function AddBookingStyledTextField(props) {
  return (
      <TextFieldCustom {...props} variant={"standard"} size={"small"} fullWidth/>
  );
}
