import {Alert, AlertTitle} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import theme from '../../vendor/mui/theme';
import {
  getHasParticipantPaid,
  getIsBooker,
  getIsBookingClosed,
  getIsBookingComplete,
} from '../../utils/bookings';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import useUser from '../../hooks/access/useUser';

function AlertsBookingStatus({
                               bookingStatus,
                               paymentType,
                               bookingTime,
                               bookingDate,
                               bookingTimezone,
                               participants,
                               origin,
                               participantPaidStatus,
                             }) {

  const {t} = useTranslate();

  const IS_AUTHENTICATED = useIsAuthenticated();
  const user = useUser();

  const IS_PAID = bookingStatus === 'paid';
  const IS_UNPAID = bookingStatus === 'unpaid';
  const IS_CANCELLED = bookingStatus === 'cancelled';
  const IS_COMPLETE = getIsBookingComplete(bookingDate, bookingTime,
      bookingTimezone,
      bookingStatus,
  );
  const IS_CLOSED = getIsBookingClosed(bookingDate, bookingTime,
      bookingTimezone,
      bookingStatus);

  const IS_SINGLE_PAYMENT = paymentType === 'single';
  const IS_SPLIT_PAYMENT = paymentType === 'split';

  let severity;
  let title;
  let description;
  let alertStyles = {};

  /* Booking Confirmed */
  if (IS_PAID && !IS_COMPLETE) {
    severity = 'success';
    title = t('global.alerts.player.bookingStatus.confirmed.title');
    description = t('global.alerts.player.bookingStatus.confirmed.description');
  }

  /* Booking Complete */
  if (IS_COMPLETE) {
    severity = 'info';
    title = t('global.alerts.player.bookingStatus.complete.title');
    description = t('global.alerts.player.bookingStatus.complete.description');
  }

  /* Booking Closed */
  if (IS_CLOSED) {
    severity = 'error';
    title = t('global.alerts.player.bookingStatus.closed.title');
    description = t('global.alerts.player.bookingStatus.closed.description');
    alertStyles = {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[800],
      svg: {
        fill: theme.palette.grey[700],
      },
    };
  }

  /*Booking Cancelled */
  if (IS_CANCELLED) {
    severity = 'error';
    title = t('global.alerts.player.bookingStatus.cancelled.title');
    description = t('global.alerts.player.bookingStatus.cancelled.description');
    alertStyles = {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[800],
      svg: {fill: theme.palette.grey[700]},
    };
  }

  /* Booking Pending */
  if (IS_UNPAID && !IS_CLOSED) {

    if (!IS_AUTHENTICATED) {

      if (IS_SINGLE_PAYMENT) {
        severity = 'warning';
        title = t(
            'global.alerts.player.bookingStatus.pending.unauthenticated.single.title');
        description = t(
            'global.alerts.player.bookingStatus.pending.unauthenticated.single.description');
      }

      if (IS_SPLIT_PAYMENT) {
        severity = 'warning';
        title = t(
            'global.alerts.player.bookingStatus.pending.unauthenticated.split.title');
        description = t(
            'global.alerts.player.bookingStatus.pending.unauthenticated.split.description');
      }

    }

    if (IS_AUTHENTICATED) {

      if (IS_SINGLE_PAYMENT) {

        if (getIsBooker(user, participants)) {
          severity = 'warning';
          title = t(
              'global.alerts.player.bookingStatus.pending.singlePayment.bookerViewing.title');
          description = t(
              'global.alerts.player.bookingStatus.pending.singlePayment.bookerViewing.description');
        }

        if (!getIsBooker(user, participants)) {
          severity = 'warning';
          title = t(
              'global.alerts.player.bookingStatus.pending.singlePayment.participantViewing.title');
          description = t(
              'global.alerts.player.bookingStatus.pending.singlePayment.participantViewing.description');
        }
      }

      if (IS_SPLIT_PAYMENT) {
        if (getHasParticipantPaid(user, participants)) {
          severity = 'warning';
          title = t(
              'global.alerts.player.bookingStatus.pending.splitPayment.paidParticipantViewing.title');
          description = t(
              'global.alerts.player.bookingStatus.pending.splitPayment.paidParticipant.description');
        }

        if (!getHasParticipantPaid(user, participants)) {
          severity = 'warning';
          title = t(
              'global.alerts.player.bookingStatus.pending.splitPayment.unpaidParticipantViewing.title');
          description = t(
              'global.alerts.player.bookingStatus.pending.splitPayment.unpaidParticipant.description');
        }
      }
    }
  }

  if (origin === 'paymentGateway' || origin === 'walletPayment') {

    alertStyles = {};

    if (participantPaidStatus === 'paid') {
      severity = 'success';
      if (origin === 'paymentGateway'){
        title = t('global.alerts.player.paymentStatus.success');
      }
      if (origin === 'walletPayment'){
        title = t('global.alerts.player.paymentStatus.wallet.success.title');
      }
    }

    if (participantPaidStatus === 'unpaid') {
      severity = 'error';
      if (origin === 'paymentGateway'){
        title = t('global.alerts.player.paymentStatus.failed');
      }
      if (origin === 'walletPayment'){
        title = t('global.alerts.player.paymentStatus.wallet.failed');
      }
    }
  }

  return (
      <Alert severity={severity} sx={alertStyles}>
        <AlertTitle>
          {title}
        </AlertTitle>
        {description}
      </Alert>
  );

}

export default AlertsBookingStatus;
