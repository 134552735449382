import {
  alpha,
  Box,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {useSlotSearchV2Context} from '../ProviderSlotSearchV2';
import useLocale from '../../../hooks/localization/useLocale';
import {bookingEvents} from '../../../utils/analyticsEvents';
import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {useTranslate} from '@tolgee/react';
import Empty from '../../_Global/Empty/Empty';
import SlotSearchV2CourtSelectDialog
  from './CourtSelectDialog/SlotSearchV2CourtSelectDialog';
import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import DialogBookingsAdd from '../../../dialogs/Booking/DialogBookingsAdd';
import DialogBookingsAddV2 from '../../../dialogs/Booking/DialogBookingsAddV2';
import ProfileImageViewer from '../../_Global/Profile/ProfileImageViewer';
import DialogCompanyBookingsAdd from '../../../dialogs/Company/DialogCompanyBookingsAdd';
import useIsMobile from '../../../hooks/ui/useIsMobile';

function SlotSearchV2Slots({experience}) {

  const isCompanyView = experience=='COMPANY';
  const {clubId,companyId} = useParams();
  const theme = useTheme();

  const {
    slots,
    isLoadingSlots,
    setWillNavigateToCreateBooking,
    setSelectedCourt,
    setSelectedSlot,
    setIsOpenCourtSelect,
    willNavigateToCreateBooking,
    view
  } = useSlotSearchV2Context();

  const showOldBookingView = view=='openMatch' || isCompanyView;
  
  const BookingAddComponent = (showOldBookingView? DialogBookingsAdd: DialogBookingsAddV2);
  const addBookingRoute = isCompanyView? `/company/${companyId}` : `/club/${clubId}`

  function handleAfterExitAddBookingDialog() {
    setWillNavigateToCreateBooking(false);
    setSelectedCourt({});
    setSelectedSlot({});
  }

  useEffect(() => {
    if (willNavigateToCreateBooking) {
      setIsOpenCourtSelect(false);
    }
  }, [willNavigateToCreateBooking]);

  if (isLoadingSlots && !willNavigateToCreateBooking) {
    return <LoadingState/>;
  }

  if (!isLoadingSlots && slots?.length === 0) {
    return <EmptyState/>;
  }

  const ClubBox=({clubId,clubName,imageUrl,children})=>{

    return(
      <Box
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "12px",
        mb: 3,
      }}
      >
        <Box
        sx={{
          padding: 2,
          backgroundColor: theme.palette.grey[200],
          borderTopRightRadius: "12px",
          borderTopLeftRadius: "12px",
        }}
      >
        <Stack
          onClick={() =>
            window.open(
              `/club/${clubId}`,
              "_blank",
              "noopener,noreferrer"
            )
          }
          sx={{ cursor: "pointer" }}
          direction={"row"}
          alignItems={"center"}
          spacing={1}
        >
          <ProfileImageViewer
            size={"sm"}
            imgPath={imageUrl}
            placeholderStyle={"shield-icon"}
          />
          <Typography variant="h6-bold">{clubName}</Typography>
        </Stack>
        </Box>
        {children}
      </Box>
    )
  }

  return (
      <>
        <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              pt: 1,
            }}
        >
          {
            isCompanyView?
            <Stack
            width={'100%'}
            >
              {
                slots?.map(clubData=>{
                  return(
                    <ClubBox
                    clubId={clubData?.clubId}
                    clubName={clubData?.clubName}
                    imageUrl={clubData?.imageUrl}
                    >
                      <Stack
                      direction={'row'}
                      flexWrap={'wrap'}
                      >
                        {
                          clubData?.slots?.map((slot,index)=>(
                            <SlotsItem
                            key={index}
                            slot={slot}
                            showOldBookingView={showOldBookingView}
                            />
                          ))
                        }
                      </Stack>
                    </ClubBox>
                  )
                })
              }
            </Stack>
            :
            slots?.map((slot, index) => {
              return <SlotsItem
                  key={index}
                  slot={slot}
                  isCompanyView={isCompanyView}
                  showOldBookingView={showOldBookingView}
              />;
            })
          }
        </Box>
        {showOldBookingView && <SlotSearchV2CourtSelectDialog/>}
        <Routes>
          <Route
              path={'add-booking'}
              element={<BookingAddComponent
                  onExit={handleAfterExitAddBookingDialog}
                  open={true}
                  variant={'add'}
                  root={addBookingRoute}
                  fetchAndSetBookings={() => {
                  }}
              />}
          />

        </Routes>
      </>
  );
}

export default SlotSearchV2Slots;

function SlotsItem({slot,showOldBookingView}) {

  const navigateTo = useNavigate();
  const theme = useTheme();
  const {locale} = useLocale();
  const {
    selectedSlot,
    setSelectedSlot,
    setIsOpenCourtSelect,
    isTablet,
  } = useSlotSearchV2Context();
  const isActive = selectedSlot?.slot === slot?.slot;

  const activeStyles = {
    backgroundColor: alpha(theme.palette.primary.main, .1),
    color: theme.palette.primary.dark,
  };

  return (

      <Box
          sx={{
            fontSize: '14px',
            fontWeight:600,
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
            padding: '9px 20px',
            width: !isTablet ? 'calc(25% - 16px)' : 'calc(33.33% - 16px)',
            margin: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '6px',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            transition: 'background-color .1s ease-in-out',
            ...(isActive) && activeStyles,
            '&:hover': activeStyles,
          }}
          onClick={() => {
            setSelectedSlot({
              ...slot,
              slots: slot?.slots?.map(x => ({...x, allowCourtUpdate: true})),
            });
            if(showOldBookingView){
              setIsOpenCourtSelect(true);
            }else{
              navigateTo('add-booking');
            }
            
            bookingEvents['clickOnTimeslot']();
          }}
      >
        {dayjs(slot.slot, 'HH:mm').locale(locale).format('LT')}
      </Box>

  );
}

function LoadingState() {

  const {isTablet} = useSlotSearchV2Context();

  return (
      <Box
          sx={{
            position: 'relative',
            // padding: '0 23px',
          }}
      >
        <LinearProgress
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
            }}
        />
        <Grid container spacing={2} mt={1} pb={4}>
          {
            Array(12).fill(0).map((el, index) => {
              return (
                  <Grid key={index} item xs={!isTablet ? 3 : 4}>
                    <Skeleton
                        width={'100%'}
                        height={'39px'}
                        sx={{
                          transform: 'scale(1,1)',
                        }}
                    />
                  </Grid>
              );
            })
          }
        </Grid>
      </Box>
  );
}

function EmptyState() {

  const {t} = useTranslate();

  return (
      <Box
          sx={{
            width: '100%',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 8,
          }}
      >
        <Empty
            imageStyle={'simple'}
            description={
              <Stack textAlign={'center'} maxWidth={'400px'}>
                <Typography variant={'body1'}>
                  {t('schedule.mobile.availableSlots.noSlots.title')}
                </Typography>
                <Typography variant={'body2'}>
                  {t('schedule.mobile.availableSlots.noSlots.description')}
                </Typography>
              </Stack>
            }
        />
      </Box>
  );
}
