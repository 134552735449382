import React, {useEffect, useState} from 'react';
import {clubTrainingss_GET} from '../../vendor/redux/actions/customers';
import {useDispatch} from 'react-redux';
import {Box, Button, Stack, Typography, useTheme} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {useNavigate, useParams} from 'react-router-dom';
import useUser from '../../hooks/access/useUser';
import PageCard from '../_Global/Page/PageCard';
import TrainingListingCard from '../Training/TrainingListingCard';
import PageCardTitle from '../_Global/Page/PageCardTitle';
import Scrollbar from '../_Global/ScrollBar/ScrollBar';
import useBreakPoint from '../../hooks/ui/useBreakpoint';
import IconNoTrainings from '../../assets/icons/IconNoTrainings';
import useIsMobile from '../../hooks/ui/useIsMobile';

function ClubInfoNewTrainings() {

  const dispatch = useDispatch();
  const {t} = useTranslate();
  const navigateTo = useNavigate();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const {isMdDown} = useBreakPoint();

  const user = useUser();
  const {clubId} = useParams();

  const [trainings, setTrainings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function fetchTrainings() {

    setIsLoading(true);
    dispatch(
        clubTrainingss_GET(
            clubId,
            '',
            '',
            '',
            '',
            2,
            cbSuccess,
            cbFail,
        ),
    );

    function cbSuccess(res) {
      setTrainings(res?.data?.data?.rows);
      setIsLoading(false);
    }

    function cbFail(e) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchTrainings();
  }, [user]);

  return (
      <Box className={'item'}>
        <PageCard>
          <PageCardTitle
              title={t('trainings.list.title')}
              route={`/club/${clubId}/trainings`}
              titleMeta={
                !isLoading && trainings?.length !== 0 && (
                <Button
                    onClick={() => {
                      navigateTo(`/club/${clubId}/trainings`);
                    }}
                    variant={'text'}
                >
                  {t('global.buttons.viewAll')}
                </Button>
                )
              }
          />

          <Box sx={{
            height: '480px',
            ...(isMdDown && {
              height: 'auto',
              minHeight:'200px'
            })
          }}>
            <Scrollbar autoHide={false}>
              {
                  !isLoading && trainings?.length === 0 &&
                  <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{mt:isMobile ?'20%' :'30%' , textAlign:'center'}} rowGap={1}>
                  <IconNoTrainings color={'disabled'} fontSize='medium'/>  
                  <Typography  variant="subtitle2" color={theme.palette.grey[600]} >
                  {t('trainings.list.empty')}
                  </Typography>
                  </Stack> 
              }
              {
                  !isLoading && trainings?.length > 0 &&
                  <Stack spacing={2}>
                    {
                      trainings.map((training, index) => {
                        return (
                            <TrainingListingCard
                                key={index}
                                training={training}
                                isBackground={true}
                            />
                        );

                      })
                    }
                  </Stack>
              }
            </Scrollbar>
          </Box>

        </PageCard>
      </Box>
  );

}

export default ClubInfoNewTrainings;
