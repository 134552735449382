import {Box, Stack, useTheme} from '@mui/material';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import { Suspense } from 'react';
import LayoutCompanyHeader from './LayoutCompanyHeader';
import LayoutCompanyPage from './LayoutCompanyPage';
import LayoutClubHeaderAlt from '../LayoutClub/LayoutClubHeaderAlt/LayoutClubHeaderAlt';
import useIsMobile from '../../hooks/ui/useIsMobile';
import SiteOnboardingButtons from '../../components/_Global/Site/SiteOnboardingButtons';
import LayoutMainMobileHeader from '../LayoutMain/LayoutMainMobileHeader';
import useNavItems from '../LayoutClub/Nav/useNavItems';
import LayoutCLubHeaderNew from '../LayoutClub/LayoutCLubHeaderNew/LayoutCLubHeaderNew';

function LayoutCompany() {

  const pageRef = useRef();
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const navItems = useNavItems('COMPANY');
  const {companyId} = useParams();

  useEffect(() => {

    if (pageRef.current){
       pageRef.current.scrollTo({
         top: 0,
         behavior: "instant",
       });
    }

  }, [pathname]);
  return (
      <Box
      ref={pageRef}
      sx={{
        width: '100vw',
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.neutral,
        position: 'relative',
      }}
      >
       
       {/* prev comany view mobile header */}
        {/* {
          isMobile &&
          <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          spacing={1}
          padding={'0.5rem 1.2rem'}
          sx={{
            backgroundColor:theme.palette.primary.main,
            position:'relative',
            zIndex:1
          }}
          >
            <SiteOnboardingButtons showDivider={true} size={'medium'}/>
            <LayoutMainMobileHeader
                navItems={navItems}
            />
          </Stack>
        } */}
        <LayoutCLubHeaderNew
        navItems={navItems}
        defaultPath={`/company/${companyId}`}
        experience={'COMPANY'}
        />
        <Box component={'main'}>
          <Suspense fallback={<h1></h1>}>
          <LayoutCompanyPage>
            <Outlet/>
          </LayoutCompanyPage>
          </Suspense>
        </Box>
      </Box>
  );
}

export default LayoutCompany;
