function IconSportPadel(props) {
  return (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
           xmlns="http://www.w3.org/2000/svg" {...props}>

          <path
              d="M23.9584 8.28671C23.8005 6.92139 23.1783 5.63418 22.2064 4.66231L19.8363 2.29213C18.8644 1.32026 17.5772 0.698005 16.2119 0.540074C14.8465 0.38219 13.4513 0.694067 12.2832 1.41842L12.2702 1.42644C8.42196 3.81275 6.38353 8.24602 7.07709 12.7207C7.26488 13.9326 6.98427 15.1555 6.28687 16.1642C6.12491 16.3984 6.1835 16.7196 6.41775 16.8816C6.652 17.0436 6.97321 16.985 7.13517 16.7507C7.98337 15.524 8.32469 14.0367 8.09625 12.5627C7.46622 8.49794 9.31798 4.47072 12.8138 2.30296L12.8267 2.29494C13.798 1.69266 14.9582 1.43328 16.0934 1.56463C17.2287 1.69594 18.299 2.21333 19.1071 3.02145L21.4773 5.39163C22.2854 6.19976 22.8027 7.27002 22.9341 8.40531C23.0654 9.54059 22.8061 10.7007 22.2038 11.672L22.1957 11.685C20.0279 15.1807 16.0005 17.0323 11.9358 16.4024C10.474 16.1758 8.99631 16.5105 7.77491 17.3448C7.53972 17.5055 7.47934 17.8263 7.63999 18.0615C7.73984 18.2077 7.90162 18.2863 8.06625 18.2863C8.16643 18.2863 8.26769 18.2572 8.35666 18.1964C9.36092 17.5104 10.5759 17.2352 11.7779 17.4215C12.3588 17.5115 12.9383 17.5555 13.5134 17.5555C17.3684 17.5552 20.9956 15.577 23.0721 12.2283L23.0801 12.2153C23.8045 11.0473 24.1164 9.65207 23.9584 8.28671Z"
              fill="currentColor"/>
          <path
              d="M9.44008 15.0566C9.56454 15.1815 9.74591 15.2334 9.91584 15.1955C10.6495 15.0336 11.3954 15.0094 12.1328 15.1237C12.8762 15.2389 14.2642 15.1929 14.3229 15.1909C14.5281 15.1839 14.7097 15.0557 14.7849 14.8647C14.8602 14.6736 14.815 14.4561 14.6699 14.3109L10.1858 9.82681C10.0405 9.68158 9.82298 9.63644 9.632 9.71173C9.44097 9.78701 9.31281 9.96852 9.30578 10.1738C9.30376 10.2325 9.25773 11.6205 9.373 12.3639C9.48729 13.1014 9.4631 13.8473 9.30118 14.5809C9.26326 14.7528 9.31562 14.9322 9.44008 15.0566ZM13.0633 14.1628C12.7792 14.1542 12.4993 14.1369 12.2908 14.1045C11.9335 14.0492 11.5747 14.0215 11.2159 14.0215C10.954 14.0215 10.6921 14.0362 10.431 14.0657C10.5008 13.4468 10.4879 12.824 10.3922 12.2059C10.3599 11.9975 10.3425 11.7175 10.3339 11.4334L13.0633 14.1628Z"
              fill="currentColor"/>
          <path
              d="M7.0261 16.1669C6.64475 15.7855 6.02418 15.7855 5.64278 16.1669L2.00883 19.8008C1.47611 19.6592 0.883996 19.7965 0.466972 20.2135C-0.155657 20.8361 -0.155657 21.8492 0.466972 22.4718L2.02992 24.0348C2.34124 24.3461 2.75015 24.5017 3.15906 24.5017C3.56802 24.5017 3.97694 24.3461 4.28825 24.0348C4.70532 23.6177 4.84258 23.0255 4.70087 22.4927L8.33473 18.8588C8.71609 18.4775 8.71609 17.8569 8.33473 17.4755L7.0261 16.1669ZM3.55898 23.3056C3.33846 23.5261 2.97966 23.5261 2.75915 23.3056L1.1962 21.7426C0.975689 21.5221 0.975689 21.1633 1.1962 20.9427C1.30646 20.8325 1.45126 20.7774 1.59611 20.7774C1.74092 20.7774 1.88577 20.8325 1.99603 20.9427L3.55898 22.5057C3.77954 22.7262 3.77954 23.0851 3.55898 23.3056ZM4.12348 21.6116L2.89003 20.3782L6.33446 16.9338L7.56791 18.1672L4.12348 21.6116Z"
              fill="currentColor"/>
          <path
              d="M15.2422 4.961C15.527 4.961 15.7579 4.73013 15.7579 4.44534C15.7579 4.16055 15.527 3.92969 15.2422 3.92969C14.9574 3.92969 14.7266 4.16055 14.7266 4.44534C14.7266 4.73013 14.9574 4.961 15.2422 4.961Z"
              fill="currentColor"/>
          <path
              d="M12.8321 5.76373C13.1168 5.76373 13.3477 5.53286 13.3477 5.24808C13.3477 4.96329 13.1168 4.73242 12.8321 4.73242C12.5473 4.73242 12.3164 4.96329 12.3164 5.24808C12.3164 5.53286 12.5473 5.76373 12.8321 5.76373Z"
              fill="currentColor"/>
          <path
              d="M12.0313 8.16998C12.3161 8.16998 12.5469 7.93911 12.5469 7.65433C12.5469 7.36954 12.3161 7.13867 12.0313 7.13867C11.7465 7.13867 11.5156 7.36954 11.5156 7.65433C11.5156 7.93911 11.7465 8.16998 12.0313 8.16998Z"
              fill="currentColor"/>
          <path
              d="M16.8477 6.56647C17.1325 6.56647 17.3633 6.3356 17.3633 6.05081C17.3633 5.76602 17.1325 5.53516 16.8477 5.53516C16.5629 5.53516 16.332 5.76602 16.332 6.05081C16.332 6.3356 16.5629 6.56647 16.8477 6.56647Z"
              fill="currentColor"/>
          <path
              d="M14.4375 7.3692C14.7223 7.3692 14.9532 7.13833 14.9532 6.85355C14.9532 6.56876 14.7223 6.33789 14.4375 6.33789C14.1527 6.33789 13.9219 6.56876 13.9219 6.85355C13.9219 7.13833 14.1527 7.3692 14.4375 7.3692Z"
              fill="currentColor"/>
          <path
              d="M13.6367 9.77545C13.9215 9.77545 14.1524 9.54458 14.1524 9.2598C14.1524 8.97501 13.9215 8.74414 13.6367 8.74414C13.352 8.74414 13.1211 8.97501 13.1211 9.2598C13.1211 9.54458 13.352 9.77545 13.6367 9.77545Z"
              fill="currentColor"/>
          <path
              d="M18.4492 8.16998C18.734 8.16998 18.9649 7.93911 18.9649 7.65433C18.9649 7.36954 18.734 7.13867 18.4492 7.13867C18.1645 7.13867 17.9336 7.36954 17.9336 7.65433C17.9336 7.93911 18.1645 8.16998 18.4492 8.16998Z"
              fill="currentColor"/>
          <path
              d="M16.043 8.97272C16.3278 8.97272 16.5587 8.74185 16.5587 8.45706C16.5587 8.17227 16.3278 7.94141 16.043 7.94141C15.7582 7.94141 15.5273 8.17227 15.5273 8.45706C15.5273 8.74185 15.7582 8.97272 16.043 8.97272Z"
              fill="currentColor"/>
          <path
              d="M15.2422 11.3809C15.527 11.3809 15.7579 11.1501 15.7579 10.8653C15.7579 10.5805 15.527 10.3496 15.2422 10.3496C14.9574 10.3496 14.7266 10.5805 14.7266 10.8653C14.7266 11.1501 14.9574 11.3809 15.2422 11.3809Z"
              fill="currentColor"/>
          <path
              d="M20.0547 9.77545C20.3395 9.77545 20.5704 9.54458 20.5704 9.2598C20.5704 8.97501 20.3395 8.74414 20.0547 8.74414C19.7699 8.74414 19.5391 8.97501 19.5391 9.2598C19.5391 9.54458 19.7699 9.77545 20.0547 9.77545Z"
              fill="currentColor"/>
          <path
              d="M17.6485 10.5782C17.9333 10.5782 18.1641 10.3473 18.1641 10.0625C18.1641 9.77774 17.9333 9.54688 17.6485 9.54688C17.3637 9.54688 17.1328 9.77774 17.1328 10.0625C17.1328 10.3473 17.3637 10.5782 17.6485 10.5782Z"
              fill="currentColor"/>
          <path
              d="M16.8477 12.9864C17.1325 12.9864 17.3633 12.7555 17.3633 12.4707C17.3633 12.1859 17.1325 11.9551 16.8477 11.9551C16.5629 11.9551 16.332 12.1859 16.332 12.4707C16.332 12.7555 16.5629 12.9864 16.8477 12.9864Z"
              fill="currentColor"/>
          <path
              d="M19.2539 12.1837C19.5387 12.1837 19.7696 11.9528 19.7696 11.668C19.7696 11.3832 19.5387 11.1523 19.2539 11.1523C18.9691 11.1523 18.7383 11.3832 18.7383 11.668C18.7383 11.9528 18.9691 12.1837 19.2539 12.1837Z"
              fill="currentColor"/>

      </svg>

  );
}

export default IconSportPadel;
