import {Divider, Stack, Typography} from '@mui/material';
import SlotSearchV2HeaderSportSelectMobile from './SlotSearchV2HeaderSportSelectMobile';
import SlotSearchV2HeaderDurationSelect
  from './SlotSearchV2HeaderDurationSelect';
import SlotSearchV2HeaderDateSelect from './SlotSearchV2HeaderDateSelect';
import SlotSearchV2HeaderFilterSelect from './SlotSearchV2HeaderFilterSelect';
import {useSlotSearchV2Context} from '../ProviderSlotSearchV2';
import SlotSearchV2HeaderSportSelectDesktop
  from './SlotSearchV2HeaderSportSelectDesktop';
import { useTranslate } from '@tolgee/react';
import { APP_DEFAULT_TIMEZONE } from '../../../constants/appDefaults';
import moment from 'moment-timezone';

function SlotSearchV2Header() {
  const {t} = useTranslate();
  
  const {
    isTablet,
    sports,
    selectedSport,
    handleSelectSport,

    clubDetails, clubPolicies,
    selectedDate,
    handleSelectDate,
    view,
  } = useSlotSearchV2Context();

  const isOpenMatchView = view=='openMatch';
  const clubTimezone = clubDetails?.addresses[0].timezone ??
      APP_DEFAULT_TIMEZONE;
  const {bookingAheadLimit = 6, openMatchMinCreateTime = 1} = clubPolicies ??
  {};
  
  const maxDate = moment().add(+bookingAheadLimit, 'days');

  const minDate = isOpenMatchView ?
      moment.tz(moment().add(+openMatchMinCreateTime, 'hours'), clubTimezone) :
      null;
  
  if (!isTablet) {
    return (
        <Stack>
           <Stack
               direction="row"
               columnGap={1}
               rowGap={1}
               justifyContent="space-between"
               alignItems="center"
               mb={2}
               flexWrap="wrap"
           >
            <Typography variant="h5-bold" >
            {view ? '': t('home.text.createBokking')}
            </Typography>
            <SlotSearchV2HeaderSportSelectDesktop
            sports={sports}
            selectedSport={selectedSport}
            handleSelectSport={handleSelectSport}
            />
          </Stack>
          {/*<Divider sx={{*/}
          {/*  marginRight:"calc( -0rem - clamp(1.00rem, calc(-0.14rem + 5.71vw), 2.00rem))",*/}
          {/*  marginLeft:"calc( -0rem - clamp(1.00rem, calc(-0.14rem + 5.71vw), 2.00rem))",*/}
          {/*  mb:2,*/}
          {/*}}/>*/}
          <Stack
              direction={'row'}
              justifyContent="space-between"
              flexWrap={'wrap'}
              columnGap={1}
              rowGap={1}
          >
            <SlotSearchV2HeaderDurationSelect/>
            <Stack
                direction="row"
                columnGap={1}
                rowGap={1.5}
                alignItems={'center'}
            >
              
              <SlotSearchV2HeaderDateSelect
              minDate={minDate}
              maxDate={maxDate}
              selectedDate={selectedDate}
              handleSelectDate={handleSelectDate}
              isTablet={isTablet}
              clubTimezone={clubTimezone}
              />
              <SlotSearchV2HeaderFilterSelect/>
            </Stack>
          </Stack>
        </Stack>
    )
  }


  if (isTablet) {
    return (
        <Stack
            direction="row"
            columnGap={1}
            rowGap={1.5}
            alignItems={'center'}
            justifyContent="space-between"
            flexWrap={'wrap'}
        >
          <Typography variant="h5-bold" mb={1}>
          {t('home.text.createBokking')}
          </Typography>
          <SlotSearchV2HeaderSportSelectMobile
          isTablet={isTablet}
          sports={sports}
          selectedSport={selectedSport}
          handleSelectSport={handleSelectSport}
          />
          <SlotSearchV2HeaderDurationSelect/>
          <Stack
              direction="row"
              columnGap={1}
              rowGap={1.5}
              alignItems={'center'}
              width={'100%'}
          >
            <SlotSearchV2HeaderDateSelect/>
            <SlotSearchV2HeaderFilterSelect/>
          </Stack>
        </Stack>
    );
  }

}

export default SlotSearchV2Header;
