import React, {useEffect, useRef, useState} from 'react';
import {clubOffers_GET} from '../../vendor/redux/actions/customers';
import {Box, lighten, Stack, Typography, useTheme} from '@mui/material';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslate} from '@tolgee/react';
import useLocale from '../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import {getLocalizedNum} from '../../utils/localization';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import useOnboardingContext from '../../hooks/onboarding/useOnboardingContext';
import {SwiperButton} from '../_Global/Swiper/SwiperButton';
import {offersEvents} from '../../utils/analyticsEvents';
import ArtPackage from '../../assets/art/ArtPackage';
import PageCardTitle from '../_Global/Page/PageCardTitle';
import useDefaults from '../../hooks/defaults/useDefaults';
import { BookOnlineTwoTone, RedeemTwoTone } from '@mui/icons-material';
import useIsMobile from '../../hooks/ui/useIsMobile';

function ClubInfoNewOffers({clubId, clubDetails, type}) {

  const dispatch = useDispatch();
  const {t} = useTranslate();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [offers, setOffers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);

  const swiperRef = useRef();

  function fetchOffers() {

    setIsLoading(true);
    dispatch(clubOffers_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('clubOffers_GET Success', res);
      setOffers( res.data.data.rows);
      setIsLoading(false);
    }

    function cbFail(e) {
      console.log('clubOffers_GET Fail', e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchOffers();
  }, []);

  return (
      <Stack
          sx={{
            height: '100%',
            minHeight: '400px',
          }}
      >
        <PageCardTitle
            title={t('finances.offers.addEdit.breadcrumbs.offers')}
            titleMeta={
              <>
                {
                    offers?.length > 0 &&
                    <Stack direction={'row'} spacing={1}>
                      <SwiperButton
                          disabled={slideActiveIndex === 0}
                          direction={'prev'}
                          onClick={() => {
                            swiperRef.current.slidePrev();
                            setSlideActiveIndex(swiperRef.current.activeIndex);
                            offersEvents['offerSlideLeft']();
                          }}
                      />
                      <SwiperButton
                          direction={'next'}
                          disabled={slideActiveIndex === offers?.length - 1}
                          onClick={() => {
                            swiperRef.current.slideNext();
                            setSlideActiveIndex(swiperRef.current.activeIndex);
                            offersEvents['offerSlideRight']();
                          }}
                      />
                    </Stack>
                }
              </>

            }
        />
        {
            !isLoading && offers?.length === 0 &&
            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{mt:isMobile?'40%':'22%'}} rowGap={1}>
            <BookOnlineTwoTone color={'action'} fontSize='large'/>  
            <Typography variant="subtitle2" color={theme.palette.grey[600]} >
            {t('club.noOffers')}
            </Typography>
            </Stack>
          
        }
        {
            !isLoading && offers?.length > 0 &&
            <Swiper
                slidesPerView={1}
                spaceBetween={24}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
                onSlideChange={(swiper) => {
                  setSlideActiveIndex(swiper.activeIndex);
                }}
                style={{
                  width: '100%',
                  flex: 1,
                }}
            >
              {
                offers?.map((offer, index) => {
                  return <SwiperSlide key={index} style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <OfferItem
                        offer={offer}
                        clubId={clubId}
                        clubName={clubDetails?.name}
                        type={type}
                    />
                  </SwiperSlide>;
                })
              }
            </Swiper>
        }
      </Stack>
  );
}

function OfferItem({offer}) {

  const {offerId, offerAmount, offerValue, description} = offer;

  const navigateTo = useNavigate();
  const theme = useTheme();
  const {currencySymbol} = useDefaults();
  const {t} = useTranslate();

  const isAuthenticated = useIsAuthenticated();
  const {setIsOpenLoginDialog} = useOnboardingContext();

  const {locale} = useLocale();
  dayjs.locale(locale);

  function handleClickPurchaseOffer() {
    navigateTo(`wallet-offer/add?offerId=${offerId}`);
  }

  function handleClickSignIn() {
    setIsOpenLoginDialog(true);
  }

  return (
      <>
        <Stack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              cursor: 'pointer',
              position: 'relative',
            }}
            onClick={() => {
              if (isAuthenticated) {
                handleClickPurchaseOffer();
              } else {
                handleClickSignIn();
              }
            }}
        >
          <Box
              sx={{
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                backgroundColor: theme.palette.primary.main,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '200px',
                flex: 1,
              }}
          >
            <ArtPackage strokeColor={lighten(theme.palette.primary.main, .4)}/>
          </Box>
          <Stack
              rowGap={'6px'}
              sx={{
                backgroundColor: '#fff',
                border: `1px solid ${theme.palette.divider}`,
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                padding: '20px',
                height: '114px',
                boxSizing: 'border-box',
              }}
          >
            <Typography variant={'h6'} color={'inherit'}>
              {`${t(
                  'player.wallet.detail.offer.pay')} ${currencySymbol}${getLocalizedNum(
                  locale, +offerAmount)} ${t(
                  'player.wallet.detail.offer.get')} ${currencySymbol}${getLocalizedNum(
                  locale, +offerValue)}`}
            </Typography>
            <Typography variant={'body2'} fontWeight={400}
                        color={'text.secondary'}>
              {description}
            </Typography>
          </Stack>
        </Stack>
      </>
  );
}

export default ClubInfoNewOffers;
