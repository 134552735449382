import {statusError, statusLoading, statusSuccess} from './status';
import {
  CUSTOMERS_CLUBS_EXTRAS_lIST_GET_FAILURE,
  CUSTOMERS_CLUBS_EXTRAS_lIST_GET_LOADING,
  CUSTOMERS_CLUBS_EXTRAS_lIST_GET_SUCCESS,
} from '../constants';
import axios from 'axios';

export const customersClubsExtrasList_GET = (clubId,sports, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CUSTOMERS_CLUBS_EXTRAS_lIST_GET_LOADING));
      const response = await axios.get(`customers/clubs/extras/list?clubId=${clubId}&sport=${sports}`);
      dispatch(statusSuccess(CUSTOMERS_CLUBS_EXTRAS_lIST_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(CUSTOMERS_CLUBS_EXTRAS_lIST_GET_FAILURE, e));
    }
  };
};
