import {Chip, Tooltip} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import useChipStyles from '../../../hooks/bookings/useChipStyles';
import {getLocalizedNum} from '../../../utils/localization';
import useLocale from '../../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import useDefaults from '../../../hooks/defaults/useDefaults';


function ChipBookingStatus({
  bookingType,
  bookingStatus,
  bookingPrice,
  openMatchStatus,
  chipStyle = 'outlined' || 'filled',
  variant = 'booking-status' || 'booking-price',
  size = 'small' || 'medium',
}) {

  const {t} = useTranslate();
  const {currencySymbol} = useDefaults();
  const {locale} = useLocale();
  dayjs.locale(locale);
  const [neutralStyles, successStyles, errorStyles, infoStyles] = useChipStyles(
      chipStyle);

  let isReservationType = bookingType === 'recurrence' || bookingType ===
      'reservation';

  let isNonReservationType = bookingType === 'maintenance' || bookingType ===
      'closed' || bookingType === 'training' || bookingType === 'tournament';

  const successStylesObj = {...successStyles, borderRadius: '24px'}

  let isEventType = bookingType === 'event';
  let isOpenMatch = bookingType === 'open_match';

  const isCancelled = bookingStatus === 'cancelled';

  let styles;
  let label = '';
  let tooltipTitle = '';

  if (isCancelled) {
    styles = neutralStyles;
    label = t('global.chips.label.cancelled');

    return <Chip
        size={size}
        label={label}
        sx={{...styles}}
    />;
  }

  if (isReservationType) {
    // paid
    // unpaid
    // complete
    // closed
    const isPaid = bookingStatus === 'paid';
    const isUnpaid = bookingStatus === 'unpaid';

    if (isPaid) {
      styles = successStylesObj;
    }

    if (isUnpaid) {
      styles = errorStyles;
    }

    if (variant === 'booking-status') {

      if (isPaid) {
        label = t('global.chips.label.paid');
      }

      if (isUnpaid) {
        label = t('global.types.status.unpaid');
      }

    }

    if (variant === 'booking-price') {

      if (isPaid) {
        label = `${currencySymbol}${getLocalizedNum(locale,
            +bookingPrice)}`;
        tooltipTitle = t('global.chips.label.paid');
      }

      if (isUnpaid) {
        label = `${currencySymbol}${getLocalizedNum(locale,
            +bookingPrice)}`;
        tooltipTitle = t('global.types.status.unpaid');
      }

    }

    return (
        <Tooltip
            title={!!tooltipTitle ? tooltipTitle : ''}
            placement={'top'}
            arrow
        >
          <Chip
              size={size}
              label={label}
              sx={{...styles}}
          />
        </Tooltip>
    );

  }

  if (isNonReservationType) {
    //--- can be
    // active
    // complete
    styles = successStylesObj;

    label = t('global.chips.label.active');
  }

  if (isEventType) {
    // active
    styles = successStyles;
    label = t('global.chips.label.active');
  }


  if(isOpenMatch){

    const isBooked = openMatchStatus == 'booked';
    const isPaid = bookingStatus=='paid';
    const isUnpaid = bookingStatus=='unpaid';

    if (isPaid) {
      styles = successStylesObj;
    }

    if (isUnpaid) {
      styles = errorStyles;
    }

    if (variant === 'booking-price') {

      if (isPaid) {
        label = t('global.chips.label.paid');
      }

      if (isUnpaid) {
        label = t('global.types.status.unpaid');
      }

    }

    if(variant=='booking-status'){
      if (isBooked) {
      styles = successStylesObj;
      }else{
        styles = errorStyles;
      }

      if (isBooked) {
        label = t("openMatches.detail.page.court.status.booked");
        tooltipTitle = t("openMatches.detail.page.court.status.booked");
      }else{
        label = t("openMatches.detail.page.court.status.notBooked");
        tooltipTitle = t("openMatches.detail.page.court.status.notBooked");
      }
    }

    return (
      <Tooltip
          title={!!tooltipTitle ? tooltipTitle : ''}
          placement={'top'}
          arrow
      >
        <Chip
            size={size}
            label={label}
            sx={{...styles}}
        />
      </Tooltip>
    );

  }

  return <Chip
      size={size}
      label={label}
      sx={{...styles}}
  />;

}

export default ChipBookingStatus;


