export default function DialogOverride(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiPaper-root[role=dialog]': {
            borderRadius: '12px',
          },
          '.MuiDialog-paperWidthSm': {
            borderRadius: '12px',
            width: '600px',
          },
          '.MuiDialog-paperWidthXl': {
            borderRadius: '12px',
          },
        },
      },
    },

    MuiDialogTitle: {
      defaultProps: {
        variant: 'h5',
        sx: {
          padding: '24px',
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 26px',
          borderBottom: 'none',
        },
      },
      defaultProps: {
        dividers: true,
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px',
          ['@media (max-width: 450px)']: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '16px',
            '&> *': {
              width: '100%',
              marginLeft: '0 !important',
              marginBottom: '8px !important',
            },
          },
        },
      },
    },

  };
}
