import axios from 'axios';

export const customersClubsLocation_GET = (clubId, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`customers/clubs/location/${clubId}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};
