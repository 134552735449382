import {useTranslate} from '@tolgee/react';
import {Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {
  SlotSearchV2CourtSelectDialogCourtsList,
} from './SlotSearchV2CourtSelectDialogCourtsList';
import moment from 'moment-timezone';
import useExperienceContext
  from '../../../../hooks/clubExperience/useExperienceContext';
import {useSlotSearchV2Context} from '../../ProviderSlotSearchV2';
import {TransitionFade} from '../../../../vendor/mui/transitions';
import DialogBase from '../../../_Global/Dialogs/DialogBase';

function SlotSearchV2CourtSelectDialog() {

  const {t} = useTranslate();
  const navigateTo = useNavigate();
  const {clubDetails} = useExperienceContext();
  const clubTimezone = clubDetails?.addresses[0].timezone;
  
  const {
    selectedSlot,
    setSelectedSlot,
    selectedCourt,
    setSelectedCourt,
    isOpenCourtSelect,
    setIsOpenCourtSelect,
    willNavigateToCreateBooking,
    allowCourtUpdate,
    view,
  } = useSlotSearchV2Context();

  const courts = selectedSlot.slots;

  const isOpenMatch = view === 'openMatch';

  function handleNavigateToAddBooking() {

    const {
      clubId,
      clubName,
      clubCity,
      clubStreet,
      clubHouseNumber,
      clubPostalCode,
      courtId,
      courtName,
      courtSize,
      courtSport,
      courtType,
      courtFeature,
      date,
      startTime,
      endTime,
      discountApplied,
    } = selectedCourt;

    navigateTo('add-booking', {
      state: {
        clubId,
        clubName,
        clubCity,
        clubStreet,
        clubHouseNumber,
        clubPostalCode,
        courtId,
        courtName,
        courtSize,
        courtSport,
        courtType,
        courtFeature,
        startDate: moment.tz(date, clubTimezone).format('YYYY-MM-DD'),
        startTime,
        endTime,
        discountApplied,
      },
    });
  }

  function handleNavigateToCreateOpenMatch() {
    const locationState = {
      ...selectedCourt,
      allowCourtUpdate,
      startDate: moment.tz(selectedCourt.date, clubTimezone).format('YYYY-MM-DD'),
    };
    navigateTo(`/club/${clubDetails?.clubId}/openMatches/create`, {state: locationState});
  }

  function handleOnClose() {
    setIsOpenCourtSelect(false);
  }

  function handleOnExit() {
    if (willNavigateToCreateBooking) {
      if (isOpenMatch) {
        return handleNavigateToCreateOpenMatch();
      }else {
        handleNavigateToAddBooking();
      }
    } else {
      setSelectedSlot({});
      setSelectedCourt({});
    }
  }

  return (
      <DialogBase
          scroll={'body'}
          isOpen={isOpenCourtSelect}
          onClose={handleOnClose}
          onExited={handleOnExit}
          transitionComponent={TransitionFade}
          title={t('search.courtSelect.title')}
          contentSlot={<SlotSearchV2CourtSelectDialogCourtsList
              courts={courts}/>}
          actionsSlot={<>
            <Button
                color={'inherit'}
                size={'large'}
                variant={'outlined'}
                onClick={() => {
                  handleOnClose();
                }}
            >
              {t('global.buttons.actions.close')}
            </Button>
          </>}
          dialogStyles={{
            '& .MuiDialogContent-root': {
              padding: 0,
            },
          }}
      />
  );
}

export default SlotSearchV2CourtSelectDialog;
