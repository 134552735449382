import {useEffect, useState} from 'react';
import {
  getCurrentParticipant,
  getHasBookingPast,
  getIsBookingCancellable,
} from '../../utils/bookings';
import useUser from '../access/useUser';
import usePolicies from '../policies/usePolicies';

function useCanDoActions(
    clubId, bookingStatus, bookingDate, bookingStartTime, bookingTimezone,
    bookingParticipants, bookingPaymentType) {

  const user = useUser();
  const policies = usePolicies();

  const [canDoCancel, setCanDoCancel] = useState('pending');
  const [canDoPayment, setCanDoPayment] = useState('pending');

  //Can do Cancel
  useEffect(() => {

    if (policies && user && bookingStatus && bookingDate && bookingStartTime &&
        bookingTimezone) {

      const {
        bookingCancellation: policyAllowsCancellation,
        bookingCancellationTime: policyCancellationWindowHours,
      } = policies;

      /* Does policy allow cancellation ?*/
      if (!policyAllowsCancellation) {
        return setCanDoCancel(false);
      }

      /*Cant Cancel if already cancelled*/
      if (bookingStatus === 'cancelled') {
        return setCanDoCancel(false);
      }

      /*Only Booker Can Cancel*/
      const userId = user.userObj.id;
      const currentParticipant = getCurrentParticipant(userId,
          bookingParticipants);
      const isCurrentParticipantBooker = currentParticipant.owner;

      if (!isCurrentParticipantBooker) {
        return setCanDoCancel(false);
      }

      /*Within Policy Booking Cancellation Window*/
      const isCancellable = getIsBookingCancellable(policyCancellationWindowHours,
          bookingDate, bookingStartTime, bookingTimezone);

      if (!isCancellable) {
        return setCanDoCancel(false);
      }

      /*Passed All Checks Can Cancel*/

      return setCanDoCancel(true);

    }
  }, [
    policies,
    user,
    bookingStatus,
    bookingDate,
    bookingStartTime,
    bookingTimezone,
    bookingParticipants]);

  //Can do Payment
  useEffect(() => {
    if (bookingStatus && bookingDate && bookingStartTime &&
        bookingTimezone &&
        bookingPaymentType && bookingParticipants) {

      const currentParticipant = getCurrentParticipant(user.userObj.id,
          bookingParticipants);

      //Cancelled bookings can not do payments
      if (bookingStatus === 'cancelled') {
        return setCanDoPayment(false);
      }

      const hasBookingPast = getHasBookingPast(bookingDate,
          bookingStartTime, bookingTimezone);

      //Past bookings can not do payments
      if (hasBookingPast) {
        return setCanDoPayment(false);
      }

      if (bookingPaymentType === 'single') {

        //only booker needs to pay in single payment type
        if (!currentParticipant.owner) {
          return setCanDoPayment(false);
        }

        //if current participant is the booker
        if (currentParticipant.owner) {

          if (currentParticipant.paymentStatus === 'paid') {
            //if already paid
            return setCanDoPayment(false);
          }
          if (currentParticipant.paymentStatus === 'unpaid') {
            //if not paid
            return setCanDoPayment(true);
          }
        }
      }

      if (bookingPaymentType === 'split') {
        if (currentParticipant.paymentStatus === 'paid') {
          return setCanDoPayment(false);
        }
        if (currentParticipant.paymentStatus === 'unpaid') {
          return setCanDoPayment(true);
        }
      }

    }
  }, [
    bookingStatus,
    bookingDate,
    bookingStartTime,
    bookingTimezone,
    bookingPaymentType,
    bookingParticipants]);

  return [canDoCancel, canDoPayment];

}

export default useCanDoActions;
