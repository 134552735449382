import {Box, Divider, Skeleton, Stack, Typography} from '@mui/material';

import {getBookingTimeHourSuffix} from '../../../utils/bookings';
import {useTranslate} from '@tolgee/react';
import theme from '../../../vendor/mui/theme';
import useLocale from '../../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import {getLocalizedNum} from '../../../utils/localization';
import useDefaults from '../../../hooks/defaults/useDefaults';

function BookingAddEditBilling({
                                 isLoading,
                                 courtBookingLength,
                                 priceData,
                                 walletPayment,
                                 paymentType,
                                 bookerPayment,
                                 isOpenMatch,
                                 amountPaidFromWallet,
                                 paidAtCounter,
                                 amountPaidAtCounter,
                                 totalBillingLabel,
                                 totalBillingStyles,
                                 children
                               }) {

  const {t} = useTranslate();

  const {locale} = useLocale();
  const {currencySymbol} = useDefaults();
  dayjs.locale(locale);
  const isMinutes = courtBookingLength < 1;
  courtBookingLength = isMinutes ? courtBookingLength  * 60 :Math.round(courtBookingLength * 10) / 10

  const courtAmount = +priceData?.court || 0;
  const playersPaidFor = priceData?.playersPaidFor
  const extrasAmount = +priceData?.extrasTotal || 0;
  const discountAmount = +priceData?.discount || 0;
  const platformPercentage = priceData?.platformPercentage || '0%';
  const platformFee = +priceData?.platformFee || 0;
  const totalAmount = +priceData?.totalPrice || 0;
  const wallet = priceData?.wallet;
  const totalAmountForWalletPayment =wallet?.isPartial ? wallet?.walletCredit : paymentType=='split'? bookerPayment : ((courtAmount + extrasAmount) -
      discountAmount);
console.log('totalAmountForWalletPayment',totalAmountForWalletPayment)
  return <Box>
    <Stack sx={{width: '100%'}}>

      <Stack 
      spacing={0} 
      color={'text.secondary'}
      >

        {courtBookingLength>0 &&
        <Stack direction={'row'}
               width={'100%'}
               
               paddingY={.5}
               display={courtBookingLength?'flex':'none'}
        >
          <Typography variant={'body1'} mr={'auto'}>
            {
                isLoading && <Skeleton width={'80px'}/>
            }
            {
                !isLoading &&
                `${t('bookingAdd.court')} ${courtBookingLength} 
                ${isMinutes ? t('global.components.billing.min') :t(
                    'bookingAdd.hour')}${!isMinutes ? getBookingTimeHourSuffix(
                    courtBookingLength) : ''}
                    `
            }
          </Typography>
          <Typography variant={'body1'}>
            {
                isLoading && <Skeleton width={'50px'}/>
            }
            {
                !isLoading &&
                `${currencySymbol}${getLocalizedNum(locale,courtAmount)}`
            }
            
          </Typography>
        </Stack>
        }
        {
          courtAmount && !!isOpenMatch?
          <Stack direction={'row'}
               width={'100%'}
               
               paddingY={.5}
        >
          <Typography variant={'body1'} mr={'auto'}>
            {
                isLoading && <Skeleton width={'80px'}/>
            }
            {
                `${t('openMatch.billing.courtText')} ${playersPaidFor>1?`x${playersPaidFor}`:''}`
            }
          </Typography>
          <Typography variant={'body1'}>
            {
                isLoading && <Skeleton width={'50px'}/>
            }
            {
                !isLoading &&
                `${currencySymbol}${getLocalizedNum(locale,courtAmount)}`
            }
            
          </Typography>
        </Stack>:''
        }

        {
          +extrasAmount>0 &&
            <Stack direction={'row'}
                width={'100%'}
                paddingY={.5}
          >
            <Typography variant={'body1'}
                        mr={'auto'}>
              {
                  isLoading && <Skeleton width={'80px'}/>
              }
              {
                  !isLoading && t('global.components.billing.extras')
              }
            </Typography>

            <Typography variant={'body1'}>
              {
                  isLoading && <Skeleton width={'50px'}/>
              }
              {
                  !isLoading &&
                  `${currencySymbol}${getLocalizedNum(locale,
                      extrasAmount)}`
              }
            </Typography>
          </Stack>
        }

        {/*Discount*/}
        <Stack direction={'row'}
               width={'100%'}
               
               paddingY={.5}
        >
          <Typography variant={'body1'} mr={'auto'}>
            {
                isLoading && <Skeleton width={'80px'}/>
            }
            {
                !isLoading && t('global.components.billing.discount')
            }
          </Typography>
          <Typography variant={'body1'} color={discountAmount > 0 && 'error.main'}>
            {
                isLoading && <Skeleton width={'50px'}/>
            }
            {
                !isLoading &&
                `${discountAmount > 0 ? "-" : ""} ${currencySymbol}${getLocalizedNum(locale,
                    discountAmount)}`
            }
          </Typography>
        </Stack>

        {/*Wallet Credit*/}
        {
            walletPayment &&
            <Stack direction={'row'} width={'100%'} paddingY={.5}>
              <Typography variant={'body1'} mr={'auto'}>
                {
                    isLoading && <Skeleton width={'80px'}/>
                }
                {
                    !isLoading &&
                    `${t('global.components.billing.walletDeduction')}`
                }
              </Typography>
              <Typography variant={'body1'} color={'error.main'}>
                {
                    isLoading && <Skeleton width={'50px'}/>
                }
                {
                    !isLoading &&
                    `- ${currencySymbol}${getLocalizedNum(locale,amountPaidFromWallet ?? totalAmountForWalletPayment)}`
                }
              </Typography>
            </Stack>
        }
       
        {
            !!paidAtCounter &&
            <Stack direction={'row'} width={'100%'} paddingY={.5}>
              <Typography variant={'body1'} mr={'auto'}>
                {
                    isLoading && <Skeleton width={'80px'}/>
                }
                {
                    !isLoading &&
                    `${t('global.components.billing.counterCredit')}`
                }
              </Typography>
              <Typography variant={'body1'} color={'error.main'}>
                {
                    isLoading && <Skeleton width={'50px'}/>
                }
                {
                    !isLoading &&
                    `- ${currencySymbol}${getLocalizedNum(locale,amountPaidAtCounter ?? totalAmountForWalletPayment)}`
                }
              </Typography>
            </Stack>
        }

          {/*Platform Percentage and fee*/}
          {
            (isOpenMatch || (paymentType=='single' && !walletPayment) || paymentType=='split') &&
            <Stack direction={'row'} width={'100%'} paddingY={.5}>
              <Typography variant={'body1'} mr={'auto'}>
                {
                    isLoading && <Skeleton width={'80px'}/>
                }
                {
                    !isLoading && `${t(
                        'global.components.billing.platformCharges')} ${platformPercentage}`
                }
              </Typography>
              <Typography variant={'body1'}>
                {
                    isLoading && <Skeleton width={'50px'}/>
                }
                {
                    !isLoading &&
                    `${currencySymbol}${getLocalizedNum(locale,
                        platformFee)}`
                }
              </Typography>
            </Stack>
          }

        {isOpenMatch && <Divider/>}
        {/*Total Billing*/}
        <Stack direction={'row'}
               width={'100%'}>

          <Typography variant={'h6'} mr={'auto'} color={'text.primary'} {...totalBillingStyles}>
            {
                isLoading && <Skeleton width={'80px'}/>
            }
            {
                !isLoading && (totalBillingLabel ??t('global.components.billing.totalBilling'))
            }
          </Typography>
          <Typography variant={'h6'}
                      color={'text.primary'} {...totalBillingStyles}>
            {
                isLoading && <Skeleton width={'50px'}/>
            }
            {
                !isLoading &&
                `${currencySymbol}${walletPayment && !wallet?.isPartial && paymentType=='single' ?
                    getLocalizedNum(locale, 0) :
                    getLocalizedNum(locale, +totalAmount)}`
            }
          </Typography>
        </Stack>
        {children}
      </Stack>
    </Stack>
  </Box>;
}

export default BookingAddEditBilling;
