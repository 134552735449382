import {Box, useTheme} from '@mui/material';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import LayoutClubPage from './LayoutClubPage';
import {Suspense, useEffect} from 'react';
import LayoutCLubHeaderNew from './LayoutCLubHeaderNew/LayoutCLubHeaderNew';
import SiteFooter from '../../components/_Global/Site/SiteFooter';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import useIsMobile from '../../hooks/ui/useIsMobile';
import useNavItems from './Nav/useNavItems';
import useExperienceContext
  from '../../hooks/clubExperience/useExperienceContext';

function LayoutClubNew() {

  const {pathname} = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const navItems = useNavItems();
  const {clubDetails} = useExperienceContext();

  const {clubId} = useParams();
  const homePath = `/club/${clubId}`;
  const isHome = pathname === homePath;

  //Set background image
  useEffect(() => {

    let bg;

    bg = clubDetails?.backgroundImage ?
        `${clubDetails?.backgroundImage}` :
        '/assets/club-bg-auth.png';

    if (bg) {
      document.body.style.backgroundImage = `url(${bg})`;
      document.body.style.backgroundSize = '100% auto';
      document.body.style.backgroundPosition = 'top';
      document.body.style.backgroundRepeat = 'no-repeat';

    } else {
      document.body.style.backgroundImage = ``;
    }

  }, [isHome, isAuthenticated]);

  return (
      <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
      >
        <LayoutCLubHeaderNew navItems={navItems}/>
        <Box
            component={'main'}
            sx={{
              position: 'relative',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
        >
          <Suspense fallback={<h1></h1>}>
            <LayoutClubPage>
              <Outlet/>
            </LayoutClubPage>
            <SiteFooter container={'fixed'}/>
          </Suspense>
        </Box>

      </Box>
  );
}

export default LayoutClubNew;
