import { alpha } from "@mui/material/styles"
import { chipClasses } from "@mui/material/Chip"

// ----------------------------------------------------------------------

const COLORS = ["primary", "secondary", "info", "success", "warning", "error"]

// ----------------------------------------------------------------------

export default function ChipOverride(theme) {

  const isLight = theme.palette.mode === 'light';

  const rootStyles = (ownerState,theme) => {

    const defaultColor = ownerState.color === "default"

    const filledVariant = ownerState.variant === "filled"

    const outlinedVariant = ownerState.variant === "outlined"

    const softVariant = ownerState.variant === "soft"

    const defaultStyle = {
      [`& .${chipClasses.deleteIcon}`]: {
        opacity: 0.48,
        color: "currentColor",
        "&:hover": {
          opacity: 1,
          color: "currentColor"
        }
      },

      ...(defaultColor && {
        [`& .${chipClasses.avatar}`]: {
          color: theme.palette.text.primary
        },
        // FILLED
        ...(filledVariant && {
          color: isLight ? theme.palette.common.white : theme.palette.grey[800],
          backgroundColor: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: isLight
                ? theme.palette.grey[700]
                : theme.palette.grey[100]
          },
          [`& .${chipClasses.icon}`]: {
            color: isLight
                ? theme.palette.common.white
                : theme.palette.grey[800]
          }
        }),
        // OUTLINED
        ...(outlinedVariant && {
          border: `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`
        }),
        // SOFT
        ...(softVariant && {
          color: theme.palette.text.primary,
          backgroundColor: alpha(theme.palette.grey[500], 0.16),
          "&:hover": {
            backgroundColor: alpha(theme.palette.grey[500], 0.32)
          }
        })
      })
    }

    const colorStyle = COLORS.map(color => ({
      ...(ownerState.color === color && {
        [`& .${chipClasses.avatar}`]: {
          color: theme.palette[color].lighter,
          backgroundColor: theme.palette[color].dark
        },
        // SOFT
        ...(softVariant && {
          color: theme.palette[color][isLight ? "dark" : "light"],
          backgroundColor: alpha(theme.palette[color].main, 0.16),
          "&:hover": {
            backgroundColor: alpha(theme.palette[color].main, 0.32)
          }
        })
      })
    }))

    const disabledState = {
      [`&.${chipClasses.disabled}`]: {
        opacity: 1,
        color: theme.palette.action.disabled,
        [`& .${chipClasses.icon}`]: {
          color: theme.palette.action.disabled
        },
        [`& .${chipClasses.avatar}`]: {
          color: theme.palette.action.disabled,
          backgroundColor: theme.palette.action.disabledBackground
        },
        // FILLED
        ...(filledVariant && {
          backgroundColor: theme.palette.action.disabledBackground
        }),
        // OUTLINED
        ...(outlinedVariant && {
          borderColor: theme.palette.action.disabledBackground
        }),
        // SOFT
        ...(softVariant && {
          backgroundColor: theme.palette.action.disabledBackground
        })
      }
    }

    return [
      defaultStyle,
      ...colorStyle,
      disabledState,
      {
        fontWeight: 500,
        borderRadius: theme.shape.borderRadius
      }
    ]
  }

  return {
    MuiChip: {
      defaultProps:{
        variant: 'soft',
      },
      styleOverrides: {
        root: ({ ownerState,theme }) => {
          return rootStyles(ownerState,theme);
        }
      }
    }
  }
}
