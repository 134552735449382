import {useEffect, useState} from 'react';
import {
  AddPlayerIconBtn,
  AvatarIconContainer,
  ClippedText,
  PlayerCardContainer,
} from '../../vendor/mui/styled-components/OpenMatches';
import ProfileImageViewer from '../_Global/Profile/ProfileImageViewer';
import {
  Avatar,
  Box,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {Add, Close} from '@mui/icons-material';
import DialogAddPlayer from './DialogAddPlayer';
import {
  addPlayerToState,
  checkAvailibity,
  removePlayerFromState,
} from '../../utils/openMatches';
import {getInitials} from '../../utils/ui';
import useIsMobile from '../../hooks/ui/useIsMobile';
import {useSnackbar} from 'notistack';
import {useTranslate} from '@tolgee/react';
import ChipPlayerPaymentStatus from '../_Global/Chips/ChipPlayerPaymentStatus';
import {useSelector} from 'react-redux';
import {useFormContext} from 'react-hook-form';
import useOnboardingContext from '../../hooks/onboarding/useOnboardingContext';
import PlayerRatingChip from './PlayerRatingChip';

const PlayerCard = props => {

  const {player, index, team, players, setValue, paymentStatusPage} = props;

  const {setIsOpenLoginDialog} = useOnboardingContext();
  const {watch} = useFormContext();
  const watched = watch();

  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();
  const isMobile = useIsMobile();
  const location = useLocation();
  const url = location?.pathname;
  const isCreate = url?.includes('create');

  const matchGender = watched?.gender;
  const sport = watched?.sport;
  const matchRating = [watched?.minRating ?? 0, watched?.maxRating];

  const storeData = useSelector((state) => state.openMatches);
  const details = storeData.singleDetail;
  const matchDetails = details?.data;

  const currentUser = storeData?.currentLoggedInUser;
  const newJoinee = !matchDetails?.participants?.find(
      x => x?.user?.email == currentUser?.email);

  const isRegistered = currentUser?.isRegistered;
  const isSameTeam = currentUser?.team == player?.team;
  const btnText = isRegistered ? (
      isSameTeam && currentUser?.paymentStatus != 'paid' ?
          t('openMatches.page.player.addPartner') :
          t('openMatches.page.player.slotAvailable')
  ) : t('openMatches.page.player.joinHere');
  const canAddPartner = isRegistered && isSameTeam;

  const showRemoveBtn = isCreate
      ? true
      : !!currentUser?.isRegistered &&
      newJoinee && !player?.isAlreadyRegistered &&
      (player?.email == currentUser?.email || // to remove himself
          player?.addedBy == currentUser?.id); // to remove players added by current

  const [isDisabled, setIsDisabled] = useState(false);
  const [addPlayer, setAddPlayer] = useState(false);

  const btnClickHandler = () => {

    const isEmpty = !player?.isAdded;

    if (isDisabled)
      return;

    if (!isEmpty)
      return;

    if (paymentStatusPage)
      return;

    if (!currentUser?.email) {

      setIsOpenLoginDialog(true);
      return;
    }
    if ((isCreate && isSameTeam) || (newJoinee && isRegistered && isSameTeam)) {
      setAddPlayer(true);
      return;
    }

    if (newJoinee && !isRegistered) {

      const {
        email,
        firstName,
        gender,
        id,
        imageUrl,
        lastName,
        rating,
      } = currentUser;

      // gender and rating check before joining

      addPlayerToList({
        email,
        firstName,
        gender,
        id,
        imageUrl,
        lastName,
        rating,
        isAdded: true,
        team,
      }, 'joining');
    }

    if (currentUser?.addedBy != null)
      return;

  };

  const addPlayerToList = (player, newUserJoining) => {

    const validToAdd = checkAvailibity({
      matchGender,
      matchRating,
      playerToAdd: player,
      players,
      enqueueSnackbar,
      t,
      newUserJoining,
    });

    if (!validToAdd)
      return;

    addPlayerToState({
      t,
      setValue,
      index,
      team,
      player: {...player},
      prevState: players,
      setIsOpen: setAddPlayer,
      enqueueSnackbar,
      newUserJoining,
    });

  };

  const CloseBtn = () => {

    const isRemovingSelf = newJoinee && player?.email == currentUser?.email;

    return (
        <Avatar
            className="closeBtn"
            onClick={() => removePlayerFromState({
              setValue,
              index,
              team,
              player,
              prevState: players,
              isRemovingSelf,
              currentUser,
              matchParticipants: matchDetails?.participants,
            })}
        >
          <Close color="error"/>
        </Avatar>
    );
  };

  useEffect(() => {

    if (matchDetails?.status == 'cancelled') {
      setIsDisabled(true);
      return;
    }

    if (isCreate) {
      if (isSameTeam) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (isRegistered) {

      // logge-in user is invited
      if (currentUser?.addedBy != null) {
        setIsDisabled(true);
        return;
      }
      // logged-in user has paid
      if (currentUser?.paymentStatus == 'paid') {
        setIsDisabled(true);
      } else if (isSameTeam) {
        // logged-in user is unpaid and slot is of same team
        setIsDisabled(false);
      } else {
        // logged-in user is unpaid and slot is of other team
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(false);
    }
  }, [player, currentUser]);

  return (
      <>
        <PlayerCardContainer
            isMobile={isMobile}
            isDisabled={player?.isAdded || isDisabled}
            onClick={() => btnClickHandler()}
        >
          <PaymentStatusChip
              isCreate={isCreate}
              player={player}
              currentUser={currentUser}
          />

          {/* remove icon show incase of and if user is new joinee and has added any player
          and it'll show on players that were added by currentLoggedInUser */}

          {showRemoveBtn && !!player?.isAdded && !player?.isBooker && (
              <CloseBtn/>
          )}

          <AvatarIcon
              t={t}
              isCreate={isCreate}
              player={player}
              canAddPartner={canAddPartner}
              btnText={btnText}
              isDisabled={isDisabled}
          />
        </PlayerCardContainer>

        <DialogAddPlayer
            isOpen={addPlayer}
            team={team}
            currentUser={currentUser}
            sport={sport}
            players={players}
            setIsOpen={setAddPlayer}
            setPlayer={addPlayerToList}
        />

      </>
  );

};

const PaymentStatusChip = ({player, isCreate, currentUser}) => {

  const isBooker = player?.isBooker;
  const paymentStatus = player?.paymentStatus;

  const isAssociated = player?.email == currentUser?.email || player?.addedBy ==
      currentUser?.id;

  return (
      <Box className="paymentStatus">
        {
            (!isCreate && paymentStatus == 'unpaid' && isAssociated) &&
            <ChipPlayerPaymentStatus
                chipStyle={'filled'}
                variant={'player-status'}
                size={'small'}
                playerPrice={player.price}
                paymentStatus={paymentStatus}
            />
        }
      </Box>
  );

};

export const AvatarIcon = ({
                             player,
                             isCreate,
                             canAddPartner,
                             btnText,
                             isDisabled,
                             t,
                             teamSize,
                             isMobile,
                           }) => {

  // alignment styles for this card are also in parent where AvatarIcon is being used

  return (
      <AvatarIconContainer
          className="avatarContainer"
          teamSize={teamSize}
          isMobile={isMobile}
          gender={player?.gender}
      >
        {
          player?.isAdded ?
              <AddedPlayerDetail
                  player={player}
                  isMobile={isMobile}
                  t={t}
              />
              :
              <AddPlayerView
                  isMobile={isMobile}
                  isCreate={isCreate}
                  player={player}
                  btnText={btnText}
                  isDisabled={isDisabled}
              />
        }
      </AvatarIconContainer>
  );

};

const AddedPlayerDetail = ({player, t, isMobile}) => {

  const name = isMobile ?
      player?.firstName :
      `${player?.firstName} ${player?.lastName}`;

  function stringAvatar(name) {
    return {
      children: getInitials(name),
    };
  }

  return (
      <Stack
          className={`addedPlayer ${player?.gender}`}
          alignItems={'center'}
          rowGap={1}
      >
        <ProfileImageViewer
            size={isMobile ? 'sm' : 'md'}
            imgPath={player?.imageUrl}
            placeholderStyle={'initials'}
            text={name}
            stringAvatar={stringAvatar}
        />
        <Stack
            className="nameLevel"
            alignItems={'center'}
            rowGap={1}
        >
          <Tooltip
              title={name?.length > 14 ? name : ''}
              placement={'top'}
              arrow
          >
            <ClippedText
                textAlign={'center'}
                isMobile={isMobile}
            >
              {name?.length > 14 ? `${name?.slice(0, 11)}...` : name}
            </ClippedText>
          </Tooltip>
          <PlayerRatingChip
              rating={player?.rating}
          />
        </Stack>
      </Stack>
  );

};

const AddPlayerView = ({player, isDisabled, isMobile, btnText}) => {

  const theme = useTheme();
  return (
      <Stack
          className="addPlayerView"
          alignItems={'center'}
          rowGap={1}
      >
        <AddPlayerIconBtn
            disabled={isDisabled}
            color={!isDisabled ? 'primary' : theme.palette.action.disabled}
            canAddPartner={!isDisabled}
            width={isMobile ? '40px' : null}
            height={isMobile ? '40px' : null}
        >
          {
              !isDisabled &&
              <Add fontSize={isMobile ? 'small' : 'large'}/>
          }
        </AddPlayerIconBtn>
        <Typography
        className='addPlayerBtnText'
            variant={isMobile ? 'body2' : 'body1'}
            fontSize={isMobile ? '13px' : 'initial'}
            color={!isDisabled ? 'primary' : theme.palette.action.disabled}
            textAlign={'center'}
        >
          {btnText}
        </Typography>
      </Stack>
  );
};
export default PlayerCard;
