
function IconSport({fill}) {
    return (
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.916 18.8374C15.916 19.1688 15.6474 19.4374 15.316 19.4374H0.683985C0.352614 19.4374 0.0839844 19.1688 0.0839844 18.8374V1.1625C0.0839844 0.83113 0.352614 0.5625 0.683984 0.5625H15.316C15.6474 0.5625 15.916 0.831129 15.916 1.1625V18.8374ZM1.13159 17.9493C1.13159 18.2806 1.40022 18.5493 1.73159 18.5493H14.2341C14.5654 18.5493 14.8341 18.2806 14.8341 17.9493V2.12746C14.8341 1.79609 14.5654 1.52746 14.2341 1.52746H1.73159C1.40022 1.52746 1.13159 1.79609 1.13159 2.12746V17.9493Z" fill="#757575"/>
        <path d="M15.3184 3.29102H0.0839844V4.19578H15.3184V3.29102Z" fill="#757575"/>
        <path d="M15.3184 15.8027H0.0839844V16.7075H15.3184V15.8027Z" fill="#757575"/>
        <line x1="0.0957031" y1="9.68125" x2="15.6934" y2="9.68125" stroke="#757575" stroke-width="0.7" stroke-dasharray="0.7 0.7"/>
        <path d="M8.45261 3.59912H7.54785V16.1108H8.45261V3.59912Z" fill="#757575"/>
        </svg>
        

    );
  }
  
  export default IconSport;
  