import {useSlotSearchV2Context} from '../ProviderSlotSearchV2';
import {
  ToggleButtonGroupRounded,
  ToggleButtonRounded,
} from '../../_Global/ToggleButtonGroup/ToggleButtonGroupRounded';
import {Box, Stack} from '@mui/material';
import IconSportPadel from '../../../assets/icons/Sports/IconSportPadel';
import IconSportTennis from '../../../assets/icons/Sports/IconSportTennis';
import IconSportSquash from '../../../assets/icons/Sports/IconSportSquash';

function SlotSearchV2HeaderSportSelectMobile({isTablet,sports,selectedSport, handleSelectSport}) {

  function handleOnChange(e, value) {
    if (selectedSport === value || !value) {
    } else {
      handleSelectSport(value);
    }
  }

  return (
      <Box sx={{
        minWidth:"276px",
        display:"flex",
        justifyContent:"flex-start",
        width: isTablet ? "100%" : null,
      }}>

        <ToggleButtonGroupRounded
            exclusive
            fullWidth={isTablet}
            value={selectedSport}
            onChange={handleOnChange}
        >
          {
            sports?.map((sport, index) => {

              let Icon;

              if (sport.sport === 'Padel') {
                Icon = IconSportPadel;
              }

              if (sport.sport === 'Tennis') {
                Icon = IconSportTennis;
              }

              if (sport.sport === 'Squash') {
                Icon = IconSportSquash;
              }

              return (
                  <ToggleButtonRounded
                      key={index}
                      value={sport.sport}
                      color={'primary'}
                  >
                    <Stack direction="row" alignItems={'center'} columnGap={.5}>
                      {Icon && <Icon width={20} height={20}/>}
                      {sport.sport}
                    </Stack>
                  </ToggleButtonRounded>
              );
            })
          }

        </ToggleButtonGroupRounded>
      </Box>

  );
}

export default SlotSearchV2HeaderSportSelectMobile;
