function IconSportSquash(props) {
  return (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="c">
          <path
              d="m10,9.8c0-.1,0-.2-.1-.3,0,.2,0,.3.1.3h0Zm3.7,3.7h0c.1.1.2,0,.3.1-.1,0-.2,0-.3-.1Zm-3.7-3.7c0-.1,0-.2-.1-.3,0,.2,0,.3.1.3h0Zm-.7,1.2c0,.1.1.2.2.4v-.2s-.1-.1-.2-.2Zm.3.2v.2c-.1-.1-.1-.2-.2-.4,0,.1.1.2.2.2Zm13.4-7.3h0q0-.1-.1-.1v-.1c-.6-1.4-1.7-2.5-3.1-3.1h-.1C17.7-.2,15.4,0,13.5,1h0c-1.5.8-2.8,2-3.6,3.5-.3.3-1.1,2-1.1,3.8,0,2.4.7-.9-1.2,6.9l-2.1,2c-1.4-1.4-.6-1.5-4.9,2.8-.4.5-.6,1-.6,1.5s.2,1,.6,1.4c.4.4.9.6,1.4.6s1-.2,1.4-.6c4.2-4.2,4.2-3.5,2.8-4.9l2-2,5.6-1.4c2,.4,4.6-.4,5.2-.9,1.6-.9,2.7-2.1,3.4-3.4v-.1c.5-.4,1.8-3.5.6-6.3ZM1.3,22.2c-.9-.9.4-1.8,3.1-4.5l1.4,1.4c-2.8,2.9-3.6,4-4.5,3.1ZM21.9,3.9l-2.6,2.5-2.3-2.3,2.5-2.6c1.1.5,1.9,1.4,2.4,2.4Zm-3.2,3.2l-2.8,2.7-2.3-2.3,2.7-2.8,2.4,2.4Zm-.1-5.9l-2.2,2.2-1.9-1.9c1.3-.5,2.7-.7,4.1-.3Zm-5.1.8l2.1,2.1-2.7,2.8-2.1-2.1c.7-1.2,1.6-2.1,2.7-2.8Zm-3.2,3.7l1.9,1.9-2.3,2.3c.1-.6-.6-1.9.4-4.2Zm-.7,5.5v.2c-.1-.1-.1-.2-.2-.4,0,.1.1.2.2.2Zm-.9,3.6l.8-3.4c.5,1.1,1.4,2,2.6,2.6l-3.4.8Zm1.7-4l2.6-2.6,2.3,2.3-2.6,2.6c-1.1-.4-1.9-1.3-2.3-2.3Zm3.2,2.8l2.3-2.3,1.9,1.9c-2.3.9-3.7.3-4.2.4Zm5.1-.9l-2.1-2.1,2.7-2.8,2.1,2.1c-.6,1.2-1.5,2.1-2.7,2.8Zm3.3-3.7l-1.9-1.9,2.2-2.2c.3,1.2.3,2.7-.3,4.1ZM3.5,7c-2,0-3.5-1.6-3.5-3.5C0,.3,3.9-1.1,6,1c2.2,2.2.6,6-2.5,6ZM1.7,1.7c-1,1-1,2.6,0,3.5.9.9,2.6.9,3.5,0C7.6,2.9,4.1-.6,1.7,1.7Z"
              strokeWidth="0"/>
        </g>

      </svg>

  );
}

export default IconSportSquash;
