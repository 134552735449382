import {Stack} from '@mui/material';
import PackageBenefitDetailsDiscountsRow from './PackageBenefitDetailsDiscountsRow';

function PackageBenefitDetailsDiscounts({discounts}) {

  if (discounts?.length > 0) {
    return (
        <Stack spacing={2}>
          {
            discounts.map((discount, index) => {
              return <PackageBenefitDetailsDiscountsRow
                  key={index}
                  discount={discount}
              />;
            })
          }
        </Stack>
    );
  }

}

export default PackageBenefitDetailsDiscounts;