import {useTranslate} from '@tolgee/react';
import DialogConfirmation from '../../../components/_Global/Dialogs/DialogConfirmation'
import {useState} from 'react';
import useBookingsNotify from '../../../hooks/bookings/useBookingNotify';
import { Button } from '@mui/material';

export function BookingsNotifyButton({
                                       variant = 'contained' || 'outlined',
                                       size = 'large' || 'medium' || 'small',
                                       bookingId,
                                       handleConfirm,
                                       loading,
                                       setLoading
                                     }) {

  const {t} = useTranslate();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const {submitBookingsNotify, isSubmittingNotify} = useBookingsNotify(
      handleNotifySuccess);

  function handleNotifySuccess() {
    // alert('notify success');
    setIsConfirmationDialogOpen(false);
    if(setLoading){
      setLoading(false);
    }
  }

  function handleNotify() {
    submitBookingsNotify(bookingId,'');
  }

  return (
      <>
        <Button
            variant={variant}
            size={size}
            onClick={()=>{
              setIsConfirmationDialogOpen(true);
            }}
        >
          {t('bookings.actions.notify')}
        </Button>
        <DialogConfirmation
            isOpen={isConfirmationDialogOpen}
            dialogTitle={t('bookings.recurring.detail.reminder.confirm.title')}
            dialogContent={t('bookings.reservation.detail.reminder.confirm.description')}
            variant={'primary'}
            handleClose={()=>{
              setIsConfirmationDialogOpen(false);
            }}
            handleConfirm={()=>handleConfirm? handleConfirm(handleNotifySuccess) : handleNotify()}
            confirmButtonLabel={t('booking.notify.confirmation')}
            isSubmitting={loading ?? isSubmittingNotify}
        />

      </>
  );
}