import {useDispatch} from 'react-redux';
import {useTranslate} from '@tolgee/react';
import {useEffect, useState} from 'react';
import {
  customersRecurBookingsSelf_GET,
} from '../../vendor/redux/actions/recurbookings';
import {useSnackbar} from 'notistack';
import {ERR_NETWORK_ERROR} from '../../constants/errors';

function useBookingRecurring(recurringBookingId, bookingSingleId) {

  const dispatch = useDispatch();
  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();

  const [recurringBooking, setRecurringBooking] = useState();

  function fetchRecurBooking() {

    dispatch(customersRecurBookingsSelf_GET(
        recurringBookingId,
        bookingSingleId,
        '',
        '',
        '',
        '',
        cbSuccess,
        cbFail,
    ));

    function cbSuccess(res) {
      console.log('customersRecurBookingsSelf_GET success', res);
      setRecurringBooking(res?.data?.data.rows[0].recurrBooking);
    }

    function cbFail(e) {
      console.log('recurBookingsList_GET fail',e);
      const err = e?.response?.data?.data || ERR_NETWORK_ERROR;
      enqueueSnackbar(t(err),{variant:"error"})
    }
  }

  useEffect(() => {
    fetchRecurBooking();
  }, []);

  return [recurringBooking, fetchRecurBooking];

}

export default useBookingRecurring;
