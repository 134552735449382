function IconLogout({ fill }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_17855_188046)">
        <path
          d="M19.3735 9.37594H11.457C11.112 9.37594 10.832 9.09595 10.832 8.75096C10.832 8.40597 11.112 8.12598 11.457 8.12598H19.3735C19.7185 8.12598 19.9985 8.40597 19.9985 8.75096C19.9985 9.09595 19.7185 9.37594 19.3735 9.37594Z"
          fill={fill ?? "#0537C6"}
        />
        <path
          d="M16.2479 12.5003C16.0878 12.5003 15.9279 12.4394 15.8061 12.317C15.562 12.0727 15.562 11.6769 15.8061 11.4328L18.4895 8.74963L15.8061 6.0663C15.562 5.82217 15.562 5.42637 15.8061 5.18224C16.0504 4.93795 16.4462 4.93795 16.6904 5.18224L19.8153 8.30714C20.0594 8.55127 20.0594 8.94707 19.8153 9.19121L16.6904 12.3161C16.5678 12.4394 16.4079 12.5003 16.2479 12.5003Z"
          fill={fill ?? "#0537C6"}
        />
        <path
          d="M6.66651 20.0004C6.48814 20.0004 6.31892 19.9753 6.14986 19.9228L1.13491 18.2521C0.452562 18.0137 0 17.3779 0 16.6672V1.66764C0 0.748481 0.747505 0.000976562 1.66667 0.000976562C1.84488 0.000976562 2.0141 0.0260002 2.18331 0.0784888L7.19811 1.74927C7.88061 1.98761 8.33302 2.62342 8.33302 3.33415V18.3337C8.33302 19.2528 7.58567 20.0004 6.66651 20.0004ZM1.66667 1.25094C1.43749 1.25094 1.24996 1.43846 1.24996 1.66764V16.6672C1.24996 16.8446 1.36913 17.0096 1.53911 17.0688L6.53056 18.7321C6.56641 18.7437 6.6131 18.7504 6.66651 18.7504C6.89569 18.7504 7.08306 18.5629 7.08306 18.3337V3.33415C7.08306 3.1567 6.96389 2.99176 6.79392 2.93256L1.80246 1.26925C1.76661 1.25765 1.71992 1.25094 1.66667 1.25094Z"
          fill={fill ?? "#0537C6"}
        />
        <path
          d="M12.7072 6.66749C12.3622 6.66749 12.0822 6.3875 12.0822 6.04251V2.29262C12.0822 1.71845 11.6149 1.25094 11.0407 1.25094H1.666C1.32101 1.25094 1.04102 0.970947 1.04102 0.625957C1.04102 0.280967 1.32101 0.000976562 1.666 0.000976562H11.0407C12.3049 0.000976562 13.3322 1.02847 13.3322 2.29262V6.04251C13.3322 6.3875 13.0522 6.66749 12.7072 6.66749Z"
          fill={fill ?? "#0537C6"}
        />
        <path
          d="M11.0403 17.5005H7.70701C7.36202 17.5005 7.08203 17.2205 7.08203 16.8755C7.08203 16.5305 7.36202 16.2505 7.70701 16.2505H11.0403C11.6145 16.2505 12.0819 15.783 12.0819 15.2088V11.459C12.0819 11.114 12.3619 10.834 12.7069 10.834C13.0518 10.834 13.3318 11.114 13.3318 11.459V15.2088C13.3318 16.473 12.3045 17.5005 11.0403 17.5005Z"
          fill={fill ?? "#0537C6"}
        />
      </g>
      <defs>
        <clipPath id="clip0_17855_188046">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default IconLogout;
