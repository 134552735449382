import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import {
  AssignmentIndOutlined,
  PeopleAltOutlined,
  Repeat,
  SurroundSound,
  SportsBaseballOutlined
} from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import dayjs from 'dayjs';
import {Link, useParams} from 'react-router-dom';
import BookingsParticipantsDetails from './BookingsParticipantsDetails';
import DialogSection from '../../_Global/Dialogs/DialogSection';
import {useTranslate} from '@tolgee/react';
import useLocale from '../../../hooks/localization/useLocale';
import useBreakpoint from '../../../hooks/ui/useBreakpoint';
import {getOrdinal} from '../../../utils/localization';
import IconRecurrenceStart from '../../../assets/icons/IconRecurrenceStart.svg'
import IconRecurrenceEnd from '../../../assets/icons/IconRecurrenceEnd.svg'
import IconTotalBookings from '../../../assets/icons/IconTotalBookings.svg'
import usePolicies from '../../../hooks/policies/usePolicies';
import MeshlinkLightAndCode from '../MeshlinkLightAndCode';



function BookingsRecurringSingleDetails({
                                          details,
                                          type,
                                          isLoading,
                                        }) {

  const {t} = useTranslate();
  const {locale} = useLocale();
  dayjs.locale(locale);

  const policies = usePolicies();

  let players;
  const playersSrc = details?.players;
  const checkins = details?.singleBooking?.recurrBookingParticipantCheckIns;

  if (playersSrc && checkins) {
    players = playersSrc.map((player) => {
      const playerId = player?.userId;
      const playerCheckinEntry = checkins.find(
          (checkin) => checkin.userId === playerId);
      let playerCheckedIn;
      if (playerCheckinEntry) {
        playerCheckedIn = playerCheckinEntry.checkedIn;
      }
      return {
        ...player,
        checkedIn: playerCheckedIn || false,
      };
    });
  } else {
    players = playersSrc;
  }

  const ordinance = getOrdinal( details?.bookingNumber,locale);
  return (
      <>
        <Box>
       {!isLoading && details?.singleBooking?.status === 'paid' &&  details?.meshlink?.code !== 'no_reservation' &&
          <MeshlinkLightAndCode
          pinCode={details?.meshlink?.data?.pinCode}
          customMessage={policies?.customPinCodeMessage}
          />}

          {
              !isLoading &&
              <Header details={details} type={type}/>
          }
          {
              isLoading &&
              <Skeleton
                  width="100%"
                  height={'296px'}
                  sx={{
                    mt: 2,
                    mb: 2,
                    borderRadius: '8px',
                    transform: 'scale(1)',
                  }}
              />

          }
          {!isLoading && <Divider/>}
          {isLoading && <Skeleton width={'100%'} height={'2px'}/>}
          <DialogSection
              label={t(
                  'bookings.recurring.single.detail.section.label.occurrence')}
              icon={Repeat}
              isLoading={isLoading}
          >
            {
                isLoading && <Skeleton width="240px"/>
            }
            {
                !isLoading &&
                <Typography>{ordinance || ''} {t(
                    `global.components.recurringSingleDetails.booking`)}</Typography>
            }
          </DialogSection>
          <DialogSection
              label={t('bookings.recurring.single.detail.section.label.time')}
              icon={AccessTimeOutlinedIcon}
              isLoading={isLoading}
          >
            {
                isLoading && <Skeleton width="240px"/>
            }
            {
                !isLoading &&
                <Stack direction={'row'} spacing={2}>
                  <Typography>
                    {
                      `${dayjs(details?.bookingDate,
                          'YYYY-MM-DD').format('ddd ll')}`
                    }
                  </Typography>
                  <Stack direction={'row'} spacing={.5}>
                    <Typography>
                      {
                        dayjs(details?.bookingStartTime, 'HH:mm').
                            format('LT')
                      }
                    </Typography>
                    <Typography>-</Typography>
                    <Typography>
                      {
                        dayjs(details?.bookingEndTime, 'HH:mm').
                            format('LT')
                      }
                    </Typography>
                  </Stack>
                </Stack>
            }
          </DialogSection>
          <DialogSection
              label={t('bookings.recurring.single.detail.section.label.court')}
              icon={SurroundSound}
              isLoading={isLoading}
          >
            {
                isLoading && <Skeleton width="240px"/>
            }
            {
                !isLoading &&
                <Typography>{details?.court?.court?.name}</Typography>
            }

          </DialogSection>
          <DialogSection
              label={t(
                  'bookings.recurring.single.detail.section.label.players')}
              icon={PeopleAltOutlined}
              isLastChild={true}
              isLoading={isLoading}
          >
            <BookingsParticipantsDetails
                isLoading={isLoading}
                players={players}
            />
          </DialogSection>
        </Box>
      </>
  );
}

function Header({details, type}) {

  const {t} = useTranslate();
  const theme = useTheme();
  const {isSmDown} = useBreakpoint();

  const {customerId,clubId} = useParams();

  let titleLink;

  if (type === 'customer-profile') {
    titleLink = `/customers/${customerId}/profile/bookings/recurring/recurrence-detail?id=${details?.recurrenceId}`;
  } else if (type === 'player-bookings-table') {
    titleLink = `/bookings/recurring/recurrence-detail?id=${details?.recurrenceId}`;
  } else if (type === 'player-available-slots') {
    titleLink = ``;
  } else if (type === 'customer') {
    titleLink = '';
  } else {
    titleLink = `/bookings/recurring/recurrence-detail?id=${details?.recurrenceId}`;
  }

  function getTitleColor() {

    if (!details?.title) {
      return 'text.disabled';
    }

    if (type === 'player-available-slots') {
      return 'text.primary';
    }

    return 'primary';
  }

  return (
      <Stack
          sx={{
            mt: 3,
            padding: '20px 24px',
            backgroundColor:theme.palette.background.neutral,
            borderRadius: '8px',
            mb: 3,
          }}
      >
        <Typography variant={'subtitle1'} mb={2}>
          {t('bookings.recurring.single.detail.header.title')}
        </Typography>
        <Divider/>
        <Stack
            direction={'row'}
            spacing={1}
            alignItems={'flex-start'}
            pt={3}
            pb={3}
        >
          <AssignmentIndOutlined color={'disabled'}/>
          <Stack>
            <Typography
                variant={'overline'}
                mt={'-2px'}
                color={'text.secondary'}
            >
              {t('bookings.recurring.single.detail.section.label.title')}
            </Typography>
            <Typography
                component={type === 'player-available-slots' || type ===
                'customer' ? '' : Link}
                to={clubId?`/club/${clubId}${titleLink}`:titleLink}
                sx={{
                  textDecoration: 'none',
                }}
                variant={'body1'}
                color={getTitleColor()}
            >
              {details?.title ||
                  t('bookings.recurring.single.detail.placeholder.noTitle')}
            </Typography>
          </Stack>
        </Stack>
        <Divider/>

        <Stack
            pt={3}
            pb={3}
            spacing={1}
            rowGap={1}
            direction={isSmDown ? 'column' : 'row'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            sx={{width: '100%'}}
        >
          <HeaderItem
              label={t(
                  'bookings.recurring.single.detail.section.label.recurrence')}
              value={t(
                  `global.types.recurrenceTypes.${details?.recurrenceType}`)}
              icon={IconRecurrenceStart}
          />
          {isSmDown && <Divider/>}
          <HeaderItem
              label={t(
                  'bookings.recurring.single.detail.section.label.recurrenceEndsOn')}
              value={`${dayjs(details?.recurrenceEndDate,
                  'YYYY-MM-DD').format('ddd ll')}`}
                  icon={IconRecurrenceEnd}
          />
          {isSmDown && <Divider/>}
          <HeaderItem
              label={t(
                  'bookings.recurring.single.detail.section.label.totalBookings')}
              value={details?.totalBookings}
              icon={IconTotalBookings}
          />
        </Stack>
        {details?.recurringExtras?.length > 0 &&
        <>
         <Divider/>
        <Stack
              direction={'row'}
              alignItems={'flex-start'}
              mt={2}
              spacing={1}
              overflow={'hidden'}
          >
            <SportsBaseballOutlined color={'disabled'}
                    sx={{position: 'relative', top: '2px'}}/>

            <Stack
                spacing={.5}
                sx={{
                  width: '100%',
                  overflow: 'hidden',
                }}
            >
              <Typography variant={'overline'} color={'text.secondary'}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
              >
                {t('bookingAdd.extra')}
              </Typography>
              <Typography variant={'body1'}>{details?.recurringExtras
              ?.map(extra => (`${extra.extra?.name} (${extra?.quantity})`)).join(', ')}</Typography>
            </Stack>
        </Stack>
        </>}
      </Stack>
  );
}

function HeaderItem({label, value,icon}) {

  const {isSmDown} = useBreakpoint();


  return (
      <Stack
          direction={'row'}
          alignItems={'flex-start'}
          spacing={1}
          overflow={'hidden'}
          sx={{
            ...(isSmDown) && {
              width: '100%',
            },
          }}
      >
        <img
        src={icon}
        color={'disabled'}
        sx={{position: 'relative', top: '2px'}}
        />
        <Stack
            spacing={.5}
            sx={{
              width: '100%',
              overflow: 'hidden',
            }}
        >
          <Typography
              variant={'overline'} color={'text.secondary'}
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
          >
            {label}
          </Typography>

          <Typography
              variant={'body1'}
              textTransform={'capitalize'}
          >
            {value}
          </Typography>
        </Stack>
      </Stack>
  );
}

export default BookingsRecurringSingleDetails;
