import Intercom from "@intercom/messenger-js-sdk";
import useUser from '../hooks/access/useUser';
import useIsMobile from "../hooks/ui/useIsMobile";
import { useEffect } from "react";
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Hex from 'crypto-js/enc-hex';
import dayjs from "dayjs";

const IntercomComponent=({clubDetails})=>{

  const isMobile = useIsMobile();
  const user = useUser();
  const userObj = user?.userObj;
  
  const time = dayjs().format('YYYY-MM-DD hh:mm A');
  const defaultPlayerUser = {name:`Player ${time}`, id: dayjs().toISOString()};

  useEffect(() => {
    const secretKey = process.env.REACT_APP_INTERCOM_SECRET_KEY ?? '';
    const userIdentifier = userObj?.id?.toString() ?? '';
    const hash = HmacSHA256(userIdentifier, secretKey).toString(Hex);

    Intercom({
      app_id: process.env.REACT_APP_INTERCOM_API_ID,
      user_id: user? userObj?.id: defaultPlayerUser?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: user? `${userObj?.firstName} ${userObj?.lastName}`: defaultPlayerUser?.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: userObj?.email ?? '', // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      phone:userObj?.cellNumber,
      user_hash:user? hash : '',
      company:{
        id:clubDetails?.clubId,
        name:clubDetails?.name,
        "Company City":clubDetails?.addresses?.at(0)?.city,
        "Company country":clubDetails?.addresses?.at(0)?.country,
      },
      alignment: 'right',        // Left or right position on the page
      horizontal_padding: isMobile? 10: 50,   // Horizontal padding
      vertical_padding: isMobile? 100: 50      // Vertical padding
    });
  }, [isMobile, user, userObj]);

  useEffect(()=>{

    if(user?.userObj){
      window.Intercom('update',{
        "User Type":'Player',
        "User City":userObj?.customerAddress?.city,
        "User Country":userObj?.customerAddress?.country,
      })
    }
  },[user])

  return(
    <>
    </>
  )

}
export default IntercomComponent;