function IconSportTennis(props) {
  return (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
           xmlns="http://www.w3.org/2000/svg" {...props}>
          <g clipPath="url(#clip0_17895_119331)">
            <path
                d="M24 6.83581C24 5.05926 23.3633 3.45018 22.2072 2.30495C21.0526 1.16118 19.431 0.53125 17.6412 0.53125C15.2378 0.53125 12.7581 1.6223 10.8377 3.52455C9.0734 5.2723 8.00063 7.48833 7.83658 9.68468C7.78741 9.9988 7.7415 10.3324 7.69331 10.6849C7.41725 12.7034 7.0763 15.1899 5.71192 16.7662C5.59204 16.7856 5.48025 16.8415 5.39326 16.9285L0.442422 21.8794C-0.147474 22.4693 -0.147474 23.4291 0.442422 24.0191C0.728205 24.3049 1.10814 24.4622 1.51229 24.4622C1.91644 24.4622 2.29637 24.3048 2.58221 24.019L7.53206 19.0671C7.62139 18.9777 7.67504 18.8665 7.69407 18.7507C9.27033 17.3854 11.7576 17.0443 13.7766 16.7682C14.1577 16.716 14.5176 16.6668 14.8536 16.613C14.937 16.5996 15.0132 16.5688 15.0797 16.5253C17.1893 16.2898 19.2965 15.2467 20.976 13.583C22.8978 11.6793 24 9.22007 24 6.83581ZM8.46558 13.203C8.75561 13.7991 9.13852 14.339 9.60662 14.8027C10.1021 15.2935 10.6838 15.6894 11.3277 15.9818C9.82368 16.2866 8.25696 16.7862 7.05552 17.7678L6.6938 17.4061C7.66037 16.223 8.15955 14.6859 8.46558 13.203ZM1.75914 23.1961C1.69321 23.2621 1.60551 23.2984 1.51229 23.2984C1.41901 23.2984 1.33137 23.2621 1.26538 23.1961C1.12927 23.06 1.12927 22.8385 1.26538 22.7024L5.80474 18.163L6.29757 18.6558L1.75914 23.1961ZM14.1733 15.4125C14.1732 15.4125 14.1728 15.4125 14.1726 15.4125C12.67 15.4123 11.3743 14.9155 10.4257 13.9758C9.47836 13.0374 8.97768 11.7566 8.97768 10.2718C8.97768 10.1207 8.9835 9.96913 8.99374 9.81742C8.99508 9.8091 8.99636 9.80025 8.99769 9.79193C9.0041 9.75207 9.00625 9.71256 9.0045 9.67374C9.17152 7.78703 10.1172 5.87651 11.6569 4.35141C13.361 2.6633 15.5422 1.69516 17.6412 1.69516C19.1437 1.69516 20.4394 2.19194 21.3881 3.13187C22.3354 4.07028 22.8361 5.35109 22.8361 6.83587C22.8361 8.91171 21.8596 11.0697 20.157 12.7563C18.4528 14.4444 16.272 15.4126 14.1733 15.4125Z"
                fill="currentColor"/>
          </g>
          <defs>
            <clipPath id="clip0_17895_119331">
              <rect width="24" height="24" fill="currentColor"
                    transform="translate(0 0.5)"/>
            </clipPath>
          </defs>

      </svg>

  );
}

export default IconSportTennis;
