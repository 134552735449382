import { features } from "../../utils/access.js";
import { useParams } from "react-router-dom";
import usePolicies from "../policies/usePolicies";
import useExperienceContext from "../clubExperience/useExperienceContext.js";
import { useEffect, useState } from "react";

const useHasAccess = (module) => {
  const feature = features[module];
  const {clubPolicies} = useExperienceContext();

  const [allow,setAllow] = useState(false);

  // Club Based Feature filter
  useEffect(()=>{
    if (feature && clubPolicies) {
      const featureSupports = clubPolicies?.club?.featureSupports;
      const isAllowed = featureSupports?.find(
        (feat) => feat?.name == feature?.alias
      );

      if (!!isAllowed?.status) {
        setAllow(true);
      } else {
        setAllow(false);
      }
    }
  },[clubPolicies])

  return allow;
};
export default useHasAccess;
