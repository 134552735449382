import {Box, Button, Stack, Typography, useTheme} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import useLocale from '../../../hooks/localization/useLocale';
import {PackageSliderSlideBenefit} from './PackageSliderSlideBenefit';
import React from 'react';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import useOnboardingContext
  from '../../../hooks/onboarding/useOnboardingContext';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationOn } from '@mui/icons-material';

export function PackageSliderSlide({clubPackage, clubName,experience,handleClickPurchase,notASliderView,height,onLoginSuccess=()=>{}}) {

  const theme = useTheme();
  const {t} = useTranslate();
  const isAuthenticated = useIsAuthenticated();
  const {setIsOpenLoginDialog,setLoginSuccessFnParams} = useOnboardingContext();
  const navigateTo = useNavigate();
  const isPurchased = clubPackage?.isPurchased;
  const benefits = clubPackage?.packageBenefitsList;
  const clubId = clubPackage?.club?.id;
  const {clubId:paramClubId} = useParams();

  function handleLogin() {
    setIsOpenLoginDialog(true);
    setLoginSuccessFnParams({
      successFn:()=>onLoginSuccess(clubPackage)
    })
  }

  return (
    <Box
      sx={{
        position: "relative",
        border: `1px solid ${theme.palette.divider}`,
        padding: "22px",
        borderRadius: "12px",
        height: height??"325px",
        display: "flex",
        alignItems: "spaceBetween",
        flexDirection: "column",
        overflow: "hidden",
        cursor: notASliderView?'pointer': "grab",
        ...(isPurchased && {
          cursor: "pointer",
        }),
      }}
    >
      {
        experience==='COMPANY' && clubName && (
        <Stack
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          mt={"-14px !important"}
        >
          <LocationOn color="primary" />
          <Typography variant="body2">{clubName}</Typography>
        </Stack>
      )}
      <Stack
        spacing={"6px"}
        marginBottom={'1.5rem'}
        onClick={() => {
          if (isAuthenticated && isPurchased) {
            handleClickPurchase(clubPackage);
          }
        }}
      >
        <Typography
          variant={"h6"}
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {clubPackage?.name}
        </Typography>
        <Typography
          variant={"body2"}
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {clubPackage?.description}
        </Typography>
        <Stack spacing={"12px"} pt={0.5}>
          {benefits?.map((benefit, index) => {
            return <PackageSliderSlideBenefit key={index} benefit={benefit} />;
          })}
        </Stack>
      </Stack>
      <Button
        fullWidth
        variant={isPurchased ? "outlined" : "soft"}
        color={"primary"}
        onClick={() => {
          if (isAuthenticated) {
            if (isPurchased && experience!='COMPANY') {
              navigateTo(
                `/club/${clubId ?? paramClubId}/settings/packages/detail?id=${clubPackage?.userPackageId}`
              );
            } else {
              handleClickPurchase(clubPackage);
            }
          }
          if (!isAuthenticated) {
            handleLogin();
          }
        }}
        sx={{ marginTop: "auto" }}
      >
        {isAuthenticated &&
          !isPurchased &&
          t("packages.purchase.action.purchase")}
        {isAuthenticated &&
          isPurchased &&
          t("packages.purchase.action.currentPackage")}
        {!isAuthenticated && t("packages.purchase.action.login")}
      </Button>
    </Box>
  );
}
