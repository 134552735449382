import {IconButton, useTheme} from '@mui/material';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import React from 'react';

export function SwiperButton({
                               direction = 'prev' || 'next',
                               onClick,
                               disabled,
                             }) {

  const theme = useTheme();
  const isPrev = direction === 'prev';
  const isNext = direction === 'next';

  return <IconButton
      size={'small'}
      disabled={disabled}
      onClick={onClick}
      color={'primary'}
      sx={{
        border: `1px solid ${disabled ?
            theme.palette.divider :
            theme.palette.primary.main}`,
        backgroundColor: '#fff !important',
      }}
  >
    {
        isPrev &&
        <ChevronLeft/>
    }
    {
        isNext &&
        <ChevronRight/>
    }
  </IconButton>;
}