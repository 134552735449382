import {Stack, Tooltip, Typography, useTheme} from '@mui/material';
import {InfoOutlined} from '@mui/icons-material';
import { useTranslate } from '@tolgee/react';

export function PackageBenefitDetailsRow({
                                           title,
                                           description,
                                           value,
                                           tooltip,
                                           supportedSports,
                                           showDivider = true,
                                         }) {

  const theme = useTheme();
  const {t} = useTranslate();
  return (
      <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          py={'20px'}
          sx={{
            ...(showDivider) &&
            {borderBottom: `1px solid ${theme.palette.divider}`},
          }}
      >
        <Stack paddingRight={'16px'}>
          <Stack direction={'row'} spacing={.5} alignItems={'center'}>
            <Typography>
              {title} {supportedSports && `(${supportedSports})` }
            </Typography>
          </Stack>
          <Typography variant={'body2'}>
            {title === t('packages.purchase.details.bookingDiscount.title') ? t('package.booking.discount.info') : description}
          </Typography>
        </Stack>

        <Typography fontWeight={600}>
          {value}
        </Typography>

      </Stack>
  );
}