import {Skeleton, Typography} from '@mui/material';

function BookingsPaymentTypeDetails({paymentType, isLoading}) {
  if (isLoading) {
    return <Skeleton width={'256px'} height={'24px'}/>;
  }
  if (!isLoading) {
    return <Typography variant={'body1'} textTransform={'capitalize'}>
      {paymentType}
    </Typography>;
  }
}

export default BookingsPaymentTypeDetails;
