import axios from 'axios';

export const customersPackagesList_GET = (clubId, cbSuccess, cbFailure) => {
  return async () => {
    try {
      const response = await axios.get(`/customers/packages/list/${clubId}`);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

export const customersPackagesDetail_GET = (packageId, cbSuccess, cbFail) => {
  return async () => {
    try {
      const response = await axios.get(`/customers/packages/detail/${packageId}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const customersPackagesPurchasedDetail_GET = (packageId, cbSuccess, cbFail) => {
  return async () => {
    try {
      const response = await axios.get(`/customers/packages/purchased/detail/${packageId}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const customersPackagesPurchasePaymentStatus_GET = (orderId, cbSuccess, cbFail) => {
  return async () => {
    try {
      const response = await axios.get(`/customers/packages/purchase/payment-status/${orderId}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const customersPackagesPurchased_GET = (clubId,type, cbSuccess, cbFail) => {
  return async () => {
    try {
      const response = await axios.get(`customers/packages/purchased/${clubId}?type=${type}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const customersPackagesPurchase_POST = (body, cbSuccess, cbFail) => {
  return async () => {
    try {
      const response = await axios.post(`/customers/packages/purchase`, body);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const customersPackagesPurchaseRetryPayment_POST = (orderId, redirectUrl, cbSuccess, cbFail) => {
  return async () => {
    try {
      const response = await axios.post(`/customers/packages/purchase/retry/${orderId}`, {redirectUrl});
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};