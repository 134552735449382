import {statusError, statusLoading, statusSuccess} from './status';
import {
  BOOKINGS_AVAILABLE_COURTS_GET_FAILURE,
  BOOKINGS_AVAILABLE_COURTS_GET_LOADING,
  BOOKINGS_AVAILABLE_COURTS_GET_SUCCESS,
  BOOKINGS_CANCEL_POST_FAILURE,
  BOOKINGS_CANCEL_POST_LOADING,
  BOOKINGS_CANCEL_POST_SUCCESS,
  BOOKINGS_CUSTOMERS_GET_FAILURE,
  BOOKINGS_CUSTOMERS_GET_LOADING,
  BOOKINGS_CUSTOMERS_GET_SUCCESS,
  BOOKINGS_TOKEN_GET_FAILURE,
  BOOKINGS_TOKEN_GET_LOADING,
  BOOKINGS_TOKEN_GET_SUCCESS,
  CUSTOMERS_BOOKINGS_CANCEL_POST_FAILURE,
  CUSTOMERS_BOOKINGS_CANCEL_POST_LOADING,
  CUSTOMERS_BOOKINGS_CANCEL_POST_SUCCESS,
  CUSTOMERS_BOOKINGS_CREATE_POST_FAILURE,
  CUSTOMERS_BOOKINGS_CREATE_POST_LOADING,
  CUSTOMERS_BOOKINGS_CREATE_POST_SUCCESS,
  CUSTOMERS_BOOKINGS_CUSTOMERS_GET_FAILURE,
  CUSTOMERS_BOOKINGS_CUSTOMERS_GET_LOADING,
  CUSTOMERS_BOOKINGS_CUSTOMERS_GET_SUCCESS,
  CUSTOMERS_BOOKINGS_SINGLE_GET_FAILURE,
  CUSTOMERS_BOOKINGS_SINGLE_GET_LOADING,
  CUSTOMERS_BOOKINGS_SINGLE_GET_SUCCESS,
} from '../constants';
import axios from 'axios';

export const customersBookingsCreate_POST = (body, onSuccess, onFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CUSTOMERS_BOOKINGS_CREATE_POST_LOADING));
      const response = await axios.post('v2/customers/bookings/create', body);
      dispatch(
          statusSuccess(CUSTOMERS_BOOKINGS_CREATE_POST_SUCCESS, response.data));
      onSuccess(response);
    } catch (e) {
      dispatch(statusError(CUSTOMERS_BOOKINGS_CREATE_POST_FAILURE, e));
      onFail(e);
    }
  };
};

export const customersBookingsCustomers_GET = (
    searchTerm, cbSuccess, cbFailure,usage,sport) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CUSTOMERS_BOOKINGS_CUSTOMERS_GET_LOADING));
      const response = await axios.post(
          `customers/booking/customers?usage=${usage}&sport=${sport??''}`,{searchTerm});
      dispatch(statusSuccess(CUSTOMERS_BOOKINGS_CUSTOMERS_GET_SUCCESS,
          response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(CUSTOMERS_BOOKINGS_CUSTOMERS_GET_FAILURE, e));
    }
  };
};

export const customersSearchInterConnected_GET = (
  searchTerm,clubId, cbSuccess, cbFailure,type,usage,sport) => {
return async (dispatch) => {
  try {
    dispatch(statusLoading(CUSTOMERS_BOOKINGS_CUSTOMERS_GET_LOADING));
    const response = await axios.post(
        `/customers/suggested-players?type=${type ?? ''}&usage=${usage}&sport=${sport}`,{searchTerm, clubId});
    dispatch(statusSuccess(CUSTOMERS_BOOKINGS_CUSTOMERS_GET_SUCCESS,
        response.data));
    cbSuccess(response);
  } catch (e) {
    cbFailure(e);
    dispatch(statusError(CUSTOMERS_BOOKINGS_CUSTOMERS_GET_FAILURE, e));
  }
};
};
export const customersBookingsSingle_GET = (
    bookingID, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CUSTOMERS_BOOKINGS_SINGLE_GET_LOADING));
      const response = await axios.get(
          `customers/bookings/single/${bookingID}`);
      dispatch(
          statusSuccess(CUSTOMERS_BOOKINGS_SINGLE_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(CUSTOMERS_BOOKINGS_SINGLE_GET_FAILURE, e));
    }
  };
};

export const customersBookingsCancel_POST = (bookingId, onSuccess, onFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CUSTOMERS_BOOKINGS_CANCEL_POST_LOADING));
      const response = await axios.post(
          `customers/booking/cancel/${bookingId}`);
      dispatch(
          statusSuccess(CUSTOMERS_BOOKINGS_CANCEL_POST_SUCCESS, response.data));
      onSuccess(response);
    } catch (e) {
      dispatch(statusError(CUSTOMERS_BOOKINGS_CANCEL_POST_FAILURE, e));
      onFail(e);
    }
  };
};

export const customersBookingsAvailableCourts_POST = (body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_AVAILABLE_COURTS_GET_LOADING));
      const response = await axios.post(`customers/bookings/available-courts`, body);
      dispatch(statusSuccess(BOOKINGS_AVAILABLE_COURTS_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(BOOKINGS_AVAILABLE_COURTS_GET_FAILURE, e));
    }
  };
};
export const customersBookingCheckout_POST = (
    bookingId, redirectUrl, wallet = false, type = '', onSuccess, onFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('customers/booking-checkout',
          {bookingId, redirectUrl, wallet, type});
      onSuccess(response);
    } catch (e) {
      onFail(e);
    }
  };
};

export const bookingsToken_GET = (token, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_TOKEN_GET_LOADING));
      const response = await axios.get(`bookings/token/${token}`);
      dispatch(statusSuccess(BOOKINGS_TOKEN_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_TOKEN_GET_FAILURE, e));
    }
  };
};

export const recurrBookingsToken_GET = (token, cbSuccess, cbFailure) => {
  return async () => {
    try {
      const response = await axios.get(`recurrbookings/token/${token}`);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

export const bookingsTokenCheckout_POST = (
    token, redirectUrl, useWallet, onSuccess, onFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`bookings/token/checkout/${token}`,
          {
            redirectUrl: redirectUrl,
            wallet: useWallet,
          });

      onSuccess(response);
    } catch (e) {

      onFail(e);
    }
  };
};

export const recurrBookingsTokenCheckout_POST = (
    token, redirectUrl,isPayingWithWallet, onSuccess, onFail) => {
  return async () => {
    try {
      const response = await axios.post(`recurrbookings/token/checkout/${token}`,
          {
            redirectUrl: redirectUrl,
            wallet: isPayingWithWallet
          });
      onSuccess(response);
    } catch (e) {
      onFail(e);
    }
  };
};


export const scheduleAvailableCourtsCustomer_GET = (
    body, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_CUSTOMERS_GET_LOADING));
      const response = await axios.post(`customers/bookings/available-courts`,
          body);
      dispatch(statusSuccess(BOOKINGS_CUSTOMERS_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_CUSTOMERS_GET_FAILURE, e));
    }
  };
};

export const bookingsNotify_POST = (
  bookingId, userId, cbSuccess, cbFailure) => {
return async (dispatch) => {
 try {
   const response = await axios.post(`/bookings/notify`, {
     bookingId,
     userId,
   });
   cbSuccess(response);
 } catch (e) {
   cbFailure(e);
 }
};
};

export const getBookingsWalletPaymentOnDetail = (
  body, cbSuccess, cbFailure) => {
return async (dispatch) => {
 try {
   const response = await axios.post(`/v2/customers/bookings/calculate-wallet-price`, 
     body
   );
   cbSuccess(response);
 } catch (e) {
   cbFailure(e);
 }
};
};

export const getRecurringBookingsWalletPaymentOnDetail = (body, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/customers/recurring-bookings/calculate-wallet-price`, body);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

