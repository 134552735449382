import {Alert, Skeleton, Stack, Typography, useTheme} from '@mui/material';
import {Circle} from '@mui/icons-material';
import { useTranslate } from '@tolgee/react';

function BookingsCourtDetails({
                                courtName,
                                courtSize,
                                courtSport,
                                courtType,
                                courtFeature,
                                showCourtDetails = true,
                                isLoading,
                                isMeshlinkenabled
                              }) {
  const theme = useTheme();
  const {t} = useTranslate();
  return <>
    {
        isLoading && <Skeleton width={'256px'} height={'24px'}/>
    }
    {
        !isLoading &&
        <Stack>
          <Typography variant={'body1'} sx={{userSelect: 'none'}}>
            {courtName}
          </Typography>
          {
              showCourtDetails &&
              <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
                <Typography variant={'body2'}>{courtSport}</Typography>
                {
                    courtSize &&
                    <>
                      <Circle
                          sx={{
                            fill: theme.palette.text.secondary,
                            width: '4px',
                          }}/>
                      <Typography variant={'body2'}>{courtSize}</Typography>
                    </>
                }
                {
                    courtType &&
                    <>
                      <Circle
                          sx={{
                            fill: theme.palette.text.secondary,
                            width: '4px',
                          }}/>
                      <Typography variant={'body2'}>{courtType}</Typography>
                    </>
                }

                {
                    courtFeature && <>
                      <Circle
                          sx={{fill: theme.palette.text.secondary, width: '4px'}}/>
                      <Typography variant={'body2'}>{courtFeature}</Typography>
                    </>
                }
              </Stack>
          }
          {
            isMeshlinkenabled &&
            <Alert severity='info'>
              {t('meshlink.lights.message')}
            </Alert>
          }
        </Stack>
    }
  </>;
}

export default BookingsCourtDetails;
;
