import {createContext, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/es';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/es';
import moment from 'moment-timezone';
import {useDispatch} from 'react-redux';
import {customersSelf_GET} from '../vendor/redux/actions/player';
import {APP_DEFAULT_LOCALE} from '../constants/localization';
import {USER_LOGIN} from '../vendor/redux/constants';
import useIsAuthenticated from '../hooks/access/useIsAuthenticated';

export const LocaleContext = createContext();

export default function ProviderLocale({children}) {

  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const localStorageLocale = JSON.parse(localStorage.getItem('locale'));

  const [locale, setLocale] = useState(null);

  useEffect(() => {
    //if signed in fetch user language from server
    if (isAuthenticated) {
      dispatch(customersSelf_GET(cbSuccess, cbFail));

      function cbSuccess(res) {
        console.log('customersSelf_GET Success', res);
        //get language from server
        const data = res?.data?.data;
        const userLanguageFromServer = data?.language || 'en';
        //set app locale
        setLocale(userLanguageFromServer);
      }

      function cbFail(e) {
        console.log('customersSelf_GET Fail', e);
        setLocale(APP_DEFAULT_LOCALE); //fallback
      }
    } else {
      //if not signed in use local storage or app default value
      setLocale(localStorageLocale || APP_DEFAULT_LOCALE);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (locale) {
      //update existing user object in local storage and redux
      const userLocalStorage = JSON.parse(localStorage.getItem('user'));
      if (userLocalStorage){
        userLocalStorage.userObj.language = locale;
        localStorage.setItem('user', JSON.stringify(userLocalStorage));
        dispatch({type: USER_LOGIN, payload: userLocalStorage});
      }
    }
  }, [locale]);

  useEffect(() => {
    if (locale) {
      dayjs.locale(locale);
      moment.locale(locale);
    }

  }, [locale]);

  if (locale) {
    return (
        <LocaleContext.Provider value={{locale, setLocale}}>
          {children}
        </LocaleContext.Provider>
    );
  }

}
