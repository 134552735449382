import {Box, LinearProgress, Paper} from '@mui/material';
import {LAYOUT_LOGIN__HEADER__TOP__HEIGHT} from '../../constants/ui';
import SiteFooter from '../../components/_Global/Site/SiteFooter';
import SiteLanguageSwitcher
  from '../../components/_Global/Site/SiteLanguageSwitcher';
import {useTheme} from '@emotion/react';
import { useParams } from 'react-router-dom';

function LayoutOnboarding({
                            width = '564px',
                            isLoading = false,
                            actionSlot,
                            children,
                            boxPadding = '20px',
                          }) {
  const theme = useTheme();
  const {clubId} = useParams();

  return (
      <Box>
        {!clubId &&  <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: LAYOUT_LOGIN__HEADER__TOP__HEIGHT,
              padding: '0 clamp(0.50rem, -1.00rem + 7.50vw, 2.00rem)',
              backgroundColor:theme.palette.background.neutral,
            }}
        >
        <SiteLanguageSwitcher/>
        </Box>}
        <Box
            sx={{
              height: `calc(100vh - ${LAYOUT_LOGIN__HEADER__TOP__HEIGHT})`,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
        >
          <Box
              sx={{
                display: 'flex',
                alignItems: clubId ? 'initial' : 'center',
                justifyContent: 'center',
                flexGrow: 1,
                padding: `${clubId ? '90px' : '32px'} clamp(0.50rem, -1.00rem + 7.50vw, 2.00rem)`,
              }}
          >
            <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  maxWidth: width,
                  width: '100%',
                }}
            >
              <Paper
                  variant={'outlined'}
                  sx={{
                    maxWidth: width,
                    width: '100%',
                    padding: boxPadding,
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: '12px',
                  }}
              >
                {
                    isLoading && <LinearProgress
                        sx={{
                          position: 'absolute',
                          left: '0',
                          top: '0',
                          width: '100%',
                        }}
                    />
                }
                {children}
              </Paper>
              {
                  actionSlot &&
                  <Box
                      mt={3}
                  >
                    {actionSlot}
                  </Box>
              }
            </Box>
          </Box>
          <SiteFooter/>
        </Box>
      </Box>
  );
}

export default LayoutOnboarding;
