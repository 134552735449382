import {createContext, useEffect, useState} from 'react';

export const OnboardingContext = createContext();

function ProviderOnboarding({children}) {

  const [isOpenLoginDialog, setIsOpenLoginDialog] = useState(false);
  const [isOpenForgotDialog, setIsOpenForgotDialog] = useState(false);
  const [loginDialogDescription, setLoginDialogDescription] = useState('');
  const [isOpenRegisterDialog, setIsOpenRegisterDialog] = useState(false);

  const [willShowForgotPassword, setWillShowForgotPassword] = useState(false);
  const [willShowLoginDialog, setWillShowLoginDialog] = useState(false);
  const [willShowRegisterDialog, setWillShowRegisterDialog] = useState(false);
  const [registerSuccessFnParams, setRegisterSuccessFnParams] = useState({successFn:()=>{}});
  const [onRegisterDialogCloseParams, setOnRegisterDialogCloseParams] = useState({successFn:()=>{}});

  const [loginSuccessFnParams, setLoginSuccessFnParams] = useState({successFn:()=>{}});
  const [onLoginDialogCloseParams, setOnLoginDialogCloseParams] = useState({successFn:()=>{}});
  const [isOpenUserRatingDialog, setIsOpenUserRatingDialog] = useState(false);
  const [ratingDialogDecription, setRatingDialogDescription] = useState('');
  const [ratingSuccessFn,setRatingSuccessFn] = useState({successFn:()=>{}});
  const [preFillEmail,setPreFillEmail] = useState('');

  const loginSuccess=()=>{
    
    if(loginSuccessFnParams){
      loginSuccessFnParams?.successFn();
      setLoginSuccessFnParams({successFn:()=>{}});
    }
  }
  
  const onLoginDialogClose=()=>{
    if(onLoginDialogCloseParams){
      onLoginDialogCloseParams?.successFn();
    }
  }

  const registerSuccess=()=>{
    
    if(registerSuccessFnParams){
      registerSuccessFnParams?.successFn();
      setRegisterSuccessFnParams({successFn:()=>{}});
    }
  }
  
  const onSubmitRatingSuccess=()=>{
    ratingSuccessFn.successFn();
    setRatingSuccessFn({successFn:()=>{}})
  }
  
  const onRegisterDialogClose=()=>{
    
    if(onRegisterDialogCloseParams){
      onRegisterDialogCloseParams?.successFn();
    }
  }

  useEffect(() => {
    if (willShowForgotPassword) {
      setIsOpenLoginDialog(false);
    }
  }, [willShowForgotPassword]);

  useEffect(() => {
    if (willShowLoginDialog) {
      setIsOpenForgotDialog(false);
    }
  }, [willShowLoginDialog]);

  useEffect(() => {
    if (willShowRegisterDialog) {
      setIsOpenLoginDialog(false);
    }
  }, [willShowRegisterDialog]);
  
  useEffect(() => {
    if (!isOpenLoginDialog) {
      setPreFillEmail('');
    }
  }, [isOpenLoginDialog]);

  useEffect(()=>{

    if(!isOpenRegisterDialog){
      setPreFillEmail('');
    }
  },[isOpenRegisterDialog])

  return (
      <OnboardingContext.Provider
          value={{
            isOpenLoginDialog,
            setIsOpenLoginDialog,
            loginDialogDescription, 
            setLoginDialogDescription,
            loginSuccessFn:()=>loginSuccess(),
            loginSuccessFnParams,
            setLoginSuccessFnParams,
            onLoginDialogCloseFn:()=>onLoginDialogClose(),
            onLoginDialogCloseParams,
            setOnLoginDialogCloseParams,

            isOpenForgotDialog,
            setIsOpenForgotDialog,
            isOpenRegisterDialog,
            setIsOpenRegisterDialog,
            registerSuccessFn:()=>registerSuccess(),
            registerSuccessFnParams,
            setRegisterSuccessFnParams,
            onRegisterDialogCloseFn:()=>onRegisterDialogClose(),
            onRegisterDialogCloseParams,
            setOnRegisterDialogCloseParams,
            willShowForgotPassword,
            setWillShowForgotPassword,
            willShowLoginDialog,
            setWillShowLoginDialog,
            willShowRegisterDialog,
            setWillShowRegisterDialog,
            preFillEmail,
            setPreFillEmail,
            isOpenUserRatingDialog,
            setIsOpenUserRatingDialog,
            ratingDialogDecription, 
            setRatingSuccessFn,
            setRatingDialogDescription,
            onRatingSuccess:()=>onSubmitRatingSuccess()
          }}>
        {children}
      </OnboardingContext.Provider>
  );
}

export default ProviderOnboarding;