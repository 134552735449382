import dayjs from 'dayjs';

export function getHumanizedDuration(duration, t) {
  if (duration <= 1) {
    return `${duration} ${t('global.types.dateTime.month')}`;
  }

  if (duration > 1) {
    return `${duration} ${t('global.types.dateTime.months')}`;
  }
}

export function getIsAgeEligible(userDob, packageMinAge, packageMaxAge) {

  if (!packageMinAge || !packageMaxAge) {
    return true;
  }
  
  const dob = dayjs(userDob, 'YYYY-MM-DD');
  const currentDate = dayjs();
  const age = currentDate.diff(dob, 'year');

  if (age < packageMinAge) {
    return false;
  }

  if (age > packageMaxAge) {
    return false;
  }

  return true;

}