function IconWallet({ fill }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_17855_187975)">
        <path
          d="M19.0926 10.0849V7.27389C19.0926 6.32073 18.3533 5.54556 17.4191 5.47039L14.8087 0.910803C14.5668 0.489105 14.1761 0.187704 13.7087 0.0625495C13.2435 -0.0617525 12.757 0.00353057 12.3407 0.24587L3.39181 5.45573H1.82012C0.817394 5.45573 0.00195312 6.27113 0.00195312 7.27389V18.1828C0.00195312 19.1855 0.817352 20.001 1.82012 20.001H17.2744C18.2772 20.001 19.0926 19.1856 19.0926 18.1828V15.3718C19.6205 15.1836 20.0017 14.6838 20.0017 14.092V11.3647C20.0017 10.7729 19.6205 10.2731 19.0926 10.0849ZM16.3628 5.45573H12.401L15.3724 3.72581L16.3628 5.45573ZM14.9207 2.93692L10.5943 5.45573H8.79974L14.472 2.15323L14.9207 2.93692ZM12.7983 1.03153C13.0038 0.911229 13.2439 0.879269 13.4734 0.940546C13.7056 1.00268 13.8991 1.15272 14.0194 1.36267L14.0204 1.36433L6.99307 5.45573H5.1986L12.7983 1.03153ZM18.1835 18.1828C18.1835 18.6839 17.7756 19.0919 17.2744 19.0919H1.82012C1.31899 19.0919 0.911058 18.6839 0.911058 18.1828V7.27389C0.911058 6.77277 1.31899 6.36483 1.82012 6.36483H17.2744C17.7756 6.36483 18.1835 6.77277 18.1835 7.27389V10.0011H15.4563C13.9524 10.0011 12.729 11.2245 12.729 12.7284C12.729 14.2322 13.9524 15.4556 15.4563 15.4556H18.1835V18.1828ZM19.0926 14.092C19.0926 14.3427 18.8889 14.5465 18.638 14.5465H15.4563C14.4535 14.5465 13.6381 13.7311 13.6381 12.7284C13.6381 11.7256 14.4535 10.9102 15.4563 10.9102H18.638C18.8888 10.9102 19.0926 11.1139 19.0926 11.3647V14.092Z"
          fill={fill ?? "#0537C6"}
        />
        <path
          d="M15.4559 11.8193C14.9548 11.8193 14.5469 12.2273 14.5469 12.7284C14.5469 13.2295 14.9548 13.6375 15.4559 13.6375C15.9571 13.6375 16.365 13.2295 16.365 12.7284C16.365 12.2273 15.9571 11.8193 15.4559 11.8193Z"
          fill={fill ?? "#0537C6"}
        />
      </g>
      <defs>
        <clipPath id="clip0_17855_187975">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default IconWallet;
