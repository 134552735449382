export const getDayTranslation = (value, t) => {

  switch (value) {
    case 'Monday':
      return t('days.monday');
    case 'Tuesday':
      return t('days.tuesday');
    case 'Wednesday':
      return t('days.wednesday');
    case 'Thursday':
      return t('days.thursday');
    case 'Friday':
      return t('days.friday');
    case 'Saturday':
      return t('days.saturday');
    case 'Sunday':
      return t('days.sunday');
  }
};

export function getSortedDaysTimings(timingsArray) {
  const MON = timingsArray.find(t => t.day === 'Monday');
  const TUE = timingsArray.find(t => t.day === 'Tuesday');
  const WED = timingsArray.find(t => t.day === 'Wednesday');
  const FRI = timingsArray.find(t => t.day === 'Friday');
  const THU = timingsArray.find(t => t.day === 'Thursday');
  const SAT = timingsArray.find(t => t.day === 'Saturday');
  const SUN = timingsArray.find(t => t.day === 'Sunday');
  return [MON, TUE, WED, THU, FRI, SAT, SUN];
}

export function getTotalDaysOfMonth(month) {
  if (month === 0) return 31;
  if (month === 1) return 31;
  if (month === 2) return 28;
  if (month === 3) return 31;
  if (month === 4) return 30;
  if (month === 5) return 31;
  if (month === 6) return 30;
  if (month === 7) return 31;
  if (month === 8) return 31;
  if (month === 9) return 30;
  if (month === 10) return 31;
  if (month === 11) return 30;
}
