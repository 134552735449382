import { Alert, AlertTitle } from '@mui/material'
import React from 'react'
import LockIcon from '@mui/icons-material/Lock';
import { useTranslate } from '@tolgee/react';

function MeshlinkLightAndCode({pinCode, customMessage, mt=2}) {
  const {t} = useTranslate();
  if(pinCode || customMessage) {
    return (
      <Alert icon={<LockIcon sx={{width: '39px', height: '48px'}} />} 
      severity='info' sx={{mb: 2, mt,alignItems: 'center'}}>
         {pinCode && <AlertTitle>{t('booking.detail.facilityMsg')} {pinCode}</AlertTitle>}
          {customMessage || ''}
          
      </Alert>
    )
  }
  return ''
  
}

export default MeshlinkLightAndCode