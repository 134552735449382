import usePolicies from '../policies/usePolicies';
import {APP_DEFAULT_CURRENCY_SYMBOL} from '../../constants/localization';

function useDefaults() {

  const policies = usePolicies();
  const currencySymbol = policies?.club?.company?.CompanyLocalize?.currency?.symbol || APP_DEFAULT_CURRENCY_SYMBOL;

  return {currencySymbol};
}

export default useDefaults;
