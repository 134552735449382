import {useEffect, useState} from 'react';

function useBookingsRecurringSingleDetails(booking,recurringBookingId,bookingSingleId){

  const [details, setDetails] = useState(null);


  useEffect(() => {
    if (booking) {

      const singleBookingIDX =
          booking.courtBookings.findIndex((booking) => {
            return booking.id === bookingSingleId;
          });

      const singleBooking =
          booking.courtBookings.find((booking) => {
            return booking.id === bookingSingleId;
          });

      const singleBookingCourtId = singleBooking.courtId;

      const court = booking.recurrBookingCourts.find((el)=>{
        return el.courtId === singleBookingCourtId;
      })

      console.log('single booking', singleBooking)

      setDetails({
        clubId: singleBooking.court.clubId,
        recurrenceId: recurringBookingId,
        singleBooking: singleBooking,
        singleBookingId: singleBooking.id,
        title: booking.name,
        recurrenceType: booking.type,
        recurrenceEndDate: booking.endDate,
        court: court,
        paymentType: booking.paymentType,
        totalBookings: booking.courtBookings.length,
        bookingNumber: singleBookingIDX + 1,
        bookingDate: singleBooking.date,
        bookingStartTime: singleBooking.startTime,
        bookingEndTime: singleBooking.endTime,
        bookingStatus: singleBooking.status,
        players: booking.recurrBookingParticipants,
        recurringExtras: booking?.recurringExtras,
        meshlink: singleBooking?.meshLink
      });

    }
  }, [booking]);

  return [details];

}

export default useBookingsRecurringSingleDetails;
