
export default function TableOverride(theme) {
  return {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          '.MuiTableRow-root': {
            '&:hover': {
              backgroundColor: theme.palette.grey[100],
            },
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            position: 'relative',
            background: '#fff',
            '&:hover': {
              background: theme.palette.grey['50'],
            },
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },




  };
}
