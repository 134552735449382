import {Button, Stack, TextField} from '@mui/material';
import {TransitionSlide} from '../../vendor/mui/transitions';
import {useTranslate} from '@tolgee/react';
import {Controller, useForm} from 'react-hook-form';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import {validate} from 'email-validator';
import {useState} from 'react';
import DialogBase from '../../components/_Global/Dialogs/DialogBase';
import ForgotPasswordSubmitSuccessAlert
  from '../../components/Onboarding/ForgotPassword/ForgotPasswordSubmitSuccessAlert';
import {ERR_NETWORK_ERROR} from '../../constants/errors';
import { forgotPasswordEvents } from '../../utils/analyticsEvents';

function DialogForgotPassword({
                                isOpenDialog,
                                setIsOpenDialog,
                                setWillShowForgotPassword,
                                setWillShowLoginDialog,
                                willShowLoginDialog,
                                showLoginDialog,
                              }) {

  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: {errors, isSubmitting},
  } = useForm();

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleForgotPasswordSubmit = () => {
    handleSubmit(
        async ({email}) => {
          const url = 'customers/forget-password';
          try {
            const response = await axios.post(url, {email});
            enqueueSnackbar(`Password reset email has been sent.`,
                {variant: 'success'});
            setSubmitSuccess(true);
            forgotPasswordEvents['success']();
          } catch (e) {
            const errorMsg = e?.response?.data?.data || ERR_NETWORK_ERROR;
            enqueueSnackbar(t(errorMsg), {variant: 'error'});
            setSubmitSuccess(false);
            forgotPasswordEvents['failure']();
          }
        },
        () => {
          enqueueSnackbar(t('alerts.globalErrorMessage'), {variant: 'error'});
          setSubmitSuccess(false);
        },
    )();
  };

  const handleEnterKeySubmit = (e) => {
    e.key === 'Enter' && handleForgotPasswordSubmit();
  };

  function handleOnExited() {
    if (willShowLoginDialog) {
      showLoginDialog();
    }
    setSubmitSuccess(false);
    reset();
  }

  function handleOnEnter() {
    if (willShowLoginDialog) {
      setWillShowLoginDialog(false);
    }
  }

  function handleCloseDialog() {
    setWillShowForgotPassword(false);
    setIsOpenDialog(false);
  }

  function handleBackBtnClick() {
    setWillShowForgotPassword(false);
    setWillShowLoginDialog(true);
  }

  return (
      <DialogBase
          isOpen={isOpenDialog}
          onClose={handleCloseDialog}
          dividers={false}
          transitionComponent={TransitionSlide}
          onExited={handleOnExited}
          onEnter={handleOnEnter}
          title={t('authFlow.forgotPassword.heading')}
          description={t('authFlow.forgotPassword.subHeading')}
          contentSlot={
            <>
              {
                  !submitSuccess &&
                  <Controller name={'email'}
                              control={control}
                              defaultValue={''}
                              rules={{
                                required: {
                                  value: true,
                                  message: t('authFlow.fields.errorEmail'),
                                },
                                validate: {
                                  invalid: (value) => {
                                    return validate(value) ||
                                        t('validation.emailValidation');
                                  },
                                },
                              }}
                              render={({field}) => {
                                return (
                                    <TextField
                                        sx={{mt: 1}}
                                        autoFocus
                                        fullWidth
                                        required
                                        size="medium"
                                        type="email"
                                        name="email"
                                        label={t('authFlow.fields.labelEmail')}
                                        placeholder="John@email.com"
                                        error={!!errors['email']}
                                        helperText={!!errors['email'] &&
                                            errors['email'].message}
                                        onKeyDown={handleEnterKeySubmit}
                                        {...field}
                                    />
                                );
                              }}
                  />
              }
              {
                  submitSuccess &&
                  <ForgotPasswordSubmitSuccessAlert email={getValues(
                      'email')}/>
              }
              <Stack
                  direction="row"
                  spacing={1}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  mt={2}
              >
                <Button
                    size={'large'}
                    variant="outlined"
                    onClick={handleBackBtnClick}
                >
                  {t('buttons.backButton')}
                </Button>
                {
                    !submitSuccess &&
                    <Button
                        size={'large'}
                        variant="contained"
                        onClick={handleForgotPasswordSubmit}
                        disabled={isSubmitting || submitSuccess ||
                            errors['email']}
                    >
                      {t('buttons.linkButton')}
                    </Button>
                }
              </Stack>
            </>
          }
          dialogStyles={{
            '.MuiDialogContent-root':{
              mt:1
            }
          }}
      />
  );
}

export default DialogForgotPassword;
