import {Box, Stack, Typography} from '@mui/material';

function PageHeaderClubExperience({
                                    pageTitle,
                                    mb=3,
                                    breadcrumbsSlot: BreadcrumbsSlot,
                                    actionsSlot: ActionsSlot,
                                  }) {
  return (
      <Box sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        mb,
      }}>
        <Stack>
          {BreadcrumbsSlot}
          <Typography variant={'h5-bold'}>
            {pageTitle}
          </Typography>
        </Stack>
        {
            ActionsSlot &&
            <Stack direction={'row'} spacing={1}>
              {ActionsSlot}
            </Stack>
        }
      </Box>
  );
}

export default PageHeaderClubExperience;
