export function formatAddress(street, houseNumber, postalCode, city, country) {
  const address = [];

  (street && houseNumber) && address.push(`${street} ${houseNumber}`);
  postalCode && address.push(postalCode);
  city && address.push(city);
  country && address.push(country);

  return address.join(', ');

}