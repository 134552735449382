import {Box, Chip, Divider, Stack, Tooltip, Typography, useTheme} from '@mui/material';
import {Circle} from '@mui/icons-material';
import {useTranslate} from '@tolgee/react';
import {bookingEvents} from '../../../../utils/analyticsEvents';
import {getLocalizedNum} from '../../../../utils/localization';
import {useSlotSearchV2Context} from '../../ProviderSlotSearchV2';
import useLocale from '../../../../hooks/localization/useLocale';
import useIsMobile from '../../../../hooks/ui/useIsMobile';
import useDefaults from '../../../../hooks/defaults/useDefaults';
import { useLocation } from 'react-router-dom';

export function SlotSearchV2CourtSelectDialogCourtsListItem({court,isLast}) {
  const isMobile = useIsMobile()
  const theme = useTheme();
  const {locale} = useLocale();
  const {currencySymbol} = useDefaults();
  const {t} = useTranslate();
  const location = useLocation();
  const locationState = location?.state;

  const {
    selectedCourt:courtSelected,
    setSelectedCourt,
    setWillNavigateToCreateBooking
  } = useSlotSearchV2Context();

  const selectedCourt = locationState?.selectedCourt ?? courtSelected; // incase of new Booking pop-up

  const isSelected = selectedCourt?.courtId == court?.courtId;

  const {
    courtName,
    courtSport,
    courtType,
    courtFeature,
    courtSize,
    price,
    originalPrice,
    discountApplied
  } = court;

  function handleClickSlot() {
    setSelectedCourt(court);
    setWillNavigateToCreateBooking(true);
    bookingEvents['selectCourtForBooking']();
  }

  return (
    <Box
    px={isMobile ? '5px' : '26px'}
    sx={{
      background:isSelected? 'rgba(202, 62, 222, 0.7)' :'',
      color: isSelected? '#fff':'',
      ':hover':{
          background: isSelected? 'rgba(202, 62, 222, 0.7)':theme.palette.grey[100]
        }
    }}
    >
      <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          sx={{
            width: '100%',
            cursor: 'pointer',
            pt: 2,
            pb: 2,
          }}
          onClick={handleClickSlot}
      >
        <Stack>
          <Typography>
            {courtName}
          </Typography>
          <Stack 
          direction={'row'} 
          spacing={'4px'} 
          alignItems={'center'}
          sx={{
            '& p, & svg':{
              color:isSelected?'#fff':'',
              fill:isSelected?'#fff':theme.palette.text.secondary
            }
          }}
          >

            <Typography variant={'body2'}>
              {courtSport}
            </Typography>
            {
                courtSize &&
                <>
                  <Circle
                      sx={{
                        fill: theme.palette.text.secondary,
                        width: '4px',
                      }}/>
                  <Typography variant={'body2'}>{courtSize}</Typography>
                </>
            }
            {
                courtType &&
                <>
                  <Circle
                      sx={{
                        fill: theme.palette.text.secondary,
                        width: '4px',
                      }}/>
                  <Typography variant={'body2'}>{courtType}</Typography>
                </>
            }

            {
                courtFeature && <>
                  <Circle
                      sx={{fill: theme.palette.text.secondary, width: '4px'}}/>
                  <Typography variant={'body2'}>{courtFeature}</Typography>
                </>
            }
          </Stack>
        </Stack>

        <Stack alignItems={'center'} spacing={'4px'}>
          {
              discountApplied &&
              <Tooltip
                  arrow
                  placement={"top"}
                  title={t('global.tooltips.packageDiscount')}
              >
                <Typography
                    variant={'body2'}
                    sx={{
                      textDecoration: 'line-through',
                    }}
                >
                  {`${currencySymbol}${originalPrice}`}
                </Typography>
              </Tooltip>
          }
          <Chip
              label={`${currencySymbol}${getLocalizedNum(locale,
                  +price)}`}
              sx={{
                backgroundColor: theme.palette.success.main,
                color: '#fff',
              }}
          />
        </Stack>

      </Stack>
      {/* {
          !isLast &&
          <Divider
          sx={{margin:0}}
          />
        } */}
      </Box>

  );
}
