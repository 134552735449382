import axios from 'axios';

export const customersRecurBookingsSelf_GET = (
    id,bookingSingleId, limit, page, sortBy, orderBy, cbSuccess, cbFailure) => {
  return async () => {
    try {
      const response = await axios.get(
          `/customers/recurbookings/self?id=${id}&limit=${limit}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&courtBookingId=${bookingSingleId}`,{
            headers: {
              'x-clubos-club': '',
              'x-clubos-club-info': ''
            }
          });
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

