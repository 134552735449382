import {Box, Skeleton, Stack} from '@mui/material';
import ExtrasNumberInput from './ExtrasSelectNumberInput';

import {useFormContext} from 'react-hook-form';
import useLocale from '../../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import {getLocalizedNum} from '../../../utils/localization';
import { bookingEvents } from '../../../utils/analyticsEvents';
import useDefaults from '../../../hooks/defaults/useDefaults';

function ExtrasSelect({
  isLoading,
  availableExtras,
}) {
  const {currencySymbol} = useDefaults()
  const {locale} = useLocale();
  dayjs.locale(locale);

  const {
    register,
    setValue,
    getValues,
    formState: {defaultValues},
  } = useFormContext();

  register('extras');

  function addOrIncrementExtras(changedItem) {
    const extras = [...(getValues('extras') || [])];
    const changedExtraIndex = extras.findIndex((extra) => {
      return extra.id === changedItem.id;
    });

    if (changedExtraIndex < 0) {
      //add to extras if not added yet
      extras.push(changedItem);
    } else {
      if (changedItem.count === 0) {
        //delete from extras if count zero
        extras.splice(changedExtraIndex, 1);
      } else {
        //increment count of extra if already exists in extras array
        //and changed value greater than zero
        extras[changedExtraIndex].count = changedItem.count;
      }
    }
    setValue('extras', extras);
    bookingEvents['extraChange']();
    
  }

  const styles = {
    mt: '2px',
    '.wrap': {
      width: '100%', '.title': {marginBottom: '16px'}, '.extra': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:not(:last-child)': {marginBottom: '12px'},
        '.item': {
          display: 'flex',
          color: 'text.secondary',
          '.name': {marginRight: '8px'},
          '.cost': {},
        },
        '.counter': {display: 'flex', alignItems: 'center'},
      },
    },
  };

  return (
      <Box sx={styles}>
        <Box
            sx={{
              display: 'flex',
              width: '100%',
            }}
        >
          <Box className="wrap">
            {
                isLoading &&
                <Stack spacing={1}>
                  {[0, 0].map((el, index) => {
                    return <Stack
                        key={index}
                        direction={'row'}
                    >
                      <Skeleton
                          width={'200px'}
                          sx={{mr: 'auto'}}
                      />
                      <Skeleton width={'80px'}/>
                    </Stack>;
                  })}
                </Stack>
            }
            {
                (!isLoading && availableExtras?.length > 0) &&

                availableExtras.map((extra) => {
                  //add default count if default extras exist
                  let defaultCount = 0;
                  if (defaultValues?.extras?.length > 0) {
                    const defaultExtra = defaultValues.extras.find((item) => {
                      return item.id === extra.id;
                    });
                    if (defaultExtra?.count) {
                      defaultCount = defaultExtra.count;
                    }
                  }
                  return (<div
                      key={extra.id}
                      className={'extra'}
                  >
                    <div className="item">
                      <div className="name">{extra.name}</div>
                      <div
                          className="cost">{`${currencySymbol}${getLocalizedNum(
                          locale, +extra.price)}`}</div>
                    </div>
                    <div className="counter">
                      <ExtrasNumberInput
                          onChange={addOrIncrementExtras}
                          item={extra}
                          defaultCount={defaultCount}
                      />
                    </div>
                  </div>);
                })}
          </Box>
        </Box>
      </Box>);

}

export default ExtrasSelect;
