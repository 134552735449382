function IconMyClubs({ fill }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_17855_187990)">
        <path
          d="M17.9897 18.8278V4.45604H14.9592V0L5.03881 2.78146V6.76484H2.00823V18.8278H0.777344V20H19.2206V18.8278H17.9897ZM3.18039 18.8278V7.937H8.10314V18.8278H3.18039ZM9.2753 18.8278V6.76484H6.21093V3.67019L13.7869 1.54604L13.77 18.8279H9.2753V18.8278ZM14.9591 18.8278V5.6282H16.8175V18.8278H14.9591Z"
          fill={fill??"#0537C6"}
        />
        <path
          d="M5.05664 9.78809H6.2288V12.1457H5.05664V9.78809Z"
          fill={fill??"#0537C6"}
        />
        <path
          d="M5.05664 14.0938H6.2288V16.4514H5.05664V14.0938Z"
          fill={fill??"#0537C6"}
        />
        <path
          d="M10.7227 9.78809H11.8948V12.1457H10.7227V9.78809Z"
          fill={fill??"#0537C6"}
        />
        <path
          d="M10.7227 5.48242H11.8948V7.84006H10.7227V5.48242Z"
          fill={fill??"#0537C6"}
        />
        <path
          d="M10.7227 14.0938H11.8948V16.4514H10.7227V14.0938Z"
          fill={fill??"#0537C6"}
        />
      </g>
      <defs>
        <clipPath id="clip0_17855_187990">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default IconMyClubs;
