import {Button, Stack, useTheme} from '@mui/material';
import DialogLogin from '../../../dialogs/OnBoarding/DialogLogin';
import DialogForgotPassword
  from '../../../dialogs/OnBoarding/DialogForgotPassword';
import {useTranslate} from '@tolgee/react';
import DialogRegister from '../../../dialogs/OnBoarding/DialogRegister';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import DividerVertical from '../Dividers/DividerVertical';
import useOnboardingContext
  from '../../../hooks/onboarding/useOnboardingContext';
import {loginEvents} from '../../../utils/analyticsEvents';
import useIsMobile from '../../../hooks/ui/useIsMobile';

function SiteOnboardingButtons({
                                 size = 'small' || 'medium' || 'large',
                                 variantSignIn = 'outlined',
                                 variantRegister = 'contained',
                                 showDivider = true,
                                 onRegisterSuccess,
                                 disableRegister,
                                 onRegisterFailure,
                                 signInInverted = false,
                                 colorSignin,
                                 onLoginSuccess = () => {
                                 },
                               }) {

  const {t} = useTranslate();
  const isAuthenticated = useIsAuthenticated();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const {
    isOpenLoginDialog,
    setIsOpenLoginDialog,
    loginSuccessFn,
    loginSuccessFnParams,
    setLoginSuccessFnParams,
    onLoginDialogCloseParams,
    setOnLoginDialogCloseParams,
    isOpenForgotDialog,
    setIsOpenForgotDialog,
    isOpenRegisterDialog,
    setIsOpenRegisterDialog,
    registerSuccessFn,
    registerSuccessFnParams,
    onRegisterDialogCloseFn,
    onRegisterDialogCloseParams,
    setOnRegisterDialogCloseParams,
    willShowForgotPassword,
    setWillShowForgotPassword,
    willShowLoginDialog,
    setWillShowLoginDialog,
    willShowRegisterDialog,
    setWillShowRegisterDialog,
    preFillEmail,
    setPreFillEmail,
  } = useOnboardingContext();

  function handleOpenLoginDialog() {
    setIsOpenLoginDialog(true);
    loginEvents['openLoginPopup']();
  }

  function handleOpenRegisterDialog() {
    setIsOpenRegisterDialog(true);
  }

  const loginSuccessHandler = () => {

    if (onLoginSuccess) {
      onLoginSuccess();
    }

    if (loginSuccessFnParams) {
      loginSuccessFn();
    }
  };

  const registerSuccessHandler = () => {

    if (onRegisterSuccess) {
      onRegisterSuccess();
    }

    // commenting it as we'll trigger this on submit of ratingDialogSuccess
    // if (registerSuccessFnParams) {
    //   registerSuccessFn(registerSuccessFnParams);
    // }
  };

  const onCloseHandler = () => {

    if (onRegisterDialogCloseParams) {
      onRegisterDialogCloseFn(onRegisterDialogCloseParams);
    }
  };

  if (isAuthenticated) {
    return;
  }

  return (
      <>
        <Stack direction={'row'} spacing={isMobile ? 0.5 : 1.5}
               alignItems={'center'}>
          {
              !disableRegister &&
              <Button
                  variant={variantRegister}
                  size={isMobile ? 'small' : size}
                  onClick={handleOpenRegisterDialog}
                  color={colorSignin}
                  sx={{
                    fontWeight: 300,
                    ...(signInInverted && {
                      backgroundColor: '#fff',
                      '&:hover': {
                        backgroundColor: theme.palette[colorSignin ||
                        'primary'].main,
                        color: '#fff',
                      },
                    }),
                  }}
              >
                {t('authFlow.manager.register.heading')}
              </Button>
          }
          {
              showDivider &&
              <DividerVertical/>
          }
          <Button
              variant={isMobile ? 'contained' : variantSignIn}
              size={isMobile ? 'small' : size}
              onClick={handleOpenLoginDialog}
              // color={color}
              sx={{
                fontWeight: 300,
              }}
          >
            {t('authFlow.manager.signin.heading')}
          </Button>
        </Stack>
        <DialogLogin
            isOpenDialog={isOpenLoginDialog}
            setIsOpenDialog={setIsOpenLoginDialog}
            willShowForgotPassword={willShowForgotPassword}
            setWillShowForgotPassword={setWillShowForgotPassword}
            willShowRegisterDialog={willShowRegisterDialog}
            setWillShowRegisterDialog={setWillShowRegisterDialog}
            onLoginSuccess={loginSuccessHandler}
            preFillEmail={preFillEmail}
            setPreFillEmail={setPreFillEmail}
            showRegisterDialog={() => {
              setIsOpenRegisterDialog(true);
            }}
            showForgotPasswordDialog={() => {
              setIsOpenForgotDialog(true);
            }}
        />
        <DialogForgotPassword
            isOpenDialog={isOpenForgotDialog}
            setIsOpenDialog={setIsOpenForgotDialog}
            setWillShowForgotPassword={setWillShowForgotPassword}
            willShowLoginDialog={willShowLoginDialog}
            setWillShowLoginDialog={setWillShowLoginDialog}
            showLoginDialog={() => {
              setIsOpenLoginDialog(true);
            }}
        />
        <DialogRegister
            preFillEmail={preFillEmail}
            setPreFillEmail={setPreFillEmail}
            isOpenDialog={isOpenRegisterDialog}
            setIsOpenDialog={setIsOpenRegisterDialog}
            setWillShowRegisterDialog={setWillShowRegisterDialog}
            onRegisterSuccess={registerSuccessHandler}
            onRegisterFailure={onRegisterFailure}
            onClose={onCloseHandler}
        />
      </>
  );
}

export default SiteOnboardingButtons;
