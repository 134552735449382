import {Box, Chip, Grid, Stack, Typography, useMediaQuery} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import IconSport from '../../assets/icons/IconSport';
import moment from 'moment-timezone';
import {Route, Routes, useNavigate} from 'react-router-dom';
import DialogBookingsReservationDetail
  from '../../dialogs/Booking/DialogBookingsReservationDetail';
import DialogBookingsRecurringSingleDetail
  from '../../dialogs/Booking/DialogBookingsRecurringSingleDetail';
import IconTime from '../../assets/icons/IconTime';
import IconCalendar from '../../assets/icons/IconCalendar';
import { formatTime } from '../../utils/ui';

function UpcomingBookingsList({
                                bookings,
                                clubId,
                                totalBookings,
                                getUpcomingBooking,
                              }) {
  const {t} = useTranslate();
  const isDesktopLayout = useMediaQuery('(min-width:800px)');

  const navigateTo = useNavigate();
  const onBookingClick = (booking) => {

    if (booking?.type == 'open_match') {
      navigateTo(`openMatches/detail?id=${booking?.open_match_id}`);
    } else if (booking?.type === 'reservation') {
      navigateTo(`booking/detail`, {
        state: {
          bookingId: booking?.booking_id,
          bookingType: 'reservation',
          clubId: undefined,
          // id: location?.state?.id,
        },
      });
    } else {
      navigateTo(`booking/recurring/detail`, {
        state: {
          bookingId: booking?.booking_id,
          recurrBookingId: booking?.recurr_booking_id,
        },
      });
    }

  };

  function getDateDifference(dateString) {
    const targetDate = moment.utc(dateString);
    const currentDate = moment().startOf('day');

    // Calculate the difference in days
    const differenceDays = targetDate.diff(currentDate, 'days');

    if (differenceDays === 0) {
      return 'Today';
    } else if (differenceDays === 1) {
      return `in 1 Day`;
    } else if (differenceDays > 1) {
      return `in ${differenceDays} Days`;
    } else {
      return 0;
    }
  }

  const dotStyle = {
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: 'rgba(168, 168, 168, 1)',
  };
  return (
      <Box >
        <Stack direction={'row'} justifyContent={'space-between'}
               alignItems={'center'} mb={2}>
          <Typography
              textTransform={'capitalize'}
              variant="h5-bold">{t(
              'customerProfile.upcomingBookingHeading')}</Typography>
          <Typography sx={{cursor: 'pointer'}}
                      onClick={() => navigateTo(`/club/${clubId}/bookings`)}
                      variant="body1" color={'primary'}>{t(
              'View All')} ({totalBookings})</Typography>
        </Stack>
        <Grid container spacing={2} >
          {bookings?.map(booking => {
            const differenct = getDateDifference(booking?.start_date_time_utc);
            return (
                <Grid
                    item
                    xs={12}
                    md={6}
                    width={isDesktopLayout ? 'initial' : '100%'}
                    onClick={() => onBookingClick(booking)}
                >
                  <Box sx={{
                    border: '1px solid rgba(0, 0, 0, 0.16)',
                    borderRadius: '12px',
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px',
                    padding: isDesktopLayout ? '12px 20px' : '12px 10px',
                    position: 'relative',

                    backgroundColor: booking?.status === 'paid' ?
                        'initial' :
                        'rgba(255, 84, 73, 0.12)',
                    cursor: 'pointer',

                  }}>
                    <Box sx={{
                      ':before': {
                        position: 'absolute',
                        height: '100%',
                        width: '6px',
                        background: booking?.status === 'paid' ?
                            '#2cbc63' :
                            'rgba(255, 84, 73, 1)',
                        content: '""',
                        top: 0,
                        left: 0,
                        borderRadius: '6px',
                      },
                    }}>
                      <Stack mb={0.5} direction={'row'}
                             justifyContent={'space-between'}>
                        <Stack direction={'row'} alignItems={'center'}
                               spacing={1.5}>
                          <IconCalendar sx={{width: '18px'}}/>
                          <Typography variant="body1-emphasis">{moment(
                              booking?.start_date_time_utc).
                              format('ddd, DD MMM YY')} </Typography>
                          <Stack direction={'row'} spacing={1}
                                 alignItems={'center'}>
                            <Typography sx={dotStyle}></Typography>
                            <Typography variant="body2"
                                        color={'rgba(0, 0, 0, 0.87)'}>{differenct}</Typography>
                          </Stack>
                        </Stack>
                        <Chip
                            label={booking?.status === 'paid' ?
                                t('global.chips.label.paid') :
                                t('global.types.transactions.status.unpaid')}
                            size="small"
                            color={booking?.status === 'paid' ?
                                'success' :
                                'error'}
                        />
                      </Stack>
                      <Stack mb={0.5} direction={'row'} alignItems={'center'}
                             spacing={1.5}>
                        <IconTime sx={{width: '18px'}}/>
                        <Typography variant="body1-emphasis">
                          {`${formatTime(booking?.start_time)} - ${formatTime(booking?.end_time)}`}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} alignItems={'center'}
                             spacing={1.5}>
                        <IconSport sx={{width: '18px'}}/>
                        <Typography
                            variant="body1-emphasis">{booking?.name}</Typography>
                        <Stack direction={'row'} spacing={1}
                               alignItems={'center'}>
                          <Typography sx={dotStyle}></Typography>
                          <Typography variant="body2"
                                      color={'rgba(0, 0, 0, 0.87)'}>{booking?.sport}</Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
            );
          })}

        </Grid>
        <Routes>
          <Route path={`booking/detail`}
                 element={<DialogBookingsReservationDetail
                     open={true}
                     root={`/club/${clubId}`}
                     onCancel={getUpcomingBooking}
                 />}/>
          <Route path={`booking/recurring/detail`}
                 element={<DialogBookingsRecurringSingleDetail
                     type={'player-bookings-table'}
                     root={`/club/${clubId}`}
                     isOpen={true}
                     onCancel={getUpcomingBooking}
                 />}/>
        </Routes>
      </Box>
  );
}

export default UpcomingBookingsList;
