import {Alert, AlertTitle, Box, Button, Link, Skeleton, Typography, useTheme} from '@mui/material';
import DialogSection from '../../_Global/Dialogs/DialogSection';
import {
  AccessTimeOutlined,
  AccountBalanceWallet,
  LocationOn,
  PaymentOutlined,
  PeopleAltOutlined,
  ReceiptLongOutlined,
  SurroundSoundOutlined,
} from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import BookingsDateTimeDetails from './BookingsDateTimeDetails';
import BookingsClubDetails from './BookingsClubDetails';
import BookingsCourtDetails from './BookingsCourtDetails';
import BookingsParticipantsDetails from './BookingsParticipantsDetails';
import {useTranslate} from '@tolgee/react';
import dayjs from 'dayjs';
import {
  getBookingTimeHourSuffix,
  getCourtBookingLength,
  getExtrasBillingEntries,
} from '../../../utils/bookings';
import BillingDetails from '../../_Global/Billing/BillingDetails';
import AlertsBookingStatus from '../AlertsBookingStatus';
import BookingsPaymentTypeDetails from './BookingsPaymentTypeDetails';
import WalletSelect from '../../_Global/Wallet/WalletSelect';
import useLocale from '../../../hooks/localization/useLocale';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import {RefundDetails} from '../../_Global/Billing/RefundDetails';
import { bookingEvents } from '../../../utils/analyticsEvents';
import MeshlinkLightAndCode from '../MeshlinkLightAndCode';
import { useEffect, useState } from 'react';
import { getBookingsWalletPaymentOnDetail } from '../../../vendor/redux/actions/bookings';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useUser from '../../../hooks/access/useUser';
import WalletAlerts from '../WalletAlerts';
import usePolicies from '../../../hooks/policies/usePolicies';
import { roundToTwoDecimal } from '../../../utils/math';

function BookingsReservationDetails({
                                      booking,
                                      isLoading,
                                      canDoPayment,
                                      isPayingWithWallet,
                                      origin,
                                      participantPaidStatus,
                                      isAccessCodeShow = true,
                                      fnForWalletTopSuccess,
                                    }) {

  const {t} = useTranslate();
  const {locale} = useLocale();
  const theme = useTheme();
  dayjs.locale(locale);
  const dispatch = useDispatch();
  const user = useUser();
  const userId = user?.userObj?.id;

  const [billing,setBilling] = useState();
  const [players,setPlayers] = useState();

  const {token} = useParams();

  const billingData = billing ?? booking?.billing;

  const isAuthenticated = useIsAuthenticated();

  const paymentType = booking?.paymentType;
  const isSplitPayment = paymentType === 'split';

  const {clubId} = useParams();
  const policies = usePolicies();

  const status = booking?.status;
  const participants = booking?.participants;
  const date = booking?.date;
  const startTime = booking?.startTime;
  const endTime = booking?.endTime;
  const bookingTimeZone = booking?.court?.club.addresses[0].timezone;
  const clubName = booking?.court.club.name;
  const clubStreet = booking?.court?.club?.addresses[0]?.streetAddress;
  const houseNumber = booking?.court?.club?.addresses[0]?.houseNumber;
  const clubCity = booking?.court?.club?.addresses[0]?.city;
  const clubPostalCode = booking?.court?.club?.addresses[0]?.postalCode;
  const courtName = booking?.court.name;
  const courtType = booking?.court.courtType;
  const courtFeature = booking?.court.courtFeature;
  const courtSport = booking?.court.sport;

  const bookingTotalPrice = +billingData?.totalPrice;
  const platformFee = billingData?.platformFee;
  const platformPercentage = billingData?.platformPercentageFormatted;
  const discount = +billingData?.discount || 0;
  const courtPrice = +billingData?.court || 0;
  const extrasTotal = +billingData?.extrasTotal || 0;

  const walletAvailableAmount = +booking?.wallet?.walletAmount || 0;
  const canPayByWallet = walletAvailableAmount > 0;
  const hasPayedWithWallet = billingData?.paidViaWallet;
  const hasPayedWithCounter = billingData?.paidViaCounter;

  const isAmountRefunded = booking?.refund?.isAmountRefunded;
  const refundMethod = booking?.refund?.method;
  const refundAmount = booking?.refund?.amount;
  const otherRefund = booking?.refund?.otherRefund;
  const otherAmount = booking?.refund?.otherAmount;

  const bookingLength = getCourtBookingLength(startTime, endTime);
  const bookingLengthSuffix = getBookingTimeHourSuffix(bookingLength);

  const isMinutes = bookingLength < 1;
  const billingEntries = [
    {
      label: `${t(
          'global.components.billing.court')} ${isMinutes ? bookingLength * 60 :roundToTwoDecimal(bookingLength * 10) /
      10} ${isMinutes ? t('global.components.billing.min') : t(
          `global.components.billing.hour${bookingLengthSuffix}`)}`,
      amount: billingData?.court,
    },
    ...(getExtrasBillingEntries(billingData)),
  ];

  useEffect(()=>{

    if(isPayingWithWallet){

      // operation to change current billing with new billing
      // update current player (person whose booking detail opens) price

      const body={
        token,
        bookingId:booking?.bookingId,
        userId:!token? userId:undefined
      }

      dispatch(getBookingsWalletPaymentOnDetail(body,cbSuccess,cbFail));
      function cbSuccess(res) {

        console.log('getwalletdetailsOnBooknig Success', res);
        const response = res?.data?.data;
        const player = response?.player;
        const currentParticipants = JSON.parse(JSON.stringify(participants));
        const index = currentParticipants?.findIndex(x=>x.participantId==player?.participantId)
        const walletPaidAmount = +player?.price > +response?.walletCredit ? response?.walletCredit: 0
        if(index!=-1 ){
          currentParticipants[index] = {...currentParticipants[index],price:player?.price, walletPaidAmount, paymentStatus:response.walletCredit >= parseFloat(currentParticipants[index]?.price) ? 'paid' :'unpaid'};
        }
        setPlayers(currentParticipants);
        setBilling(
          {...booking?.billing,
          platformFee:response?.platformFee,
          totalPrice:response?.totalPrice,
          paidViaWallet:true,
          walletPaidAmount:response?.walletCredit
        });

      }

      function cbFail(e) {
        console.log('getwalletdetailsOnBooknig Fail', e);
      }

    }else if(isPayingWithWallet==false){
      setBilling(booking?.billing);
      setPlayers(booking?.participants);
    }
  },[isPayingWithWallet])

  return (
      <>

{isAccessCodeShow && !isLoading && booking?.status === 'paid' &&
        <MeshlinkLightAndCode
        pinCode={booking?.meshLink?.data?.pinCode}
        customMessage={booking?.meshLink?.customPinCodeMessage}
        />
      }
        {
            !isLoading &&
            <Box mt={2}>
              <AlertsBookingStatus
                  bookingStatus={status}
                  paymentType={paymentType}
                  participants={participants}
                  bookingDate={date}
                  bookingTime={startTime}
                  bookingTimezone={bookingTimeZone}
                  origin={origin}
                  participantPaidStatus={participantPaidStatus}
              />
            </Box>
        }

        <DialogSection
            label={t('bookingAdd.time')}
            icon={AccessTimeOutlined}
            isLoading={isLoading}
        >
          <BookingsDateTimeDetails
              isLoading={isLoading}
              date={date && `${dayjs(date, 'YYYY-MM-DD').format('ddd ll')}`}
              timeStart={startTime && dayjs(startTime, 'HH:mm').format('LT')}
              timeEnd={endTime && dayjs(endTime, 'HH:mm').format('LT')}
          />
        </DialogSection>

        <DialogSection
            isLoading={isLoading}
            label={'Club'}
            icon={LocationOn}
        >
          <BookingsClubDetails
              isLoading={isLoading}
              clubName={clubName}
              clubStreet={clubStreet}
              houseNumber={houseNumber}
              clubCity={clubCity}
              clubPostalCode={clubPostalCode}
          />
        </DialogSection>
        <DialogSection
            label={t('bookingAdd.court')}
            icon={SurroundSoundOutlined}
            isLoading={isLoading}
        >
          <BookingsCourtDetails
              isLoading={isLoading}
              courtName={courtName}
              courtType={courtType}
              courtFeature={courtFeature}
              courtSport={courtSport}
          />
        </DialogSection>
        <DialogSection
            label={t('bookingAdd.payment')}
            icon={PaymentOutlined}
            isLoading={isLoading}
        >
          <BookingsPaymentTypeDetails
              isLoading={isLoading}
              paymentType={t(`global.types.payments.${paymentType}`)}
          />
        </DialogSection>
        {
            isAuthenticated && !isLoading && canDoPayment &&
            <DialogSection
                label={t('bookings.add.section.wallet')}
                icon={AccountBalanceWallet}
                isLoading={isLoading}
                isDisabled={false}
                subLabel={ policies?.wallet &&  <Link
                  style={{cursor:'pointer', margin: '0 0 0 auto', color:theme.palette.primary.main}}
                color={theme.palette.secondary.main}
                target='_blank'
                onClick={()=> window.open(`${process.env.REACT_APP_URL}/club/${clubId}/wallet/topup?cId=${clubId}`, '_blank')}
                >
                {t('wallet.topup')}
          </Link>}
            >
              <WalletSelect
                  isLoading={isLoading}
                  walletData={{
                    available: !!walletAvailableAmount,
                    canPay: canPayByWallet,
                    amount: +walletAvailableAmount || 0,
                  }}
                  onChange={()=>bookingEvents['useWalletFromDetails']()}
              />
                {
                !isLoading &&
                <WalletAlerts
                canPay={canPayByWallet}
                clubId={clubId ?? booking?.court?.club?.clubId}
                onSuccess={()=>fnForWalletTopSuccess()}
                walletId={booking?.wallet?.walletId}
                />}

            </DialogSection>
        }

        <DialogSection
            isLoading={isLoading}
            label={t('bookingAdd.players')}
            icon={PeopleAltOutlined}
        >
          <BookingsParticipantsDetails
              isLoading={isLoading}
              players={players ?? participants}
              isPayingWithWallet={isPayingWithWallet}
              platformFee={platformFee}
              paymentType={booking?.paymentType}
          />
        </DialogSection>


        <DialogSection
            icon={ReceiptLongOutlined}
            label={t('bookingAdd.billings')}
            isLoading={isLoading}
        >
          <BillingDetails
              isLoading={isLoading}
              entries={billingEntries}
              discount={discount}
              platformPercentage={platformPercentage}
              platformFee={platformFee}
              isWalletPayment={hasPayedWithWallet}
              isCounterPayment={hasPayedWithCounter}
              walletDebitedAmount={billingData?.walletPaidAmount}
              counterPaidAmount={billingData?.counterPaidAmount}
              totalBillingAmount={bookingTotalPrice}
          />
        </DialogSection>
        {
          isAmountRefunded && otherRefund &&
           <DialogSection
           label={null}
           icon={ReceiptLongOutlined}
           isLoading={isLoading}
           isHideIcon={true}
           isLastChild={true}
           paddingY='10px'
       >
         <RefundDetails
             isLoading={isLoading}
             refundMethod={otherRefund}
             refundAmount={otherAmount}
         />
       </DialogSection>
        }
        {
            isAmountRefunded &&
            <DialogSection
                label={null}
                icon={ReceiptLongOutlined}
                isLoading={isLoading}
                isHideIcon={true}
                isLastChild={true}
                paddingY={otherRefund ? '5px': '20px'}
            >
              <RefundDetails
                  isLoading={isLoading}
                  refundMethod={refundMethod}
                  refundAmount={refundAmount}
              />
            </DialogSection>
        }

        {
            isLoading && <Skeleton width={'100%'} height={'2px'}/>
        }
      </>
  );
}

export default BookingsReservationDetails;

