import useExperienceContext
  from '../clubExperience/useExperienceContext';

function usePolicies() {

  const {clubPolicies} = useExperienceContext();

  return clubPolicies;

}

export default usePolicies;
