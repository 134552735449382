function IconNoOpenMatches() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.35" d="M26.6663 12H5.33301C3.12387 12 1.33301 13.7909 1.33301 16V22.6667C1.33301 24.8758 3.12387 26.6667 5.33301 26.6667H26.6663C28.8755 26.6667 30.6663 24.8758 30.6663 22.6667V16C30.6663 13.7909 28.8755 12 26.6663 12Z" fill="#757575" />
            <path d="M9.07342 11.9995L15.2801 6.93279C15.4838 6.76838 15.7377 6.67871 15.9994 6.67871C16.2612 6.67871 16.5151 6.76838 16.7188 6.93279L22.9334 11.9995H27.1494L18.4001 4.86345C17.7198 4.31119 16.8703 4.00977 15.9941 4.00977C15.1179 4.00977 14.2683 4.31119 13.5881 4.86345L4.85742 11.9995H9.07342Z" fill="#757575" />
            <path d="M17.6133 22.0727V16.5927C17.6133 16.3324 17.7167 16.0828 17.9008 15.8988C18.0848 15.7147 18.3344 15.6113 18.5947 15.6113H20.4613C20.5587 15.6113 20.6551 15.6305 20.745 15.6678C20.835 15.705 20.9167 15.7596 20.9855 15.8285C21.0544 15.8973 21.109 15.979 21.1462 16.069C21.1835 16.1589 21.2027 16.2553 21.2027 16.3527C21.2027 16.5493 21.1246 16.7378 20.9855 16.8769C20.8465 17.0159 20.6579 17.094 20.4613 17.094H19.216V18.5607H20.3507C20.5349 18.5607 20.7116 18.6338 20.8419 18.7641C20.9721 18.8944 21.0453 19.0711 21.0453 19.2553C21.0453 19.3465 21.0273 19.4368 20.9924 19.521C20.9575 19.6052 20.9063 19.6817 20.8418 19.7461C20.7773 19.8105 20.7007 19.8615 20.6164 19.8963C20.5321 19.931 20.4418 19.9488 20.3507 19.9487H19.216V21.5487H20.588C20.6853 21.5487 20.7817 21.5678 20.8717 21.6051C20.9616 21.6423 21.0434 21.697 21.1122 21.7658C21.181 21.8346 21.2356 21.9164 21.2729 22.0063C21.3102 22.0962 21.3293 22.1926 21.3293 22.29V22.322C21.3293 22.4193 21.3102 22.5157 21.2729 22.6057C21.2356 22.6956 21.181 22.7774 21.1122 22.8462C21.0434 22.915 20.9616 22.9696 20.8717 23.0069C20.7817 23.0442 20.6853 23.0633 20.588 23.0633H18.588C18.4589 23.0625 18.3312 23.0361 18.2123 22.9858C18.0934 22.9355 17.9856 22.8623 17.8951 22.7703C17.8045 22.6782 17.733 22.5692 17.6847 22.4495C17.6363 22.3298 17.6121 22.2018 17.6133 22.0727Z" fill="#757575" />
            <path d="M26.9338 23.0541C26.7182 23.0538 26.5077 22.9881 26.3301 22.8658C26.1525 22.7434 26.0162 22.5701 25.9391 22.3688L24.4725 18.5234H24.4418L24.4778 22.3248C24.4785 22.4203 24.4603 22.515 24.4242 22.6035C24.3882 22.6919 24.3349 22.7724 24.2676 22.8402C24.2003 22.9079 24.1203 22.9618 24.0321 22.9985C23.9439 23.0352 23.8493 23.0541 23.7538 23.0541H23.7031C23.6082 23.0541 23.5143 23.0354 23.4266 22.9991C23.3389 22.9628 23.2592 22.9095 23.1921 22.8424C23.125 22.7753 23.0718 22.6957 23.0355 22.608C22.9992 22.5203 22.9805 22.4263 22.9805 22.3314V16.6754C22.9805 16.3925 23.0928 16.1212 23.2929 15.9212C23.4929 15.7211 23.7642 15.6088 24.0471 15.6088H24.0951C24.312 15.609 24.5237 15.6754 24.7018 15.799C24.88 15.9227 25.0163 16.0977 25.0925 16.3008L26.5471 20.2021H26.5778L26.5525 16.3354C26.5518 16.2401 26.5699 16.1455 26.6059 16.0572C26.6419 15.9689 26.6951 15.8886 26.7622 15.821C26.8294 15.7533 26.9093 15.6996 26.9973 15.6629C27.0854 15.6263 27.1798 15.6074 27.2751 15.6074C27.4668 15.6074 27.6506 15.6836 27.7861 15.8191C27.9217 15.9546 27.9978 16.1384 27.9978 16.3301V21.9861C27.9978 22.269 27.8854 22.5403 27.6854 22.7403C27.4853 22.9404 27.214 23.0528 26.9311 23.0528L26.9338 23.0541Z" fill="#757575" />
            <path d="M9.71867 19.3121C9.71867 19.9001 9.552 23.2548 6.85867 23.2548C4.16533 23.2548 4 19.9001 4 19.3121C4 18.7241 4.14133 15.4121 6.85867 15.4121C9.576 15.4121 9.71867 18.7228 9.71867 19.3121ZM8 19.3121C8 18.9894 7.964 17.0454 6.864 17.0454C5.764 17.0454 5.72933 18.9934 5.72933 19.3121C5.72933 19.6308 5.79067 21.6041 6.864 21.6041C7.93733 21.6041 8 19.6348 8 19.3121Z" fill="#757575" />
            <path d="M15.9628 17.9449C15.9871 18.2509 15.9478 18.5587 15.8473 18.8488C15.7468 19.139 15.5873 19.4051 15.3789 19.6306C15.1705 19.856 14.9176 20.0359 14.6363 20.1588C14.3549 20.2818 14.0512 20.3451 13.7442 20.3449H13.0202V22.2315C13.0202 22.4504 12.9332 22.6604 12.7784 22.8151C12.6236 22.9699 12.4137 23.0569 12.1948 23.0569C11.9758 23.0569 11.7658 22.97 11.6108 22.8152C11.4558 22.6605 11.3685 22.4506 11.3682 22.2315V16.5929C11.3685 16.3325 11.4722 16.0829 11.6565 15.8989C11.8407 15.7149 12.0904 15.6115 12.3508 15.6115H13.6842C13.9931 15.5924 14.3025 15.6401 14.5914 15.7513C14.8802 15.8626 15.1417 16.0348 15.358 16.2563C15.5742 16.4777 15.7402 16.7432 15.8446 17.0346C15.949 17.326 15.9893 17.6365 15.9628 17.9449ZM14.3855 17.9769C14.3855 17.1355 13.7868 17.0302 13.5562 17.0302H13.0228V18.9755H13.5562C13.6784 18.9705 13.7982 18.9398 13.9078 18.8855C14.0175 18.8312 14.1145 18.7546 14.1927 18.6604C14.2708 18.5663 14.3284 18.4569 14.3616 18.3391C14.3948 18.2214 14.4029 18.098 14.3855 17.9769Z" fill="#757575" />
        </svg>


    )


}
export default IconNoOpenMatches;