import {useSlotSearchV2Context} from '../ProviderSlotSearchV2';
import {
  ToggleButtonGroupRounded,
  ToggleButtonRounded,
} from '../../_Global/ToggleButtonGroup/ToggleButtonGroupRounded';
import {TimerOutlined} from '@mui/icons-material';
import {Stack} from '@mui/material';

function SlotSearchV2HeaderDurationSelect() {

  const {
    durations,
    selectedDuration,
    handleSelectDuration,
    isTablet,
  } = useSlotSearchV2Context();

  function handleOnChange(e, value) {
    if (selectedDuration === value || !value) {
    } else {
      handleSelectDuration(value);
    }
  }

  return (
      <ToggleButtonGroupRounded
          fullWidth={isTablet}
          exclusive
          value={selectedDuration}
          onChange={handleOnChange}
      >
        {
          durations?.map((duration, index) => {

            let label;
            let Icon;

            if (selectedDuration === duration) {
              label = `${duration} Min`;
              Icon = isTablet && TimerOutlined;
            } else {
              label = `${duration}`;
            }

            const isActive = selectedDuration === duration;

            return (
                <ToggleButtonRounded
                    key={index}
                    value={duration}
                    color={'secondary'}
                    sx={{
                      ...(isActive && isTablet) && {
                        minWidth: '116px',
                      },
                    }}
                >
                  <Stack direction="row" alignItems="center" columnGap={.5}>
                    {Icon && <Icon/>}{label}
                  </Stack>

                </ToggleButtonRounded>
            );
          })
        }

      </ToggleButtonGroupRounded>
  );
}

export default SlotSearchV2HeaderDurationSelect;
