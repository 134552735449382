import Add from "@mui/icons-material/Add";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DialogSlots from "./DialogSlots";
import useExperienceContext from "../../hooks/clubExperience/useExperienceContext";
import useUser from "../../hooks/access/useUser";
import { useSelector } from "react-redux";
import useIsMobile from "../../hooks/ui/useIsMobile";
import useOnboardingContext from "../../hooks/onboarding/useOnboardingContext";
import { getLoggedInPlayerRating } from "../../utils/openMatches";
import { store } from "../../vendor/redux/configure-store";

const CreateMatchBtn = ({btnSize='medium',variant='contained'}) => {

  const {
    setIsOpenLoginDialog,
    setLoginDialogDescription,
    setLoginSuccessFnParams,
    setOnLoginDialogCloseParams,
    setIsOpenUserRatingDialog,
    setRatingDialogDescription,
    setRatingSuccessFn,
  } = useOnboardingContext();

  const { t } = useTranslate();
  const { clubId } = useParams();

  const isMobile = useIsMobile();
  const storeData = useSelector((state) => state.openMatches);
  const listing = storeData?.listing;
  const filters=listing?.filters;
  const sport = filters?.sport;
  
  const [createNewmatch, setCreateNewMatch] = useState(false);
  
  const { clubDetails, clubPolicies, clubTimings } = useExperienceContext();
  const USER = useUser();

  const timezone = clubDetails?.addresses?.at(0)?.timezone ?? "Europe/Amsterdam";
  const openMatchMinCreateTime = clubPolicies?.openMatchMinCreateTime ?? 0;

  const onLoginSuccess=()=>{
    
    getLoggedInPlayerRating(clubId,null,sport,checkUserRating);

  }
  const handleClick=()=>{

    if(USER){
      checkUserRating();
    }else{
      setLoginDialogDescription(t('openMatches.createMatch.loginDialogDescription'));
      setIsOpenLoginDialog(true);
      setLoginSuccessFnParams({
        successFn:()=>{
          onLoginSuccess();
          setLoginDialogDescription('');
      }})
      setOnLoginDialogCloseParams({
        successFn:()=>{
          setLoginDialogDescription('');
      }})
    }
  }

  const invalidState=()=>{

    setRatingDialogDescription(t('player.profile.addRating.toCreateMatch.description'))
    setIsOpenUserRatingDialog(true);
    setRatingSuccessFn({
      successFn:()=>{
        setCreateNewMatch(true);
        setRatingDialogDescription('');
    }});

  }

  const checkUserRating=()=>{

    if(store?.getState().openMatches.invalidRating){ // Retain this state to ensure we get the updated state after login
      invalidState();
    }else{
      setCreateNewMatch(true);
    }
  
  }
  useEffect(()=>{
      if(USER){
        getLoggedInPlayerRating(clubId,USER,sport);
      }
  },[sport])
  
  return (
    <Box width={isMobile ? "100%" : "auto"}>
      <Button
      sx={{alignItems:'center'}}
      size={btnSize}
      fullWidth
      variant={variant}
      color="primary"
      onClick={handleClick}
      >
        <Add />
        {isMobile ? t("openMatches.listing.header.mobile.createBtn"):t("openMatches.listing.header.createBtn")} 
      </Button>
      <DialogSlots
        open={createNewmatch}
        setOpen={setCreateNewMatch}
        openMatchMinCreateTime={openMatchMinCreateTime}
        timezone={timezone}
      />
    </Box>
  );
};
export default CreateMatchBtn;
