import { Grid } from "@mui/material";
import { courtAvailableCheck, fetchExtras } from "../../utils/bookings";
import CourtSelect from "../../components/Bookings/AddBookingV2/CourtSelect";
import BookingSection from "../../components/Bookings/AddBookingV2/BookingSection";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import useIsMobile from "../../hooks/ui/useIsMobile";

const DialogBookingsAddV2Content = ({
  setIsDialogOpen,
  isCourtNotAvailable,
  setIsCourtNotAvailable,
  startDate,
  priceData,
  setPriceData,
  selectedCourt,
  locationState,
  isCourtSelected
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [isLoadingExtras, setIsLoadingExtras] = useState(false);
  const [extras, setExtras] = useState([]);
  const bookingSectionRef = useRef(null);
  const { clubId, courtSport, startTime, endTime, date } = selectedCourt || {};

  useEffect(() => {
    if (isCourtSelected) {
      
      if (bookingSectionRef.current) {
        bookingSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      dispatch(
        fetchExtras({ clubId, courtSport, setExtras, setIsLoadingExtras })
      );
    }
  }, [selectedCourt]);

  useEffect(() => {
    if (clubId && isCourtSelected) {
      dispatch(
        courtAvailableCheck({
          startDate,
          startTime,
          endTime,
          clubId,
          setIsCourtNotAvailable,
          selectedCourt,
        })
      );
    }
  }, [clubId, selectedCourt]);

  return (
    <Grid container spacing={isMobile?3:1} padding={"1rem 0"} overflow={'auto'}>
      <CourtSelect
          isCourtSelected={isCourtSelected}
          />
      <BookingSection
        ref={bookingSectionRef}
        locationState={locationState}
        clubId={clubId}
        courtSport={courtSport}
        selectedCourt={selectedCourt}
        startDate={startDate}
        startTime={startTime}
        endTime={endTime}
        isCourtNotAvailable={isCourtNotAvailable}
        extras={extras}
        isLoadingExtras={isLoadingExtras}
        isCourtSelected={isCourtSelected}
        setIsOpenDialog={setIsDialogOpen}
        priceData={priceData}
        setPriceData={setPriceData}
      />
    </Grid>
  );
};
export default DialogBookingsAddV2Content;
