import {Outlet} from 'react-router-dom';
import LayoutMainHeader from './LayoutMainHeader';
import LayoutMainPage from './LayoutMainPage';
import {Box} from '@mui/material';
import {
  EventOutlined,
  Inventory2Outlined,
  Person,
  Search,
  Wallet,
} from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import PasswordIcon from '@mui/icons-material/Password';
import {useTranslate} from '@tolgee/react';
import LayoutMainMobileHeader from './LayoutMainMobileHeader';
import useIsMobile from '../../hooks/ui/useIsMobile';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import LayoutMainPublicHeader from './LayoutMainPublicHeader';
import PlumpyBooking from '../../assets/icons/PlumpyBooking';
import PlumpyEvents from '../../assets/icons/PlumpyEvents';
import PlumpySettings from '../../assets/icons/PlumpySettings';
import PlumpyWallets from '../../assets/icons/PlumpyWallets';

function LayoutMain({shouldRedirectOnLogin = true}) {

  const {t} = useTranslate();
  const isMobile = useIsMobile();

  const isAuthenticated = useIsAuthenticated();

  const navItems = [
    // {
    //   name: t('sideBar.link.textSearch'),
    //   route: '/',
    //   icon: Search,
    //   disabled: false,
    //   canAccess: true,
    // },
    {
      name: t('sideBar.link.textBooking'),
      route: `/bookings`,
      activeOnRoot:true,
      icon: PlumpyBooking,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.textEvents'),
      route: `/events`,
      icon: PlumpyEvents,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.wallet'),
      route: `/wallet`,
      icon: PlumpyWallets,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.muClubs'),
      route: `/my-clubs`,
      icon: Wallet,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.textSetting'),
      route: `/settings`,
      icon: PlumpySettings,
      disabled: false,
      canAccess: true,
      // subMenuItems: [
      //   {
      //     icon: Person,
      //     name: t('settings.playerModule.profileName'),
      //     route: `/settings/profile`,
      //     canAccess: true,
      //   },
      //   {
      //     icon: PasswordIcon,
      //     name: t('settings.playerModule.passwordName'),
      //     route: `/settings/change-password`,
      //     canAccess: true,
      //   },
      // ],
    }];

  return (
      <Box
          sx={{height: '100vh', overflow: 'hidden'}}
      >
        {
            isAuthenticated && !isMobile &&
            <LayoutMainHeader
                navItems={navItems}
            />
        }
        {
            isAuthenticated && isMobile &&
            <LayoutMainMobileHeader
                navItems={navItems}
            />
        }
        {
            !isAuthenticated &&
            <LayoutMainPublicHeader shouldRedirectOnLogin={shouldRedirectOnLogin}/>
        }
        <LayoutMainPage>
          <Outlet/>
        </LayoutMainPage>
      </Box>
  );
}

export default LayoutMain;
