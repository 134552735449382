import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

const SportsRating =(props)=>{
    
    const {
        control,
        errors,
        label,
        name,
        errorName,
        disabled,
        sport,
        watched,
        options,
        isRequired=false,
        handleChange
    }=props;
    const selectedRating = watched[name];
    
    return (
      <Grid item xs={12} mb={2}>
        <Stack direction={"row"} alignItems={"center"}>
          <Box
            sx={{
              background: "rgba(245, 245, 245, 1)",
              border: "1px solid rgba(0, 0, 0, 0.23)",
              width: "92px",
              textAlign: "center",
              padding: selectedRating?.length > 0 ? "8px" : "7px",
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderRight: 0,
            }}
          >
            {sport}
          </Box>
          <FormControl fullWidth size="small">
            <InputLabel error={!!errors[name]}>{label}</InputLabel>
            <Controller
              name={name}
              control={control}
              rules={{
                required: {
                  value: isRequired,
                  message: errorName,
                },
              }}
              defaultValue={null}
              render={({ field: { name, value } }) => {
                return (
                  <Select
                    error={!!errors[name]}
                    label={label}
                    sx={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: '350px'
                        },
                      },
                    }}
                    name={name}
                    disabled={disabled}
                    value={value}
                    placeholder={label}
                    onChange={handleChange}
                    renderValue={(selected) => {

                      const ratingDetails = options?.find(x=>x.rating==selected);

                      return (
                        <Stack
                        width={'100%'}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        >
                          <Chip
                            size="small"
                            sx={{
                            backgroundColor: "#0288D1",
                            color: "#fff",
                            }}
                            key={selectedRating}
                            label={selectedRating}
                          />
                          <Typography
                          variant="body2"
                          >
                            {ratingDetails?.level}
                          </Typography>
                        </Stack>
                      );
                    }}
                  >
                    {options?.map((option) =>{

                      return(
                        <MenuItem value={option.rating}>
                          <Stack
                          width={'100%'}
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          >
                            <Typography>
                              {option?.rating}
                            </Typography>
                            <Typography
                            variant="body2"
                            >
                              {option?.level}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      )
                    })}
                  </Select>
                );
              }}
            />
          </FormControl>
        </Stack>
        {!!errors[name] && (
          <FormHelperText error>{errors[name].message}</FormHelperText>
        )}
      </Grid>
    );
};
export default SportsRating;