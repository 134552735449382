import useExperienceContext from "../clubExperience/useExperienceContext";

const useDemo=(policies)=>{

    const {
        clubPolicies
    } = useExperienceContext();

    const clubPolicy = policies ?? clubPolicies;

    const club = clubPolicy?.club;
    return club?.isDemoClub;
}

export default useDemo;
