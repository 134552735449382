import { useEffect, useState } from "react";
import DialogBase from "../../components/_Global/Dialogs/DialogBase";
import { TransitionSlide } from "../../vendor/mui/transitions";
import { useTranslate } from "@tolgee/react";
import { FormProvider, useForm } from "react-hook-form";
import SelectUserRatings from "../../components/Onboarding/SelectUserRatings";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addUserRatings } from "../../vendor/redux/actions/player";
import useOnboardingContext from "../../hooks/onboarding/useOnboardingContext";

const DialogAddUserRating=({isOpen,setIsOpen})=>{

    const {t} = useTranslate();
    const {enqueueSnackbar} = useSnackbar();

    const dispatch = useDispatch();
    const{clubId} = useParams();

    const formMethods = useForm();
    const [isSubmitting,setIsSubmitting] = useState(false);
    
    const {watch,reset,handleSubmit} = formMethods;
    const watched = watch();
    const isDisabled = Object.keys(watched)?.length==0;
    
    const {
        registerSuccessFn,
        registerSuccessFnParams,
        setRegisterSuccessFnParams,
        ratingDialogDecription, 
        onRatingSuccess
    } = useOnboardingContext();

    const handleCloseDialog=()=>{
        setIsOpen(false);
    }
    
    const onRegisterSuccess=()=>{
        if(registerSuccessFnParams){
            registerSuccessFn();
        }
    }

    function handleFormSubmit() {
        return handleSubmit(handleSubmitOnValid, handleSubmitOnFail)();
    }

    function handleSubmitOnValid(data) {
        
        setIsSubmitting(true);

        let ratingList = {};
        
        Object.keys(data)
            .filter(key => key.includes("Rating"))
            .map(key => {
                const newKey = key.replace("Rating", ""); 
                ratingList[newKey] = data[key];
            });
        
        const body={
            ratingList
        }

        dispatch(addUserRatings(clubId,body,cbSuccess,cbFailure));

        function cbSuccess(res){
            
            onRegisterSuccess();
            setIsOpen(false);
            enqueueSnackbar(t('player.profile.addRating.successMessage'), {variant: 'success'});
            setIsSubmitting(false);
            onRatingSuccess();
        }
        
        function cbFailure(e){
            enqueueSnackbar(t(e?.response?.data?.data), {variant: 'error'});
            setIsSubmitting(false);
        }

    }

    function handleSubmitOnFail(e) {
        enqueueSnackbar(t('global.validation.fail'), {variant: 'error'});
    }

    useEffect(()=>{

        return reset();
    },[isOpen])

    const DialogActions=()=>{

        return(
            <Stack
            width={'100%'}
            direction={'row'}
            justifyContent={'space-between'}
            >
                <Button
                variant="outlined"
                color="inherit"
                onClick={()=>{
                    onRegisterSuccess();
                    setIsOpen(false)}
                }
                >
                    {t('player.profile.addRating.skipBtn')}
                </Button>
                <LoadingButton
                variant="contained"
                loading={isSubmitting}
                disabled={isDisabled || isSubmitting}
                onClick={handleFormSubmit}
                >
                    {t('player.profile.addRating.submitBtn')}
                </LoadingButton>
            </Stack>
        )
    }
    
    return(
        <DialogBase
        isOpen={isOpen}
        isSubmitting={isSubmitting}
        dividers={false}
        transitionComponent={TransitionSlide}
        onExited={handleCloseDialog}
        onClose={handleCloseDialog}
        title={t('authFlow.player.dialog.addRating.heading')}
        description={ratingDialogDecription ?? t('player.profile.addRating.description')}
        contentSlot={
            <>
                <FormProvider {...formMethods}>
                    <SelectUserRatings/>
                </FormProvider>
            </>
        }
        actionsSlot={<DialogActions/>}
        dialogStyles={{
            '& .MuiDialogContent-root':{
                paddingTop:3
            }
        }}
        />
    )
}
export default DialogAddUserRating;