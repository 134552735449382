import {
  Avatar,
  Box,
  Chip,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import {
  MatchCardContainer,
} from '../../vendor/mui/styled-components/OpenMatchesListing';
import {useTranslate} from '@tolgee/react';
import IconLevel from '../../assets/icons/IconLevel.js';
import {getLocalizedNum} from '../../utils/localization';
import useLocale from '../../hooks/localization/useLocale';
import {
  PlayerListContainer,
} from '../../vendor/mui/styled-components/OpenMatches';
import {useEffect, useState} from 'react';
import {
  createArray,
  genderIcons,
  getGendersLabel,
  preFilledPlayersArray,
} from '../../utils/openMatches';
import {AvatarIcon} from './PlayerCard';
import useIsMobile from '../../hooks/ui/useIsMobile';
import {useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import useUser from '../../hooks/access/useUser';
import {SportsTennis} from '@mui/icons-material';
import {padLeft} from '../../utils/string';
import useDefaults from '../../hooks/defaults/useDefaults.js';
import { formatTime } from '../../utils/ui.js';

const MatchCard = (props) => {
  const {
    match,
    clubId,
    lastRef,
    customTypographyVariant,
    isLandingPageSection = false,
    cardBodyStyles = {},
    alwaysMobileView,
  } = props;

  const isMobileBreakpoint = useIsMobile();
  const isMobile = alwaysMobileView || isMobileBreakpoint;
  const {currencySymbol} = useDefaults();
  const {t} = useTranslate();
  const {locale} = useLocale();
  const navigateTo = useNavigate();
  const USER = useUser();

  const matchId = match?.shortUuid;

  const level = `${match?.minRating}-${match?.maxRating}`;
  const pricePerSlot = match?.pricePerSlot ?? 0;
  const participants = match?.participants ?? [];
  const courtSize = match?.courtSize;
  const teamSize = courtSize == 'Single' ? 1 : 2;
  const matchGender = match?.gender;
  const matchType = match?.type;
  const sport = match?.sport;

  const [players, setPlayers] = useState([]);
  const [playersLoading, setPlayersLoading] = useState(false);

  const price = `${currencySymbol} ${getLocalizedNum(locale,
      +pricePerSlot)}`;

  // function formatTime(time, showAMPM = false) {

  //   const t = moment(time, 'HH:mm');

  //   const hours = t.format('h');
  //   const suffix = t.format('A');
  //   const minutes = t.minutes();

  //   const minutesWithPadding = minutes > 0 ? padLeft(minutes, 2, '0') : null;

  //   const timeString = [hours, minutesWithPadding].filter(Boolean).join(':');

  //   return showAMPM ? timeString + ' ' + suffix : timeString;
  // }

  const setValue = (key, value) => setPlayers(value);

  const createArraySuccessFn = (players) => {
    preFilledPlayersArray({
      players,
      sport,
      participants,
      loggedInUser: USER?.userObj,
      setValue,
    });
    setPlayersLoading(false);
  };

  const MatchMiscContainer = ({
                                title,
                                icon: Icon,
                                img,
                                iconStyles,
                                className = '',
                              }) => {

    return (
        <Stack
            className={`matchMisc ${className}`}
            direction={'row'}
            alignItems={'end'}
            columnGap={isMobile ? 0 : 0.5}
        >
          <Avatar variant={'circular'} sx={{...iconStyles}}>
            {img ? <img src={img} loading="lazy"/> : <Icon/>}
          </Avatar>
          <Typography
              variant={'body2'}
              color={isMobile ? 'rgba(0, 0, 0, 0.55)' : 'rgba(0, 0, 0, 0.87)'}
              mt={isMobile ? 1 : 0}
              textTransform={'capitalize'}
          >
            {title}
          </Typography>
        </Stack>
    );
  };

  useEffect(() => {
    if (players?.length == 0) {
      setPlayersLoading(true);
      createArray({
        setValue,
        length: teamSize * 2,
        successFn: createArraySuccessFn,
      });
    }
  }, [participants]);

  return (
      <MatchCardContainer
          ref={lastRef}
          isMobile={isMobile}
          onClick={() =>
              navigateTo(`/club/${clubId}/openMatches/detail?id=${matchId}`)
          }
      >
        <Grid className="header" container>
          <Grid item xs={12} md={isMobile ? 12 : 3}>
            <Stack
                width={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
              <Typography
                  variant={'h6-bold'}
                  display={'flex'}
                  columnGap={1}
                  fontSize={{
                    xs: '18px',
                    md: '20px',
                  }}
              >
                {
                    isLandingPageSection &&
                    <Typography variant={'inherit'} fontSize={'inheri'}>
                      {`${moment(match?.date).format('DD MMM')},`}
                    </Typography>
                }
                <Typography variant={'inherit'} fontSize={isMobile?'14px':'inherit'}>
                  {`${formatTime(match?.startTime)} - ${formatTime(
                      match?.endTime)}`}
                </Typography>
              </Typography>

              {
                  isMobile &&
                  <Chip
                      size={'medium'}
                      color={'success'}
                      variant={'contained'}
                      label={price}
                  />
              }
            </Stack>
          </Grid>
          <Grid item xs={12} md={isMobile ? 12 : 7}
                lg={isLandingPageSection ? 7 : 5}>
            <Stack className="details">
              <MatchMiscContainer
                  title={`${isMobile ?
                      '' :
                      t('openMatches.player.level.title')} ${level}`}
                  icon={IconLevel}
              />

              <MatchMiscContainer
                  className={'matchType'}
                  title={matchType}
                  icon={SportsTennis}
              />

              <MatchMiscContainer
                  title={getGendersLabel(t, courtSize, matchGender)}
                  img={genderIcons[matchGender]}
                  iconStyles={{
                    marginRight: isMobile ?
                        '2px !important' :
                        '6px !important',
                  }}
              />

            </Stack>
          </Grid>
          {
              !isMobile &&
              <Grid item xs={12} md={2}>
                <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                >
                  <Chip
                      size={'medium'}
                      color={'success'}
                      variant={'contained'}
                      label={price}
                  />
                </Box>

              </Grid>
          }
        </Grid>
        {!isMobile &&
            <Stack
                className="body"
                sx={{...cardBodyStyles}}
            >
              {
                  players?.length > 0 &&
                  <Players
                      t={t}
                      players={players}
                      isMobile={isMobile}
                      loading={playersLoading}
                      teamSize={teamSize}
                      USER={USER?.userObj}
                  />
              }
            </Stack>}
        {isMobile &&
            <Stack

                sx={{
                  padding: '24px 16px',
                }}
            >
              {
                  players?.length &&
                  <MobileViewPlayers
                      players={players}
                      isMobile={isMobile}
                      loading={playersLoading}
                      teamSize={teamSize}
                      USER={USER?.userObj}
                  />
              }
            </Stack>}


      </MatchCardContainer>
  );
};

function MobileViewPlayers({players, isMobile, loading, teamSize, USER}) {
  const {t} = useTranslate();
  const isSlotDisable = players?.find(x => x?.email == USER?.email);

  return (
      <Stack direction={'row'}
             justifyContent={players?.length > 2 ? 'space-between' : 'center'}
             spacing={players?.length > 2 ? 0 : 2}
             alignItems={'flex-start'}>
        <MobileViewTeamRender t={t} list={players} team={1} loading={loading}
                              teamSize={teamSize} isMobile={isMobile}
                              isSlotDisable={isSlotDisable}/>
        <Typography variant="h6-bold" fontStyle={'italic'}>vs</Typography>
        <MobileViewTeamRender t={t} list={players} team={2} loading={loading}
                              teamSize={teamSize} isMobile={isMobile}
                              isSlotDisable={isSlotDisable}/>

      </Stack>
  );
}

const Players = ({t, players, isMobile, loading, teamSize, USER}) => {

  const isSlotDisable = players?.find(x => x?.email == USER?.email);
  return (
      <PlayerListContainer
          isMobile={isMobile}
          team1Padding={'.8rem'}
          team2Padding={'2rem'}
      >
        <TeamRender t={t} list={players} team={1} loading={loading}
                    teamSize={teamSize} isMobile={isMobile}
                    isSlotDisable={isSlotDisable}/>
        <TeamRender t={t} list={players} team={2} loading={loading}
                    teamSize={teamSize} isMobile={isMobile}
                    isSlotDisable={isSlotDisable}/>
      </PlayerListContainer>
  );
};

const MobileViewTeamRender = ({
                                t,
                                list,
                                team,
                                loading,
                                teamSize,
                                isMobile,
                                isSlotDisable,
                              }) => {

  const players = list?.filter((x) => x.team == team);

  const btnText = isMobile ?
      t('slot.available') :
      t('openMatches.page.player.slotAvailable');

  return (
      <Stack direction={'row'}
             spacing={1.5} alignItems={'flex-start'}
             justifyContent={'space-between'}
      >
        {players?.map((player, index) => {

          return (
              <>
                {loading ? (
                    <Skeleton width={'100%'} height={'100%'} sx={{mr: 'auto'}}/>
                ) : (
                    <AvatarIcon
                        t={t}
                        isCreate={false}
                        player={player}
                        canAddPartner={true}
                        btnText={btnText}
                        isDisabled={isSlotDisable}
                        teamSize={teamSize}
                        isMobile={isMobile}
                    />
                )}
              </>
          );
        })}
      </Stack>
  );
};

const TeamRender = ({
                      t,
                      list,
                      team,
                      loading,
                      teamSize,
                      isMobile,
                      isSlotDisable,
                    }) => {

  const players = list?.filter((x) => x.team == team);
  const btnText = t('openMatches.page.player.slotAvailable');

  return (
      <Stack
          className={`teamContainer team${team}`}
          sx={{
            '& .avatarContainer': {
              flexBasis: '250px',
              alignItems: 'flex-start',
            },
          }}
      >
        {players?.map((player, index) => {

          return (
              <>
                {loading ? (
                    <Skeleton width={'100%'} height={'100%'} sx={{mr: 'auto'}}/>
                ) : (
                    <AvatarIcon
                        t={t}
                        isCreate={false}
                        player={player}
                        canAddPartner={true}
                        btnText={btnText}
                        isDisabled={isSlotDisable}
                        teamSize={teamSize}
                        isMobile={isMobile}
                    />
                )}
              </>
          );
        })}
      </Stack>
  );
};

export default MatchCard;
