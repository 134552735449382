import { useState } from "react";
import {
    Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  SportsBaseballOutlined,
} from "@mui/icons-material";
import ExtrasSelect from "../../components/Bookings/Inputs/ExtrasSelect";


export const StyledAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    
    
    background:'transparent !important',
    marginTop:'1rem',
    paddingBottom:'1rem',
    // borderBottom:`1px solid ${theme.palette.divider}`,
    boxShadow:'none',
    borderRadius:'0 !important',
    
    '::before':{
        display:'none'
    },

    '.MuiAccordionSummary-root':{
        padding:0,
        minHeight:'auto',
    
    },
    '.MuiAccordionSummary-content':{
        margin:0,

        '.MuiSvgIcon-root[data-testid="SportsBaseballOutlinedIcon"]':{
            color:theme.palette.grey[500],
            marginRight:'10px'
        }
    },

    '.MuiAccordionDetails-root':{
        paddingRight:0,
        paddingLeft:'33px'
    }

}));

const AccordionExtras = ({ label, loading, extras }) => {
  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const theme = useTheme();

  if(loading){

    return (
      <Stack
      width={'100%'}
      direction={'row'}
      columnGap={1}
      >
          <Skeleton variant={'circular'} width={'23px'} height={'20px'}/>
          <Stack flex={'1 0 70%'}>
            <Typography variant={'overline'}>
              <Skeleton width={'60px'} height={'20px'}/>
            </Typography>
            <Skeleton width={'100%'} height={'20px'}/>
          </Stack>
      </Stack>
    )
  }
  return (
    <StyledAccordion
    padding={0}
    expanded={expanded === "panel1"}
    onChange={handleChange("panel1")}
    >
      <AccordionSummary id={`panel-header`} aria-controls={`panel-content`}>
        <SportsBaseballOutlined 
        sx={{fill: theme.palette.icon.primary}} 
        />
        <Stack direction={"row"} justifyContent={"space-between"} width="100%">
          <Typography>{label}</Typography>
          {expanded == "panel1" ? <ExpandLess /> : <ExpandMore />}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {loading && (
          <Skeleton width={"100%"} height={"200px"} sx={{ mr: "auto" }} />
        )}
        <ExtrasSelect isLoading={loading} availableExtras={extras} />
      </AccordionDetails>
    </StyledAccordion>
  );
};
export default AccordionExtras;
