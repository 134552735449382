import {Box, IconButton, Stack} from '@mui/material';
import LayoutMainHeaderBottom from './LayoutMainHeaderBottom';
import LayoutMainHeaderTop from './LayoutMainHeaderTop';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import LayoutMainPublicHeader from './LayoutMainPublicHeader';
import PlumpyLogout from '../../assets/icons/PlumpyLogout';
import useLogout from '../../hooks/onboarding/useLogout';

function LayoutMainHeader({navItems}) {

  const isAuthenticated = useIsAuthenticated();
  const {handleUserLogout} = useLogout();
  return (
      <Box
          component={'header'}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
      >
        {
          isAuthenticated &&<LayoutMainHeaderTop/>
        }
        {
          isAuthenticated &&
          <Stack direction={'row'} justifyContent={'space-between'}>
          <LayoutMainHeaderBottom navItems={navItems}/>

             <IconButton
             sx={{mr:6}}
          onClick={() => {
            handleUserLogout();
          }}
      >
        <PlumpyLogout />
      </IconButton>
          </Stack>
        }

      </Box>
  );
}

export default LayoutMainHeader;
