import {useContext} from 'react';
import {ContextExperience} from '../../providers/ProviderExperience';

function useExperienceContext() {

  const {
    clubDetails,
    companyDetails,
    clubPolicies,
    fetchClubPolicies,
    clubSports,
    clubTimings,
  } = useContext(ContextExperience) || {};

  return {
    clubDetails,
    clubPolicies,
    fetchClubPolicies,
    clubSports,
    clubTimings,
    companyDetails
  };
}

export default useExperienceContext;
