import { useEffect, useState } from "react"
import DialogBase from "../_Global/Dialogs/DialogBase"
import { useTranslate } from "@tolgee/react"
import { CircularProgress, ListItem, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { AddPlayerContentContainer, SuggestedPlayersList } from "../../vendor/mui/styled-components/OpenMatches"
import { useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { handleSearchPlayer } from "../../utils/openMatches"
import { useParams } from "react-router-dom"
import ProfileImageViewer from "../_Global/Profile/ProfileImageViewer"
import { getInitials } from "../../utils/ui"
import { setSuggestedPlayers } from "../../vendor/redux/actions/openMatches"
import useDebounce from "../../hooks/global/useDebounce"
import useIsMobile from "../../hooks/ui/useIsMobile"
import PlayerRatingChip from "./PlayerRatingChip"


const DialogAddPlayer=props=>{

    const {isOpen,setIsOpen,setPlayer,team,currentUser,sport,players} = props

    const {t} = useTranslate();
    const isMobile = useIsMobile();
    const params = useParams();
    const clubId = params?.clubId;

    const storeData = useSelector(state=>state.openMatches);
    const suggestedPlayersObj = storeData?.suggestedPlayers;
    const suggestedPlayers = suggestedPlayersObj?.list;
    const hasNoBuddies = suggestedPlayersObj?.hasNoBuddies;
    const loading = suggestedPlayersObj?.loading;
    
    const {formState:{errors}} = useFormContext();

    const [inputValue,setInputValue] = useState('');
    const debounceValue = useDebounce(inputValue,300);
    
    const handleClose=()=>{
        setIsOpen(false);
    }

    const handleInputChange=e=>{

        setInputValue(e.target.value);

    }

    function stringAvatar(name) {
        return {
          children: getInitials(name),
        };
    }

    // const addPlayerFromCustomers=()=>{

    //     const player = suggestedPlayers?.at(0);
    //     if(player){
    //         setPlayer({...player,firstName:'Player',lastName:'',team,addedBy:currentUser?.id,isAdded:true})
    //     }
    // }

    const getDialogContent=props=>{

        return(
            <AddPlayerContentContainer
            isMobile={isMobile}
            >
                
                <TextField
                size="small"
                col={12}
                name="emailToAdd"
                type="email"
                placeholder={'Email'}
                label={'Email'}
                errors={errors}
                required={true}
                onChange={handleInputChange}
                InputProps={{
                    endAdornment: loading &&
                        <CircularProgress size={'24px'}/>
                        // (
                        //     suggestedPlayers?.length && hasNoBuddies?
                        //     <PersonAddAlt
                        //     sx={{cursor:'pointer'}}
                        //     color="primary"
                        //     onClick={addPlayerFromCustomers}
                        //     />
                        //     :''
                        // )
                }}
                />
                
                <Typography
                variant="body2"
                fontSize={'16px'}
                marginTop={'1rem'}
                >
                    {t('openMatches.create.addPlayer.dialog.suggestedPlayer')}
                </Typography>
                {
                    suggestedPlayers?.length>0 &&
                    <SuggestedPlayersList>
                        {
                            suggestedPlayers?.map(player=>{

                                const name = `${player?.firstName} ${player?.lastName}`;
                                const isExist = !!player?.isAdded;

                                return (
                                  <Tooltip
                                  key={player?.email}
                                    title={
                                      isExist
                                        ? t(
                                            "openMatches.page.create.suggestionlist.alreadyExist"
                                          )
                                        : ""
                                    }
                                    placement={"top"}
                                    arrow
                                  >
                                    <ListItem
                                      key={player?.email}
                                      className={
                                        isExist ? "disabledPlayer" : ""
                                      }
                                      onClick={() =>
                                        setPlayer({
                                          ...player,
                                          team,
                                          addedBy: currentUser?.id,
                                          isAdded: true,
                                        })
                                      }
                                    >
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        columnGap={"1rem"}
                                      >
                                        <ProfileImageViewer
                                          size={"sm"}
                                          imgPath={player?.imageUrl}
                                          placeholderStyle={"initials"}
                                          text={name}
                                          stringAvatar={stringAvatar}
                                        />
                                        <Typography fontSize={"16px"}>
                                          {name}
                                        </Typography>
                                      </Stack>
                                      <PlayerRatingChip
                                        rating={player?.rating}
                                      />
                                    </ListItem>
                                  </Tooltip>
                                );
                            })
                        }
                    </SuggestedPlayersList>
                }

            </AddPlayerContentContainer>
        )

    }

    useEffect(()=>{

        if(isOpen){
            handleSearchPlayer({
            inputValue: debounceValue,
            clubId,
            sport,
            playersAdded:players
            })
        }
    },[isOpen,debounceValue])

    useEffect(()=>{

        if(!isOpen){
            setInputValue('')
            setSuggestedPlayers({list:[]})
        }
    },[isOpen])

    return(
        <DialogBase
        scroll="paper"
        isOpen={isOpen}
        onClose={handleClose}
        onExited={handleClose}
        title={t('openMatches.create.addPlayer.dialog.title')}
        contentSlot={getDialogContent()}
        dividers={true}
        dialogStyles={{width:'auto'}}
        />
    )

}

export default DialogAddPlayer