function IconNoTrainings() {
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_18413_156764)">
                <path opacity="0.35" d="M25.3333 1.83398C24.5973 1.83398 24 2.42998 24 3.16732C24 3.90465 24.5973 4.50065 25.3333 4.50065C26.0693 4.50065 26.6667 3.90332 26.6667 3.16732C26.6667 2.43132 26.0693 1.83398 25.3333 1.83398Z" fill="#757575" />
                <path opacity="0.35" d="M12 7.832C12 9.67333 13.492 11.1667 15.332 11.1667C17.176 11.1667 18.6667 9.67333 18.6667 7.832C18.6667 5.99333 17.176 4.5 15.332 4.5C13.492 4.5 12 5.99333 12 7.832Z" fill="#757575" />
                <path d="M13.333 15.832C13.333 17.6733 14.825 19.1667 16.665 19.1667C18.509 19.1667 19.9997 17.6733 19.9997 15.832C19.9997 13.9933 18.509 12.5 16.665 12.5C14.825 12.5 13.333 13.9933 13.333 15.832Z" fill="#757575" />
                <path d="M19.9281 15.1445L13.4268 16.5899L14.8081 20.6752L21.7361 21.0565L19.9281 15.1445Z" fill="#757575" />
                <path d="M23.7757 23.7608L21.3143 20.0688C21.3143 20.0688 20.3797 18.4674 19.061 18.0221L18.4423 17.0941C18.033 16.4808 17.2063 16.3154 16.593 16.7248C16.0357 17.0968 15.873 17.8074 16.1503 18.3954C15.2557 18.9928 14.777 20.5514 14.777 20.5514L13.397 29.4208L13.3837 29.4674C13.3757 29.4941 13.3837 29.5208 13.377 29.5474L13.333 29.8341L13.3583 29.7968C13.3423 30.3901 13.7023 30.9448 14.301 31.1168C14.4223 31.1501 14.545 31.1674 14.6677 31.1674C15.2477 31.1674 15.781 30.7861 15.949 30.1994L17.9597 23.1621C18.081 22.6288 18.8343 22.5088 19.197 22.9448L20.8663 24.8128L20.0397 29.5101C19.8597 30.2234 20.2943 30.9474 21.0103 31.1261C21.1183 31.1541 21.2277 31.1674 21.3343 31.1674C21.9317 31.1674 22.4743 30.7634 22.6263 30.1568L23.9597 24.8234C24.0503 24.4594 23.9837 24.0741 23.7757 23.7608Z" fill="#757575" />
                <path d="M9.33375 17.8328C9.07242 17.8328 8.81508 17.7554 8.59375 17.6088L4.59375 14.9434C3.98175 14.5354 3.81508 13.7074 4.22442 13.0941C4.63108 12.4808 5.45775 12.3154 6.07375 12.7248L9.61375 15.0848L15.7391 13.8608C16.4671 13.7128 17.1644 14.1834 17.3084 14.9061C17.4524 15.6288 16.9844 16.3301 16.2631 16.4754L9.59642 17.8074C9.50842 17.8234 9.42042 17.8328 9.33375 17.8328Z" fill="#757575" />
                <path d="M18.6678 16.5009C18.1785 16.5009 17.7078 16.2315 17.4732 15.7635C17.1438 15.1049 17.4105 14.3035 18.0692 13.9742L22.9092 11.5542L24.0678 8.07954C24.3012 7.38221 25.0492 7.00354 25.7545 7.23688C26.4518 7.46888 26.8305 8.22354 26.5972 8.92354L25.2638 12.9222C25.1532 13.2569 24.9118 13.5342 24.5958 13.6929L19.2625 16.3609C19.0718 16.4555 18.8678 16.5009 18.6678 16.5009Z" fill="#757575" />
                <path d="M16.2783 12.5293L22.8103 11.4493L24.0117 8.25195L23.277 12.5706L17.081 15.072L16.2783 12.5293Z" fill="#757575" />
                <path d="M15.4253 12.7441L8.70801 15.4108L16.1 15.1668L15.4253 12.7441Z" fill="#757575" />
                <path opacity="0.35" d="M7.9617 5.78825C7.51503 3.24958 5.38836 1.50291 3.21236 1.88691C1.03636 2.27091 -0.363637 4.63891 0.08303 7.17758C0.344363 8.65625 1.17636 9.85625 2.25636 10.5282C3.04836 11.0202 3.60836 11.8069 3.7697 12.7242L3.98303 13.9349L6.6097 13.4722L6.39636 12.2616C6.2337 11.3429 6.49103 10.4122 7.0657 9.67891C7.85103 8.67891 8.22303 7.26691 7.9617 5.78825Z" fill="#757575" />
                <path d="M6.03184 17.8135C5.30651 17.9415 4.61451 17.4575 4.48784 16.7322L3.77051 12.7322L6.39717 12.2695L7.11451 16.2695C7.24117 16.9935 6.75717 17.6855 6.03184 17.8135Z" fill="#757575" />
            </g>
            <defs>
                <clipPath id="clip0_18413_156764">
                    <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )


}
export default IconNoTrainings;