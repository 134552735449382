import {useEffect, useState} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {Button, Skeleton, Stack, Typography, useTheme} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import DialogSection from '../../components/_Global/Dialogs/DialogSection';
import {
  Payment,
  PaymentsOutlined,
  ReceiptLongOutlined,
  SellOutlined,
} from '@mui/icons-material';
import {useDispatch} from 'react-redux';
import {
  customersOffersPurchase_POST,
  customersOffersSingle_GET,
} from '../../vendor/redux/actions/wallet';

import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';
import genUid from 'light-uid';
import useSocket from '../../hooks/sockets/useSocket';
import useLocale from '../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import {getLocalizedNum} from '../../utils/localization';
import DialogBase from '../../components/_Global/Dialogs/DialogBase';
import { offersEvents } from '../../utils/analyticsEvents';
import useDefaults from '../../hooks/defaults/useDefaults';

const socketUUID = genUid();

function DialogWalletAdd({open, onPurchaseOfferRedirectUrl, onAfterExitAnimation}) {

  const {socket} = useSocket();

  const {t} = useTranslate();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {currencySymbol} = useDefaults();
  const theme = useTheme();

  const {locale} = useLocale();
  dayjs.locale(locale);

  const [searchParams] = useSearchParams();
  const offerId = searchParams.get('offerId');

  const [offer, setOffer] = useState();
  const [isOpen, setIsOpen] = useState(open);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [priceData, setPriceData] = useState(null);

  const isLoading = !offer || !priceData;

  function fetchAndSetOffer() {

    dispatch(customersOffersSingle_GET(offerId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersOffersSingle_GET Success', res);
      setOffer(res.data.data);
    }

    function cbFail(e) {
      console.log('customersOffersSingle_GET Fail', e);
    }
  }

  function handleModalAfterExitAnimation() {
    onAfterExitAnimation()
  }

  function handleProceedToPayment() {
    setIsSubmitting(true);

    dispatch(
        customersOffersPurchase_POST(offerId, onPurchaseOfferRedirectUrl, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersOffersPurchase_POST Success', res);
      window.location.href = res.data.data.checkoutUrl;
    }

    function cbFail(e) {
      console.log('customersOffersPurchase_POST Fail', e);
      setIsSubmitting(false);
      enqueueSnackbar(t('player.wallet.detail.add.checkout.fail.message'),
          {variant: 'error'});
    }
  }

  function handleClose() {
    setIsOpen(false);
  }

  useEffect(() => {
    fetchAndSetOffer();
  }, []);

  // Sockets Listen for price change
  useEffect(() => {
    socket.on('new_price', (id, data) => {
      if (id === socketUUID) {
        if (data.status === 'error') {
          console.log('Socket new_price error', data);
        } else {
          console.log('Socket new_price data received', data);
          setPriceData(data);
        }
      }
    });

    return () => {
      socket.off('new_price');
    };

  }, []);

  // Sockets Emit Price Change
  useEffect(() => {
    const body = {
      type: 'offer',
      offerId: offerId,
    };
    console.log('socket calculate_price emit body', body);
    socket.emit('calculate_price', socketUUID, body);
  }, []);

  return (
      <DialogBase
          isOpen={isOpen}
          isSubmitting={isSubmitting}
          isLoading={isLoading}
          onClose={handleClose}
          onExited={handleModalAfterExitAnimation}
          title={t('player.wallet.dialog.title')}
          contentSlot={
            <>
              <DialogSection
                  isLoading={isLoading}
                  label={t('player.wallet.dialog.section.offer')}
                  icon={SellOutlined}
              >
                <Stack>
                  <Typography>
                    {
                        isLoading &&
                        <Skeleton width={'200px'}/>
                    }
                    {
                        !isLoading &&
                        `${t(
                            'player.wallet.detail.offer.pay')} ${currencySymbol}${getLocalizedNum(
                            locale, +offer?.offerAmount)} ${t(
                            'player.wallet.detail.offer.get')} ${currencySymbol}${getLocalizedNum(
                            locale, +offer?.offerValue)}`
                    }
                  </Typography>
                  <Typography variant={'body2'}>
                    {
                        !isLoading &&
                        offer.description || ''
                    }
                  </Typography>
                </Stack>
              </DialogSection>
              <DialogSection
                  isLoading={isLoading}
                  label={t('player.wallet.dialog.section.amount')}
                  icon={PaymentsOutlined}
              >
                <Typography>
                  {
                      isLoading &&
                      <Skeleton width={'200px'}/>
                  }
                  {
                      !isLoading &&
                      `${currencySymbol}${getLocalizedNum(locale,
                          +offer.offerAmount)}`
                  }
                </Typography>
              </DialogSection>
              <DialogSection
                  isLoading={isLoading}
                  label={t('player.wallet.dialog.section.value')}
                  icon={Payment}
              >
                <Typography>
                  {
                      isLoading &&
                      <Skeleton width={'200px'}/>
                  }
                  {
                      !isLoading &&
                      `${currencySymbol}${getLocalizedNum(locale,
                          +offer.offerValue)}`
                  }
                </Typography>
              </DialogSection>
              <DialogSection
                  isLoading={isLoading}
                  label={t('player.wallet.dialog.section.billing')}
                  icon={ReceiptLongOutlined}
                  isLastChild={true}
              >
                <Stack spacing={.5}>
                  <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      sx={{
                        backgroundColor: theme.palette.grey[50],
                      }}
                  >
                    <Typography color={'text.secondary'}>
                      {
                          isLoading &&
                          <Skeleton width={'200px'}/>
                      }
                      {
                          !isLoading &&
                          t('finances.wallet.detail.billing.amount')
                      }
                    </Typography>
                    <Typography color={'text.secondary'}>
                      {
                          isLoading &&
                          <Skeleton width={'80px'}/>
                      }
                      {
                          !isLoading &&
                          `${currencySymbol}${getLocalizedNum(
                              locale,
                              +offer.offerAmount)}`
                      }
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography color={'text.secondary'}>
                      {
                          isLoading &&
                          <Skeleton width={'200px'}/>
                      }
                      {
                          !isLoading &&
                          `${t(
                              'player.wallet.detail.add.platformCharges')} ${priceData?.platformPercentage}`
                      }
                    </Typography>
                    <Typography color={'text.secondary'}>
                      {
                          isLoading &&
                          <Skeleton width={'80px'}/>
                      }
                      {
                          !isLoading &&
                          `${currencySymbol}${getLocalizedNum(
                              locale,
                              +priceData?.platformFee)}`
                      }
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant={'h6'}>
                      {
                          isLoading &&
                          <Skeleton width={'200px'}/>
                      }
                      {
                          !isLoading &&
                          t('player.wallet.detail.add.totalBilling')
                      }
                    </Typography>
                    <Typography variant={'h6'}>
                      {
                          isLoading &&
                          <Skeleton width={'80px'}/>
                      }
                      {
                          !isLoading &&
                          `${currencySymbol}${getLocalizedNum(
                              locale,
                              +priceData?.totalPrice)}`
                      }
                    </Typography>
                  </Stack>
                </Stack>
              </DialogSection>
            </>
          }
          actionsSlot={<>
            {
                isLoading &&
                [0, 0].map(() => {
                  return <Skeleton height={'60px'} width={'120px'}/>;
                })
            }
            {
                !isLoading &&
                <>
                  <Button
                  color='inherit'
                  size={'large'}
                  variant={'outlined'}
                  onClick={handleClose}
                  >
                    {t('global.buttons.actions.close')}
                  </Button>
                  <LoadingButton
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      size={'large'}
                      variant={'contained'}
                      onClick={handleProceedToPayment}
                  >
                  <span>
                  {t('global.buttons.actions.proceedToPayment')}
                  </span>
                  </LoadingButton>
                </>
            }
          </>
          }
      />

  );

}

export default DialogWalletAdd;
