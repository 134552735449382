
export function typography(theme) {
  return {
    fontFamily:"Public Sans, sans-serif",
    'body2': {color: theme.palette.text.secondary},
    h1:{
      fontSize:"96px",
      fontWeight:"300",
    },
    h2:{
      fontSize:"60px",
      fontWeight:"300",
    },
    h3:{
      fontSize:"48px",
      fontWeight:"400",
    },
    h4:{
      fontSize:"34px",
      fontWeight:"400",
    },
    h5:{
      fontSize:"24px",
      fontWeight:"400",
    },
    "h5-bold":{
      fontSize:"24px",
      fontWeight:"700",
    },
    h6:{
      fontSize:"20px",
      fontWeight:"500",
    },
    "h6-bold":{
      fontSize:"20px",
      fontWeight:"700",
    },
    subtitle1:{
      fontSize:"17px",
      fontWeight:"400",
    },
    subtitle2:{
      fontSize:"14px",
      fontWeight:"500",
    },
    "body1-emphasis": {
      fontSize: "16px",
      fontWeight:"500",
    }
  };
}
