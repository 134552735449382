function IconNoEvents() {
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_18413_156744)">
                <path opacity="0.35" d="M20 25.8333C20 22.888 22.388 20.5 25.3333 20.5C26.3093 20.5 27.2133 20.7813 28 21.24V8.5H4V24.5C4 26.7093 5.79067 28.5 8 28.5H20.74C20.2813 27.7133 20 26.8093 20 25.8333Z" fill="#757575" />
                <path d="M24 4.5H8C5.79067 4.5 4 6.29067 4 8.5V9.83333H28V8.5C28 6.29067 26.2093 4.5 24 4.5Z" fill="#757575" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8212 20.1707L22.0145 17.904C22.2412 17.6693 22.3198 17.3293 22.2198 17.0187C22.1185 16.708 21.8545 16.4787 21.5332 16.4227L18.4265 15.8787C18.3905 15.872 18.3558 15.8573 18.3265 15.836C18.2972 15.8147 18.2732 15.7867 18.2558 15.7547L16.7785 12.968C16.6265 12.68 16.3265 12.5 15.9998 12.5C15.6732 12.5 15.3732 12.68 15.2212 12.968L13.7438 15.7547C13.7265 15.7867 13.7025 15.8147 13.6732 15.836C13.6438 15.8573 13.6092 15.872 13.5732 15.8787L10.4665 16.4227C10.1452 16.4787 9.88116 16.708 9.77982 17.0187C9.67849 17.3293 9.75716 17.6693 9.98516 17.904L12.1785 20.1707C12.2038 20.1973 12.2225 20.228 12.2345 20.264C12.2465 20.2987 12.2492 20.336 12.2438 20.372L11.8012 23.4947C11.7558 23.8173 11.8918 24.14 12.1558 24.332C12.4198 24.524 12.7678 24.5533 13.0612 24.4107L15.8945 23.0253C15.9278 23.0093 15.9638 23.0013 15.9998 23.0013C16.0358 23.0013 16.0718 23.0093 16.1052 23.0253L18.9385 24.4107C19.2318 24.5547 19.5798 24.524 19.8438 24.332C20.1078 24.14 20.2438 23.8187 20.1985 23.4947L19.7558 20.372C19.7505 20.336 19.7532 20.2987 19.7652 20.264C19.7772 20.2293 19.7958 20.1973 19.8212 20.1707Z" fill="#757575" />
                <path opacity="0.35" d="M25.3333 31.1667C28.2789 31.1667 30.6667 28.7789 30.6667 25.8333C30.6667 22.8878 28.2789 20.5 25.3333 20.5C22.3878 20.5 20 22.8878 20 25.8333C20 28.7789 22.3878 31.1667 25.3333 31.1667Z" fill="#757575" />
                <path d="M25.3337 19.166C21.6523 19.166 18.667 22.1513 18.667 25.8327C18.667 29.514 21.6523 32.4993 25.3337 32.4993C29.015 32.4993 32.0003 29.514 32.0003 25.8327C32.0003 22.1513 29.015 19.166 25.3337 19.166ZM28.8257 25.3247L25.4923 28.658C25.2723 28.878 24.979 28.9993 24.667 28.9993C24.355 28.9993 24.0617 28.878 23.8417 28.658L21.8417 26.658C21.387 26.202 21.387 25.4633 21.8417 25.0073C22.2963 24.554 23.0363 24.554 23.491 25.0073L24.6657 26.1833L27.1737 23.674C27.6283 23.2207 28.3683 23.2207 28.823 23.674C29.2803 24.13 29.2803 24.8687 28.8257 25.3247Z" fill="#757575" />
            </g>
            <defs>
                <clipPath id="clip0_18413_156744">
                    <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )


}
export default IconNoEvents;