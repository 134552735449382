import { lazy } from 'react';
import {Route} from 'react-router-dom';
const PageEventsListsRegistered = lazy(() => import('../pages/Events/PageEventsListsRegistered'));
const PageClubExperienceEventDetail = lazy(() => import('../pages/Events/PageClubExperienceEventDetail'));
const PageEventRegister = lazy(() => import('../pages/Events/PageEventRegister'));
const LoggedInEventPaymentInfo = lazy(() => import('../pages/PaymentStatus/LoggedInEventPaymentInfo'));
const PageEventDetailLegacy = lazy(() => import('../pages/Events/PageEventDetailLegacy'));
const PageEventsRegisterLegacy = lazy(() => import('../pages/Events/PageEventsRegisterLegacy'));

const RoutesEvents = (
    <>
      <Route path={`/myEvents`} element={<PageEventsListsRegistered/>}/>
      <Route path={`/events/event-detail/*`} element={<PageEventDetailLegacy/>}/>
      <Route path={`/events/register`} element={<PageEventsRegisterLegacy/>}/>
      <Route path="/events/event-status/:token"
             element={<LoggedInEventPaymentInfo/>}/>
    </>
);

export default RoutesEvents;
