import {Skeleton, Stack, Typography} from '@mui/material';
import {formatAddress} from '../../../utils/address';

function BookingsClubDetails({
                               clubName,
                               clubStreet,
                               houseNumber,
                               clubPostalCode,
                               clubCity,
                               isLoading,
                             }) {
  return <>
    {
        isLoading && <Skeleton width={'256px'} height={'24px'}/>
    }
    {
        !isLoading &&
        <Stack>
          <Typography
              variant={'body1'}
              sx={{userSelect: 'none'}}
          >
            {clubName}
          </Typography>
          <Typography variant={'body2'}>
            {formatAddress(clubStreet,houseNumber,clubPostalCode,clubCity)}
          </Typography>
        </Stack>
    }
  </>;
}

export default BookingsClubDetails;
;
