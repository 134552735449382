import {Skeleton, Stack, Typography, useMediaQuery} from '@mui/material';

function BookingsDateTimeDetails({
  date = '',
  timeStart = '',
  timeEnd = '',
  isLoading,
}) {

  const mobileCollapse = useMediaQuery(`(max-width: 334px)`)

  return <>
    {
        isLoading && <Skeleton width={'256px'} height={'24px'}/>
    }
    {
        !isLoading &&
        <Stack mr={1} direction={!mobileCollapse ? 'row' : 'column'} alignItems={!mobileCollapse ? 'center' : 'flex-start'} spacing={!mobileCollapse ? 1 : 0}>
          <Typography
              variant={'body1'}
              sx={{userSelect: 'none'}}
          >{date}</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={'4px'}>
            <Typography
                variant={'body1'}
                sx={{userSelect: 'none'}}
            >{timeStart}</Typography>
            <span>-</span>
            <Typography
                variant={'body1'}
                sx={{userSelect: 'none'}}
            >{timeEnd}</Typography>
          </Stack>
        </Stack>

    }
  </>;
}

export default BookingsDateTimeDetails;
