import {Controller, useFormContext} from 'react-hook-form';
import {useEffect} from 'react';
import {
  Alert,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Tooltip,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import { bookingEvents } from '../../../utils/analyticsEvents';

export function PaymentTypeSelect({
                                    isLoading = false,
                                    isDisabled = false,
                                    discountApplied,
                                    disableSplitOption = false,
                                  }) {

  const {t} = useTranslate();
  const {control, trigger, formState, watch, setValue} = useFormContext();
  const watched = watch();


  useEffect(() => {
    if (formState.isSubmitted) {
      trigger('players');
    }
  }, [watched.paymentType]);

  useEffect(()=>{

    if(watched?.paymentType=='single'){
      bookingEvents['paymentTypeSingle']()
    }else if(watched?.paymentType=='split'){
      bookingEvents['paymentTypeSplit']()
    }

  },[watched?.paymentType])

  return (
      <>
        {
            isLoading &&
            <Skeleton sx={{
              width: '200px',
              height: '20px',
            }}/>
        }
        {
            !isLoading &&
            <>
            <Controller
                name={'paymentType'}
                control={control}
                defaultValue={'single'}
                render={
                  ({field}) => {
                    return (
                        <RadioGroup row {...field}>
                           <FormControlLabel
                                value="single"
                                control={<Radio/>}
                                label={t(
                                    'global.components.paymentTypeRadio.single')}
                                disabled={isDisabled}
                            />
                          <Tooltip title={disableSplitOption &&
                              t('global.components.paymentTypeRadio.split.disabled')}
                                   arrow placement={'top'}>
                            <FormControlLabel
                                value="split"
                                control={<Radio/>}
                                label={t(
                                    'global.components.paymentTypeRadio.split')}
                                disabled={disableSplitOption || isDisabled}
                            />
                          </Tooltip>
                        </RadioGroup>
                    );
                  }}
            />
           {discountApplied && watched.paymentType === 'single' &&
             <Alert severity='info'>
              {t('global.component.payment.discount.info')}
            </Alert>}
            {discountApplied && watched.paymentType === 'split' &&
             <Alert severity='success'>
              {t('global.component.payment.discount.success')}
            </Alert>}
          </>
        }
      </>
  );
}
