import {Box, Menu, MenuItem, Typography, useTheme} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {useState} from 'react';
import ProfileImageViewer from '../Profile/ProfileImageViewer';
import useUser from '../../../hooks/access/useUser';
import useLogout from '../../../hooks/onboarding/useLogout';

function SiteUserProfile({
                           type,
                           size = 'xs',
                           onLogoutSuccess = () => {
                           },
                         }) {

  const theme = useTheme();
  const {t} = useTranslate();

  const selectedUser = useUser();
  const userObject = selectedUser?.userObj;

  const {handleUserLogout} = useLogout(onLogoutSuccess);

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  return (<Box
      sx={{
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
      }}
  >
    <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={handleOpenMenu}
    >
      <ProfileImageViewer
          size={size}
          imgPath={userObject?.imageUrl}
          placeholderStyle={'user-icon'}
      />

    </Box>
    <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            mt: 1,
          },
        }}
        MenuListProps={{
          disablePadding: true,
          sx: {
            minWidth: '200px',
          },
        }}
    >
      <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
          onClick={() => {
            handleCloseMenu();
          }}
      >
        <Typography variant={'body1'}>
          {`${userObject?.firstName || ''} ${userObject?.lastName || ''}`}
        </Typography>
        <Typography variant={'caption'}>
          {userObject?.email}
        </Typography>
      </MenuItem>

      {/* {type === 'club' &&
       <MenuItem
          sx={{
            paddingTop: '16px',
            paddingBottom: '16px',
          }}
          onClick={() => {
            window.open('/', '_blank', 'noopener,noreferrer')
          }}
      >
          <Typography variant={'button'}>
          {t('customerProfile.tabs.profile.account')}
        </Typography>
      </MenuItem>} */}
      <MenuItem
          sx={{
            paddingTop: '16px',
            paddingBottom: '16px',
          }}
          onClick={() => {
            handleCloseMenu();
            handleUserLogout();
          }}
      >

        <Typography variant={'button'}>
          {t('authFlow.logout')}
        </Typography>
      </MenuItem>

    </Menu>

  </Box>);
}

export default SiteUserProfile;
