import {useNavigate, useParams} from 'react-router-dom';
import useUser from '../../hooks/access/useUser';
import {useSelector} from 'react-redux';
import moment from 'moment-timezone';
import {Box, Button, Grid, Skeleton, Stack, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import MatchCard from './ListingMatchCard';
import {useTranslate} from '@tolgee/react';
import {refreshStates} from '../../vendor/redux/actions/openMatches';
import PageCard from '../_Global/Page/PageCard';
import PageCardTitle from '../_Global/Page/PageCardTitle';
import Scrollbar from '../_Global/ScrollBar/ScrollBar';
import {getListingData} from '../../utils/openMatches';
import useBreakPoint from '../../hooks/ui/useBreakpoint';
import CreateMatchBtn from './CreateMatchBtn';
import OpenMatchLandingPageCard from './OpenMatchLandingPageCard';
import IconNoOpenMatches from '../../assets/icons/IconNoOpenMatches';
import useIsMobile from '../../hooks/ui/useIsMobile';

const ClubInfoOpenMatchesList = ({alwaysMobileView = false}) => {

  const theme = useTheme();
  const {t} = useTranslate();
  const navigateTo = useNavigate();
  const isMobile = useIsMobile();
  const {clubId} = useParams();
  const {isMdDown} = useBreakPoint();
  const storeData = useSelector((state) => state.openMatches);

  const listing = storeData?.listing;

  const data = listing?.data;
  const loading = listing?.loading;
  const filters = listing?.filters;
  const date = filters?.date;
  const sport = filters?.sport;
  const gender = filters?.gender;
  const selectedClubs = filters?.clubIds;
  const page = filters?.page;
  const limit = filters?.limit;

  const currentDate = moment()?.format('YYYY-MM-DD');

  const [matches, setMatches] = useState([]);

  useEffect(() => {
    getListingData({
      sport: '',
      page,
      limit: 4,
      clubId,
      date: currentDate,
      gender,
      selectedClubs,
      rows: data,
      setListingArray: setMatches,
    });

    return () => refreshStates();
  }, [sport, date, gender, selectedClubs]);

  return (
      <Box className={'item'}>
        <PageCard>
          <PageCardTitle
              title={t('bookings.list.tabs.openMatches')}
              route={`/club/${clubId}/openMatches`}
              titleMeta={
                !loading && matches.length != 0 &&
                (
                <Stack
                direction={'row'}
                alignItems={'center'}
                columnGap={1}
                >
                  <CreateMatchBtn
                  btnSize='large'
                  variant='text'
                  />

                  <Box
                  sx={{height:'20px',width:'2px',background:theme.palette.divider}}
                  />
                  <Button
                    onClick={() => {
                      navigateTo(`/club/${clubId}/openMatches`);
                    }}
                    variant={'text'}
                  >
                    {t('global.buttons.viewAll')}
                  </Button>
                </Stack>
                )
              }
          />

          <Box sx={{
            height: '558px',
            ...(isMdDown && {
              height: 'auto',
              minHeight: '200px',
            }),
          }}>
            <Scrollbar autoHide={false}>
              {
                  loading &&
                  <Grid container spacing={2}>
                    {
                      Array(3).fill(0)?.map((item, index) => (
                          <Grid item xs={12} key={index}>
                            <Skeleton height={'100px'} width={'100%'} sx={{
                              transform: 'scale(1,1)',
                            }}/>
                          </Grid>
                      ))
                    }
                  </Grid>
              }
              {
                  !loading && matches.length === 0 &&
                  <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{mt:isMobile ? '12%' :'32%'}} rowGap={1}>
                  <IconNoOpenMatches color={'disabled'} fontSize='medium'/>  
                  <Typography variant="subtitle2" color={theme.palette.grey[600]} >
                  {t('club.noOpenMatches')}
                  </Typography>
                  <CreateMatchBtn isData={true} />
                  </Stack>

              }
              {
                  !loading && matches?.length > 0 &&
                  <Grid container>
                    {
                      matches?.map((match, index) => (
                          <Grid item xs={12} key={index}>
                            <OpenMatchLandingPageCard
                                isLandingPageSection={true}
                                alwaysMobileView={alwaysMobileView}
                                match={match}
                                clubId={match?.club?.id}
                                customTypographyVariant={'body1'}
                                cardBodyStyles={{
                                  '& .teamContainer .avatarContainer': {
                                    flexBasis: '200px',
                                  },
                                }}
                            />
                          </Grid>
                      ))
                    }
                  </Grid>
              }
            </Scrollbar>
          </Box>

        </PageCard>
      </Box>
  );

};
export default ClubInfoOpenMatchesList;
