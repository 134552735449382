import {Box, Stack, Typography, useTheme} from '@mui/material';
import React, {useMemo} from 'react';
import {useTranslate} from '@tolgee/react';
import useLocale from '../../../hooks/localization/useLocale';

export function PackageSliderSlideBenefit({benefit}) {

  const theme = useTheme();
  const {t} = useTranslate();

  const value = benefit?.value;
  const tolgeeKey = benefit?.key;

  return (
      <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
      >
        <Box sx={{
          minWidth: '12px',
          maxWidth: '12px',
          width: '100%',
          height: '12px',
          backgroundColor: theme.palette.success.main,
          borderRadius: '50%',
        }}></Box>
        <Typography sx={{
          'display': '-webkit-box',
          'WebkitLineClamp': '1',
          'WebkitBoxOrient': 'vertical',
          'overflow': 'hidden',
        }}>
          {`${value ? `${value} ` : ''}${t(tolgeeKey)}`}
        </Typography>
      </Stack>
  );
}