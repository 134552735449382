import {Stack, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

function PageCardTitle({className,title, variant = 'h5-bold', titleMeta, route,padding,mb=2}) {

  const navigateTo = useNavigate();

  return (
      <Stack
      className={className}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={mb}
          padding={padding}
      >
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={() => {
              if (route) {
                navigateTo(route);
              }
            }}
        >
          <Typography
              variant={variant}
              sx={{
                cursor: 'pointer',
              }}
          >
            {title}
          </Typography>
        </Stack>

        {titleMeta}
      </Stack>
  );
}

export default PageCardTitle;
