import {useTranslate} from '@tolgee/react';
import {Box, Skeleton, Stack, Typography, useTheme} from '@mui/material';
import useLocale from '../../../hooks/localization/useLocale';
import moment from 'moment-timezone';
import {PackageBenefitDetailsRow} from './PackageBenefitDetailsRow';
import PackageBenefitDetailsDiscounts from './PackageBenefitDetailsDiscounts';

export function PackageBenefitDetails({
                                        isLoading = false,
                                        packageName,
                                        packageDescription,
                                        packageBenefitsList,
                                        discounts,
                                      }) {

  const {t} = useTranslate();
  const theme = useTheme();
  const {locale} = useLocale();

  moment.locale(locale);

  if (isLoading) {
    return (
        <Skeleton
            width={'100%'}
            height={'200px'}
            sx={{
              transform: 'scale(1, 1)',
            }}
        />
    );
  }

  if (!isLoading) {
    return (
        <Box
            sx={{
              padding: 3,
              mb: .5,
              backgroundColor: theme.palette.grey[100],
              borderRadius: 2,
            }}
        >
          <Stack spacing={.5} mb={2}>
            <Typography variant={'h5-bold'}>
              {packageName}
            </Typography>
            <Typography color={'text.secondary'}>
              {packageDescription}
            </Typography>
          </Stack>
          {
            packageBenefitsList?.map((b,i) => {

              const isLastChild = i === (packageBenefitsList?.length - 1);

              return <PackageBenefitDetailsRow
                  key={i}
                  title={t(b.title)}
                  description={t(b.description)}
                  supportedSports={b?.supportedSports}
                  value={`${b.value} ${b.valueKey ? t(b.valueKey) : ''}`}
                  showDivider={!isLastChild}
                  tooltip={ isLastChild && t('package.purchase.discount.tooltip')}
              />;
            })}
          {
              discounts &&
              <PackageBenefitDetailsDiscounts discounts={discounts}/>

          }
        </Box>
    );
  }

}

