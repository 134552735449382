import {useTranslate} from '@tolgee/react';
import {
  Badge,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemText,
  ListSubheader,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {filtersEvents} from '../../../utils/analyticsEvents';
import {
  Cancel,
  FilterList,
  FilterListOutlined,
  HighlightOff,
} from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import {useSlotSearchV2Context} from '../ProviderSlotSearchV2';

function SlotSearchV2HeaderFilterSelect() {

  const {t} = useTranslate();

  const {
    appliedFilters,
    handleApplyFilters,
    selectedSport,
    isTablet,
    clubSports,
  } = useSlotSearchV2Context();

  const [selectedFilters, setSelectedFilters] = useState(appliedFilters);
  const [anchorEl, setAnchorEl] = useState(null);

  const hasFilters = appliedFilters?.length > 0;

  const isOpen = Boolean(anchorEl);

  const optionsGroups = useMemo(() => {
    //filter selected sport option group
    const group = clubSports?.find((sportGroup) => {
      return sportGroup.sport === selectedSport;
    }) || {};

    return {
      ...(group?.courtTypes?.length > 0) && {type: group.courtTypes},
      ...(group?.courtSizes?.length > 0) && {size: group.courtSizes},
      ...(group?.courtFeatures?.length > 0) && {features: group.courtFeatures},
      ...(group?.courtTurfs?.length > 0) && {surface: group.courtTurfs},
    };

  }, [selectedSport, clubSports]);

  function removeFilter(event, option) {
    const filtered = appliedFilters.filter((filter) => {
      return filter !== option;
    });
    setSelectedFilters(filtered);
    handleApplyFilters(filtered);
  }

  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
    filtersEvents['filterOpen']();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  // Reset Selected Filters When Sport is changed
  useEffect(() => {
    setSelectedFilters([]);
  }, [selectedSport]);

  return (
      <>
        {
          <DesktopFilterSelect
              hasFilters={hasFilters}
              appliedFilters={appliedFilters}
              handleOpen={handleOpen}
          />
        }
        {
            !isTablet &&
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                slotProps={{
                  paper: {
                    sx: {
                      width: '350px',
                    },
                  },
                }}
                TransitionProps={{
                  onExited: () => {
                    setSelectedFilters(appliedFilters);
                  },
                }}
            >
              <Box
                  sx={{
                    height: '320px',
                    overflowY: 'scroll',
                  }}
              >
                <OptionsList
                    optionsGroups={optionsGroups}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                />
              </Box>
              <Stack
                  direction={'row'}
                  justifyContent={'flex-end'}
                  spacing={1}
                  px={1}
                  py={2}
              >
                <Button
                    variant={'outlined'}
                    size={'small'}
                    startIcon={<HighlightOff/>}
                    onClick={() => {
                      setSelectedFilters([]);
                      handleApplyFilters([]);
                      handleClose();
                      filtersEvents['clearFilter']();
                    }}
                >
                  {t('global.buttons.actions.clearFilters')}
                </Button>
                <Button
                    variant={'contained'}
                    size={'small'}
                    onClick={() => {
                      handleClose();
                      handleApplyFilters(selectedFilters);
                      filtersEvents['applyFilter']();
                    }}
                >
                  {t('global.buttons.actions.applyFilters')}
                </Button>
              </Stack>
            </Popover>
        }
        {
          isTablet &&
            <Dialog
                open={isOpen}
                scroll={'paper'}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
                keepMounted={false}
                TransitionProps={{
                  onExited: () => {
                    setSelectedFilters(appliedFilters);
                  },
                }}

            >
              <DialogTitle>{t('schedule.filter.filterBy')}</DialogTitle>
              <DialogContent
                  sx={{
                    padding: '0px 8px',
                  }}
              >
                <OptionsList
                    optionsGroups={optionsGroups}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                />
              </DialogContent>
              <DialogActions>
                <Button
                    variant={'outlined'}
                    size={'large'}
                    startIcon={<HighlightOff/>}
                    onClick={() => {
                      setSelectedFilters([]);
                      handleApplyFilters([]);
                      handleClose();
                    }}
                >
                  {t('global.buttons.actions.clearFilters')}
                </Button>
                <Button
                    variant={'contained'}
                    size={'large'}
                    onClick={() => {
                      handleClose();
                      handleApplyFilters(selectedFilters);
                    }}
                >
                  {t('global.buttons.actions.applyFilters')}
                </Button>

              </DialogActions>
            </Dialog>
        }
      </>
  );

}

function DesktopFilterSelect({
                               hasFilters,
                               appliedFilters,
                               handleOpen,
                             }) {

  const theme = useTheme();

  return (
      <>
        <IconButton
            size={'large'}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              padding: '8px',
              height: '48px',
              width: '48px',
              mt: '2px',
              paddingTop: '12px',
            }}
            onClick={handleOpen}
        >
          <Badge
              badgeContent={hasFilters ? appliedFilters?.length : null}
              color={'primary'}
          >
            <FilterList/>
          </Badge>

        </IconButton>

      </>
  );
}

function TabletFilterSelect({
                              appliedFilters,
                              isOpen,
                              handleOpen,
                              removeFilter,
                            }) {

  const {t} = useTranslate();
  const theme = useTheme();

  return (
      <>
        <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              px: '8px',
              py: '11px',
              position: 'relative',
              cursor: 'pointer',
              ...(isOpen) && {
                borderColor: `${theme.palette.primary.main}`,
                boxShadow: `0 0 0 .5px ${theme.palette.primary.main}`,
              },
            }}
            onClick={handleOpen}
        >
          {
              appliedFilters.length === 0 &&
              <Stack direction="row" spacing={1} alignItems={'center'}>
                <Typography>{t('schedule.filter.filterBy')}</Typography>
                <FilterListOutlined
                    fontSize={'small'}
                    color={'action'}
                />
              </Stack>

          }
          {
              appliedFilters.length > 0 &&
              <Stack
                  direction={'row'}
                  flexWrap={'wrap'}
                  columnGap={1}
                  rowGap={1}
                  sx={{
                    px: 4,
                  }}
              >
                {
                  appliedFilters.map((option, index) => {
                    const parsed = JSON.parse(option);
                    return (
                        <Chip
                            key={index}
                            color={'primary'}
                            label={t(
                                `schedule.filter.option.${parsed.value}`)}
                            deleteIcon={<Cancel
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                }}
                            />}
                            onDelete={(event) => {
                              removeFilter(event, option);
                            }}
                        />
                    );
                  })
                }
              </Stack>
          }
        </Box>

      </>
  );
}

function OptionsList({optionsGroups, selectedFilters, setSelectedFilters}) {

  const {t} = useTranslate();
  let output = [];

  for (let group in optionsGroups) {

    output.push(<ListSubheader key={group}>{t(
        `schedule.filter.${group}`)}</ListSubheader>);

    optionsGroups[group].forEach((option) => {
      output.push(
          <OptionsListOption
              key={option}
              option={option}
              group={group}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
          />,
      );
    });
  }

  return (
      <List>
        {output}
      </List>
  );

}

function OptionsListOption({
                             option,
                             group,
                             selectedFilters,
                             setSelectedFilters,
                           }) {

  const {t} = useTranslate();

  const value = JSON.stringify({group, value: option});

  const isSelected = selectedFilters.filter((selectedFilter) => {
    return value === selectedFilter;

  })?.length > 0;

  function handleClick() {

    if (isSelected) {
      const filtered = selectedFilters.filter((filter) => {
        return filter !== value;
      });
      setSelectedFilters(filtered);
      return;
    }

    setSelectedFilters((prevState) => {
      return [...prevState, value];
    });
  }

  return (
      <MenuItem
          selected={isSelected}
          value={value}
          onClick={handleClick}
      >
        <Checkbox checked={isSelected}/>
        <ListItemText primary={t(`schedule.filter.option.${option}`)}/>
      </MenuItem>
  );
}

export default SlotSearchV2HeaderFilterSelect;
