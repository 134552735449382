
  import {useState} from 'react';
  import {useDispatch} from 'react-redux';
  import {bookingsNotify_POST} from '../../vendor/redux/actions/bookings';
  import {useSnackbar} from 'notistack';
  import {useTranslate} from '@tolgee/react';
  
  function useBookingsNotify(onNotifySuccess = () => {}) {
  
    const dispatch = useDispatch();
    const {t} = useTranslate();
    const {enqueueSnackbar} = useSnackbar();
  
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    function handleNotify(bookingId, userId) {
  
      setIsSubmitting(true);
  
      dispatch(bookingsNotify_POST(
          bookingId,
          userId,
          cbSuccess,
          cbFail,
      ));
  
      function cbSuccess(res) {
        console.log('bookingsNotify_POST Success', res);
        setIsSubmitting(false);
        enqueueSnackbar(t('bookings.detail.notify.success'),
            {variant: 'success'});
        onNotifySuccess();
      }
  
      function cbFail(e) {
        console.log('bookingsNotify_POST Fail', e?.response?.data?.data);
        setIsSubmitting(false);
        enqueueSnackbar(t(e?.response?.data?.data),
            {variant: 'error'});
      }
    }
  
    return {
      submitBookingsNotify: handleNotify,
      isSubmittingNotify: isSubmitting,
  
    };
  
  }
  
  export default useBookingsNotify;